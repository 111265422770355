import { Box } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { ProjectType } from "../../../store/models/project";
import Service from "../../../store/models/service";
import "../../components/ManageEngineeringService/ManageEngineeringService.css";
import {
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
} from "../../components/ManageEngineeringService/ManageEngineeringService.styles";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { PercentageTextfield } from "../PercentageTextField/PercentageTextfield";
import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";

const MINIMUM_DISCOUNT_PERCENTAGE = 0;
const MAXIMUM_DISCOUNT_PERCENTAGE = 80;

interface ToggleBundleDiscountProps {
  addService: boolean;
  setAddService: (value: boolean) => void;
  engineerHasMasterServices: boolean;
  engineerHasDolbyServices: boolean;
  setPendingService: Dispatch<SetStateAction<Service>>;
  canBeBundledWithMasterService: boolean;
  canBeBundledWithDolbyService: boolean;
  pendingService: Service;
}

const ProjectTypeToString = new Map<ProjectType, string>([
  [ProjectType.MASTERING, "Mastering"],
  [ProjectType.ATMOS_MIXING, "Atmos Mixing"],
]);

export const ToggleBundleDiscount = ({
  addService,
  setAddService,
  engineerHasMasterServices,
  engineerHasDolbyServices,
  setPendingService,
  canBeBundledWithDolbyService,
  canBeBundledWithMasterService,
  pendingService,
}: ToggleBundleDiscountProps) => {
  const initialMasterDiscountPercentage =
    pendingService.add_master_discount_percentage || 5;
  const initialAtmosDiscountPercentage =
    pendingService.add_atmos_mix_discount_percentage || 5;
  const disabled = !(
    (canBeBundledWithMasterService && engineerHasMasterServices) ||
    (canBeBundledWithDolbyService && engineerHasDolbyServices)
  );

  return (
    <div className="add-engineer-service-form-column">
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel htmlFor="add-engineer-service-toggle">
            Offer bundled discounts for bookings with multiple services
          </ServiceFormLabel>
          <ServiceFormLabelSubtext>
            *Note: must have Mastering or Dolby ATMOS services
          </ServiceFormLabelSubtext>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <ToggleSwitch
            disabled={disabled}
            id="add-engineer-service-toggle"
            checked={addService}
            onChange={(checked: boolean) => {
              setAddService(checked);

              if (checked && !disabled) {
                setPendingService((pendingService) => ({
                  ...pendingService,
                  add_master_discount_percentage:
                    engineerHasMasterServices && canBeBundledWithMasterService
                      ? initialMasterDiscountPercentage
                      : 0,
                  add_atmos_mix_discount_percentage:
                    engineerHasDolbyServices && canBeBundledWithDolbyService
                      ? initialAtmosDiscountPercentage
                      : 0,
                }));
              } else {
                setPendingService((pendingService) => ({
                  ...pendingService,
                  add_master_discount_percentage: 0,
                  add_atmos_mix_discount_percentage: 0,
                }));
              }
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      {addService && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "8px",
            marginTop: "32px",
          }}
        >
          {engineerHasMasterServices && canBeBundledWithMasterService && (
            <IndividualDiscountField
              onUpdateValue={(updatedValue) => {
                setPendingService((pendingService) => ({
                  ...pendingService,
                  add_master_discount_percentage: updatedValue,
                }));
              }}
              serviceType={ProjectType.MASTERING}
              initialValue={initialMasterDiscountPercentage}
            />
          )}
          {engineerHasDolbyServices && canBeBundledWithDolbyService && (
            <IndividualDiscountField
              onUpdateValue={(updatedValue) => {
                setPendingService((pendingService) => ({
                  ...pendingService,
                  add_atmos_mix_discount_percentage: updatedValue,
                }));
              }}
              serviceType={ProjectType.ATMOS_MIXING}
              initialValue={initialAtmosDiscountPercentage}
            />
          )}
        </Box>
      )}
    </div>
  );
};

interface IndividualDiscountProps {
  onUpdateValue: (updatedValue: number) => void;
  roundingFactor?: number;
  serviceType: ProjectType;
  initialValue: number;
}

const IndividualDiscountField = ({
  onUpdateValue,
  roundingFactor = 5,
  serviceType,
  initialValue,
}: IndividualDiscountProps) => {
  return (
    <Box>
      <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
        Add {ProjectTypeToString.get(serviceType)}:
      </Text>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "8px",
          alignItems: "center",
          padding: "16px",

          ".textfield": {
            width: "60px",
          },
        }}
      >
        <Text variant={TextStyleVariant.P1}>Offer</Text>
        <PercentageTextfield
          max={MAXIMUM_DISCOUNT_PERCENTAGE}
          min={MINIMUM_DISCOUNT_PERCENTAGE}
          value={initialValue / 100}
          onChangePercentage={(newValue) => {
            onUpdateValue(Math.round(newValue * 100));
          }}
          step={roundingFactor}
          containerStyle={{ width: "unset" }}
          offsetForPercentSpace
        />

        <Text variant={TextStyleVariant.P1}>
          discount on your {ProjectTypeToString.get(serviceType)} services
        </Text>
      </Box>
    </Box>
  );
};
