import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { loadEngineerServices } from "../store/actions/services";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectUserEngineerField } from "../store/selectors/userInfoSelectors";

export const useGetEngineerServices = (engineerId = 0) => {
  const engineer = useAppSelector(selectUserEngineerField);
  const dispatch = useAppDispatch();
  return useQuery({
    staleTime: engineer && engineer.id === engineerId ? Infinity : undefined,
    enabled: Boolean(engineerId),
    queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineerId],
    queryFn: async () => {
      return dispatch(
        loadEngineerServices({ engineer_id: engineerId }),
      ).unwrap();
    },
  });
};
