import { FormEvent, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useEngineerServiceCrud } from "../../../../hooks/profileScreenHooks/useEngineerServiceCrud";
import { useUpsertRecordingService } from "../../../../hooks/profileScreenHooks/useUpsertRecordingService";
import { ServiceOperations } from "../../../../store/actions/services";
import Engineer from "../../../../store/models/engineer";
import { ProjectType } from "../../../../store/models/project";
import {
  isRecordingService,
  RecordingService,
} from "../../../../store/models/recording";
import Service, { APIService } from "../../../../store/models/service";
import User from "../../../../store/models/user";
import {
  convertDiscountRatesToDisplayableRates,
  getServiceFromServiceType,
} from "../../../../store/utils/serviceUtils";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../../core-ui/components/Button/FixedMinWidthButton";
import { EditableTextArea } from "../../../elements/EditableTextArea/EditableTextArea";
import { ServicesFormModalFooter } from "./ServicesFormModalFooter";
import { StyledForm } from "./ServicesTab.styles";

interface ServiceDescriptionTabProps {
  service?: Service | RecordingService;
  userData: User;
  onSuccessfulServiceDescriptionUpdate: (
    data: APIService | RecordingService,
  ) => void;
  engineer: Engineer;
  onCancel?: () => void;
}

export const ServiceDescriptionTab = ({
  service,
  userData,
  onSuccessfulServiceDescriptionUpdate,
  engineer,
  onCancel,
}: ServiceDescriptionTabProps) => {
  const [description, setDescription] = useState(service?.description ?? "");
  const {
    mutate: engineerServiceCrud,
    isPending: isEngineerServiceCrudLoading,
  } = useEngineerServiceCrud({
    additionalOnSuccess: onSuccessfulServiceDescriptionUpdate,
  });
  const {
    mutate: updateRecordingService,
    isPending: isUpdatingRecordingService,
  } = useUpsertRecordingService({
    engineerId: engineer.id,
    additionalOnSuccess: onSuccessfulServiceDescriptionUpdate,
  });

  const serviceName = useMemo(() => {
    let suffix = "";
    if (service?.service_type === ProjectType.RECORDING) {
      suffix = " (hourly)";
    }
    return (
      getServiceFromServiceType(
        service?.service_type || ProjectType.NO_TYPE,
        true,
      ) + suffix
    );
  }, [service?.service_type]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!service) {
      toast.error("There was an error adding service description");
      return;
    }

    if (!isRecordingService(service)) {
      engineerServiceCrud({
        operation: ServiceOperations.UPDATE,
        updatedService: {
          ...service,
          service_rate: {
            ...service.service_rate,
            user_id: userData.id,
          },
          description,
        },
      });

      return;
    }

    updateRecordingService({
      price: service.service_rate.price ?? 0,
      label_price: service.service_rate.label_price ?? 0,
      minimum_session_time_minutes: service.minimum_session_time_minutes,
      maximum_session_time_minutes: service.maximum_session_time_minutes,
      will_come_to_you: Boolean(service.will_come_to_you),
      max_travel_distance_minutes: service.max_travel_distance_minutes ?? 0,
      equipment_highlights: service.equipment_highlights ?? "",
      arrival_information: service.arrival_information || "",
      minimum_gap_between_sessions_in_minutes:
        service.minimum_gap_between_sessions_in_minutes,
      number_of_hours_notice: service.number_of_hours_notice,
      unit_number: service.unit_number || "",
      travel_to_artist_price: service.service_rate?.travel_to_artist_price || 0,
      description,
      recording_location: service.recording_location?.city_location,
      discount_rates: service
        ? convertDiscountRatesToDisplayableRates(service).map(
            (discountRate) => discountRate.discount,
          )
        : [],
    });
  };

  const formId = `${serviceName}-service-description-form`;

  return (
    <StyledForm onSubmit={handleSubmit} id={formId}>
      <label htmlFor="service-description">
        Write a description for {serviceName}
      </label>
      <EditableTextArea
        initialValue={service?.description ?? ""}
        editMode={true}
        placeholder={`Service description will be shown to clients when they click on "what's included" for this service card`}
        numberOfLines={8}
        textAreaProps={{
          id: "service-description",
        }}
        onChange={setDescription}
      />
      <ServicesFormModalFooter>
        <FixedMinWidthButton
          variant={ButtonVariant.OUTLINED}
          disabled={isEngineerServiceCrudLoading || isUpdatingRecordingService}
          onClick={onCancel}
        >
          Cancel
        </FixedMinWidthButton>
        <FixedMinWidthButton
          disabled={description === ""}
          type="submit"
          loading={isEngineerServiceCrudLoading || isUpdatingRecordingService}
          form={formId}
        >
          Save
        </FixedMinWidthButton>
      </ServicesFormModalFooter>
    </StyledForm>
  );
};
