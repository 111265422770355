import { Box, styled } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";

export const AlbumIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isPortfolio",
})<{ $isPortfolio: boolean }>(({ $isPortfolio }) => ({
  display: "flex",
  gap: "16px",
  alignItems: $isPortfolio ? "center" : "flex-start",
}));

export const AlbumIconImg = styled("img")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  height: "64px",
  width: "64px",
  cursor: "pointer",
}));

export const ColumnContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export const AlbumIconText = styled(Text)(({ theme }) => ({
  "&.release-date-text": {
    color: theme.palette.text.secondary,
  },
}));

export const MusoCreditInfoColumnContainer = styled(ColumnContainer)({
  gap: "12px",
});

const RoleContainer = styled(Box)({
  width: "fit-content",
  display: "flex",
});

export const FeaturedTrackRoleContainer = styled(RoleContainer)(
  ({ theme }) => ({
    padding: "2px",
    borderRadius: "2px",
    backgroundColor: theme.palette.customColor.midgroundColor,
  }),
);

export const MusoCreditInfoRoleContainer = styled(RoleContainer)(
  ({ theme }) => ({
    padding: "4px",
    backgroundColor: theme.palette.customColor.darkerMidgroundColor,
    borderRadius: "2px",
  }),
);
