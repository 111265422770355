import {
  createTheme,
  GlobalStyles,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import { useMemo } from "react";
import { standardColor } from "../colors";
import muiBaseTheme from "./baseTheme";

const { BoomyOrange, DeepBlue, Gray, Green, Red, White } = standardColor;

const { augmentColor } = muiBaseTheme.palette;

const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: augmentColor({
      color: {
        main: BoomyOrange[400],
        contrastText: White[0],
      },
    }),
    secondary: augmentColor({
      color: {
        main: Gray[950],
        contrastText: White[0],
      },
    }),
    text: {
      primary: Gray[950],
      secondary: Gray[500],
      disabled: Gray[300],
    },
    background: {
      default: Gray[25],
      paper: White[0],
    },
    error: augmentColor({
      color: {
        main: Red[500],
        contrastText: White[0],
      },
    }),
    success: augmentColor({
      color: {
        main: Green[600],
        contrastText: White[0],
      },
    }),
    info: augmentColor({
      color: {
        main: DeepBlue[500],
        contrastText: White[0],
      },
    }),
    divider: Gray[200],
    customColor: {
      buttonColorHover: Gray[200],
      buttonOutlinedColorHover: Gray[100],
      textInvertColor: Gray[25],
      textErrorColor: Red[700],
      backgroundColor: Gray[25],
      midgroundColor: Gray[50],
      darkerMidgroundColor: Gray[100],
      foregroundColor: White[0],
      dropdownBackgroundColor: White[0],
      disabledTextPrimaryColor: Gray[300],
      unselectedTextPrimaryColor: Gray[400],
      projectWorkflowMessageBackgroundColor: BoomyOrange[100],
      projectWorkflowMessageBorderColor: Gray[25],
      monochromeBackgroundColor: Gray[50],
      monochromeColor: Gray[950],
      musoColor: "#E4F7F6",
      successFadedColor: Green[100],
    },
  },
};

const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: augmentColor({
      color: {
        main: BoomyOrange[400],
        contrastText: White[0],
      },
    }),
    secondary: augmentColor({
      color: {
        main: Gray[25],
        contrastText: Gray[950],
      },
    }),
    text: {
      primary: Gray[25],
      secondary: Gray[200],
      disabled: Gray[300],
    },
    background: {
      default: Gray[950],
      paper: Gray[800],
    },
    action: {
      disabled: "rgba(255, 255, 255, 0.36)",
      disabledBackground: "rgba(255, 255, 255, 0.22)",
      disabledOpacity: 0.38,
    },
    divider: Gray[700],
    customColor: {
      buttonColorHover: Gray[600],
      buttonOutlinedColorHover: Gray[700],
      textInvertColor: Gray[950],
      textErrorColor: Red[400],
      backgroundColor: Gray[950],
      midgroundColor: Gray[800],
      darkerMidgroundColor: Gray[900],
      foregroundColor: Gray[800],
      dropdownBackgroundColor: Gray[950],
      disabledTextPrimaryColor: Gray[300],
      unselectedTextPrimaryColor: Gray[200],
      projectWorkflowMessageBackgroundColor: BoomyOrange[950],
      projectWorkflowMessageBorderColor: BoomyOrange[700],
      monochromeBackgroundColor: Gray[600],
      monochromeColor: White[0],
      musoColor: "#E4F7F6",
      successFadedColor: Green[800],
    },
  },
};

export const MuiThemeProvider = ({
  children,
  darkMode,
}: {
  children: React.ReactNode;
  darkMode: boolean;
}) => {
  const muiTheme = useMemo(
    () => createTheme(muiBaseTheme, darkMode ? darkTheme : lightTheme),
    [darkMode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        {/* @TODO: consider adding a global style reset here (see: https://mui.com/material-ui/react-css-baseline/) */}
        {/* This sets default box-sizing to border-box and other default styles - currently messes up too many styles */}
        {/* <CssBaseline enableColorScheme /> */}
        <GlobalStyles
          styles={{
            body: {
              background: muiTheme.palette.background.default,
              transition: "background 0.2s ease-in, color 0.2s ease-in",
              margin: 0,
              padding: 0,
              ["--webkit-font-smoothing"]: "antialiased",
              ["-moz-osx-font-smoothing"]: "grayscale",
            },
          }}
        />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
