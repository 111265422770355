/* eslint-disable import/no-unused-modules */
import { Dispatch, SetStateAction, useMemo } from "react";
import Engineer from "../../../../store/models/engineer";
import { ProjectType } from "../../../../store/models/project";
import { RecordingService } from "../../../../store/models/recording";
import Service from "../../../../store/models/service";
import User from "../../../../store/models/user";
import { getServiceFromServiceType } from "../../../../store/utils/serviceUtils";
import { BaseModal } from "../../../core-ui/components/BaseModal/BaseModal";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { FormType, ServicesFormModalContent } from "./ServicesFormModalContent";
import { StyledServicesFormModalBody } from "./ServicesTab.styles";

export interface ServicesFormModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  engineer: Engineer;
  onChangeService: (selectedProjectType: OptionType) => void;
  selectedServiceType: ProjectType;
  existingServices: (Service | RecordingService)[];
  formType: FormType;
  userData: User;
}

export const ServicesFormModal = ({
  isOpen,
  setOpen,
  engineer,
  onChangeService,
  selectedServiceType,
  existingServices,
  formType,
  userData,
}: ServicesFormModalProps) => {
  const serviceName = useMemo(() => {
    let suffix = "";
    if (selectedServiceType === ProjectType.RECORDING) {
      suffix = " (hourly)";
    }
    return getServiceFromServiceType(selectedServiceType, true) + suffix;
  }, [selectedServiceType]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <BaseModal
      open={isOpen}
      setOpen={setOpen}
      header={`${formType === FormType.EDIT ? "Edit" : "Create"} Service: ${serviceName}`}
      customModalStyle={{
        boxSizing: "border-box",
        width: "90%",
        maxWidth: "968px", // As per design
      }}
      style={{ width: "100%", maxWidth: "unset", height: "100%" }}
      customBodyStyle={StyledServicesFormModalBody}
      fullScreenOnMobile
    >
      <ServicesFormModalContent
        setOpen={setOpen}
        engineer={engineer}
        onChangeService={onChangeService}
        selectedServiceType={selectedServiceType}
        existingServices={existingServices}
        formType={formType}
        userData={userData}
        onCancel={handleCloseModal}
      />
    </BaseModal>
  );
};
