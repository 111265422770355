import { ProjectType } from "../../../store/models/project";
import { SurveyForm } from "../SurveyForm/SurveyForm";
import "./EditServiceSurvey.css";

export interface EditServiceSurveyProps {
  serviceType: ProjectType;
  onFinishUpdate?: () => void;
  onCancel?: () => void;
  isRevalidatingData?: boolean;
}

export const EditServiceSurvey = (props: EditServiceSurveyProps) => {
  return (
    <div>
      <div className="add-package-service-container">
        <SurveyForm
          serviceType={props.serviceType}
          onFinishUpdate={props.onFinishUpdate}
          onCancel={props.onCancel}
          isRevalidatingData={props.isRevalidatingData}
        />
      </div>
    </div>
  );
};
