import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  DEFAULT_DEPOSIT,
  MAXIMUM_DEPOSIT,
  MINIMUM_DEPOSIT,
} from "../../../hooks/useManageEngineeringService";
import { useMinAndMaxServiceRates } from "../../../hooks/useMinAndMaxServiceRates";
import { removeCalendarIntegration } from "../../../store/actions/calendarService";
import {
  createRecordingService,
  createRecordingServiceParams,
  deleteRecordingService,
} from "../../../store/actions/recording";
import { updateBookingStatus } from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { Discounts, RecordingService } from "../../../store/models/recording";
import { StudioRoom } from "../../../store/models/studio";
import { selectIsUserRecordingEnabled } from "../../../store/selectors/userInfoSelectors";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  convertDiscountRatesToDisplayableRates,
  convertIndexFromHourOptionsToMilliseconds,
  convertMillisecondsToMinutesRoundedToInt,
  convertMinutesToHourOptionType,
  getHourOptionsWithMinimumDuration,
  hourOptions,
} from "../../../store/utils/serviceUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import GoogleCalendarIcon from "../../assets/google-calendar-icon.png";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { ColorPalette } from "../../theme";
import "../CalendarSetupView/CalendarSetupView.css";
import { CalendarSetUpViewV1 } from "../CalendarSetupView/CalendarSetupViewV1";
import { CollapsableComponent } from "../CollapsableComponent/CollapsableComponent";
import { LocationInput } from "../LocationInput/LocationInput";
import "../ManageEngineeringService/ManageEngineeringService.css";
import { PercentageSlider } from "../PercentageSlider/PercentageSlider";
import { SelectWorkingHoursComponentV1 } from "../SelectWorkingHoursComponent/SelectWorkingHoursComponentV1";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import { AddBlockDiscountButton } from "./components/AddBlockDiscountButton/AddBlockDiscountButton";

export interface AddEngineeringServicePropsV1 {
  onSuccessfulSubmit?: (service?: RecordingService) => void;
  onServiceUpdated?: (service: RecordingService) => void;
  studioRoom?: StudioRoom;
  studioId?: number;
  canManageStudio?: boolean;
  hideGoogleCalendar?: boolean;
}

export interface DiscountRateService {
  minTimeSelected: OptionType;
  discount: Discounts;
}

const MIN_TRAVEL_DISTANCE = 0;
const MAX_TRAVEL_DISTANCE = 100;

export const AddRecordingServiceV1: React.FC<AddEngineeringServicePropsV1> = ({
  onSuccessfulSubmit,
  onServiceUpdated,
  studioRoom,
  studioId,
  canManageStudio,
  hideGoogleCalendar,
}) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const [recordingLocation, setRecordingLocation] = useState<
    undefined | google.maps.places.PlaceResult
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isRecordingEnabled: boolean = useAppSelector(
    selectIsUserRecordingEnabled,
  );
  const [acceptingBookings, setBooking] = useState<boolean>(true);
  const engineerRecordingService = useAppSelector(
    (state) => state.engineerServices.recordingService,
  );
  const recordingService = useMemo(() => {
    if (studioRoom) {
      return studioRoom.recording_service;
    }
    return engineerRecordingService;
  }, [engineerRecordingService, studioRoom]);

  const [allowPartialPayments, setAllowPartialPayments] = useState<boolean>(
    recordingService?.minimum_deposit_percentage
      ? recordingService.minimum_deposit_percentage < 1
      : false,
  );

  const [minServiceRate, maxServiceRate] = useMinAndMaxServiceRates(
    ProjectType.RECORDING,
  );
  const [minimumSessionTime, setMinimumSessionTime] = useState<OptionType>(
    recordingService
      ? convertMinutesToHourOptionType(
          recordingService.minimum_session_time_minutes,
        )
      : hourOptions[0],
  );

  const maximumSessionTimeOptions = useMemo(() => {
    return [...hourOptions].reverse();
  }, [hourOptions]);
  const [maximumSessionTime, setMaximumSessionTime] = useState<OptionType>(
    recordingService
      ? convertMinutesToHourOptionType(
          recordingService.maximum_session_time_minutes,
        )
      : maximumSessionTimeOptions[0],
  );
  const [minimumGapBetweenSessions, setMinimumGapBetweenSessions] =
    useState<number>(
      recordingService
        ? recordingService.minimum_gap_between_sessions_in_minutes
        : 0,
    );
  const [numberHoursNotice, setNumberHoursNotice] = useState<number>(
    recordingService ? recordingService.number_of_hours_notice : 0,
  );
  const [showWorkingHoursSetup, setShowWorkingHoursSetup] =
    useState<boolean>(false);
  const [discountRates, setDiscountRate] = useState<DiscountRateService[]>(
    recordingService
      ? convertDiscountRatesToDisplayableRates(recordingService)
      : [],
  );
  const [showDiscount, setShowDiscount] = useState<boolean>(
    discountRates.length > 0,
  );

  const [pendingRecordingService, setPendingRecordingService] =
    useState<createRecordingServiceParams>(
      recordingService
        ? {
            price: recordingService.service_rate?.price || 0,
            label_price: recordingService?.service_rate?.label_price || 0,
            travel_to_artist_price:
              recordingService?.service_rate?.travel_to_artist_price || 0,
            minimum_session_time_minutes:
              recordingService.minimum_session_time_minutes || 0,
            maximum_session_time_minutes:
              recordingService.maximum_session_time_minutes || 0,
            will_come_to_you: true,
            max_travel_distance_minutes:
              recordingService.max_travel_distance_minutes || 0,
            equipment_highlights: recordingService.equipment_highlights || "",
            arrival_information: recordingService.arrival_information || "",
            recording_location:
              recordingService.recording_location?.city_location,
            unit_number: recordingService.unit_number || "",
            minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
            number_of_hours_notice: numberHoursNotice,
            minimum_deposit_percentage:
              recordingService.minimum_deposit_percentage.toString() ?? 1.0,
          }
        : {
            price: 0,
            label_price: 0,
            travel_to_artist_price: 0,
            minimum_session_time_minutes: 0,
            maximum_session_time_minutes: 0,
            will_come_to_you: true,
            max_travel_distance_minutes: 0,
            equipment_highlights: "",
            arrival_information: "",
            recording_location: undefined,
            unit_number: "",
            minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
            number_of_hours_notice: numberHoursNotice,
            minimum_deposit_percentage: "1.0",
          },
    );

  useEffect(() => {
    if (recordingService) {
      setPendingRecordingService({
        price: recordingService.service_rate?.price || 0,
        label_price: recordingService?.service_rate?.label_price || 0,
        travel_to_artist_price:
          recordingService?.service_rate?.travel_to_artist_price || 0,
        minimum_session_time_minutes:
          recordingService.minimum_session_time_minutes || 0,
        maximum_session_time_minutes:
          recordingService.maximum_session_time_minutes || 0,
        will_come_to_you: true,
        max_travel_distance_minutes:
          recordingService.max_travel_distance_minutes || 0,
        equipment_highlights: recordingService.equipment_highlights || "",
        arrival_information: recordingService.arrival_information || "",
        recording_location: recordingService.recording_location?.city_location,
        unit_number: recordingService.unit_number || "",
        minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
        number_of_hours_notice: numberHoursNotice,
        minimum_deposit_percentage:
          recordingService.minimum_deposit_percentage.toString() ?? "1.0",
      });
    }
  }, [minimumGapBetweenSessions, numberHoursNotice, recordingService]);

  const initialDepositPercentage = useMemo(() => {
    if (!pendingRecordingService.minimum_deposit_percentage) {
      return DEFAULT_DEPOSIT;
    }
    const initialDepositPercentage =
      pendingRecordingService.minimum_deposit_percentage
        ? +pendingRecordingService.minimum_deposit_percentage * DEFAULT_DEPOSIT
        : DEFAULT_DEPOSIT;
    if (initialDepositPercentage === DEFAULT_DEPOSIT) return MAXIMUM_DEPOSIT;
    if (initialDepositPercentage < MINIMUM_DEPOSIT) return MINIMUM_DEPOSIT;
    if (initialDepositPercentage > MAXIMUM_DEPOSIT) return MAXIMUM_DEPOSIT;
    return initialDepositPercentage;
  }, [allowPartialPayments]);

  useEffect(() => {
    onServiceUpdated?.({
      service_rate: {
        price: pendingRecordingService.price,
        label_price: pendingRecordingService.label_price,
        travel_to_artist_price: pendingRecordingService.travel_to_artist_price,
      },
      id: 0,
      minimum_session_time_minutes: 0,
      maximum_session_time_minutes: 0,
      will_come_to_you: pendingRecordingService.will_come_to_you
        ? "will come to you"
        : null,
      max_travel_distance_minutes:
        pendingRecordingService.max_travel_distance_minutes,
      equipment_highlights: pendingRecordingService.equipment_highlights,
      service_type: ProjectType.RECORDING,
      unit_number: pendingRecordingService.unit_number,
      arrival_information: pendingRecordingService.arrival_information,
      minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
      number_of_hours_notice: numberHoursNotice,
      deleted: null,
      studio_room: null,
      not_accepting_bookings: null,
      minimum_deposit_percentage:
        pendingRecordingService.minimum_deposit_percentage
          ? +pendingRecordingService.minimum_deposit_percentage
          : 1.0,
    });
  }, [
    pendingRecordingService,
    numberHoursNotice,
    minimumGapBetweenSessions,
    onServiceUpdated,
  ]);

  const { calendarIntegrated, email } = useAppSelector(
    (state) => state.calendarService,
  );

  const handleRemovingGoogleCalendarIntegration = () => {
    setLoading(true);
    dispatch(removeCalendarIntegration({ studio_id: studioId }))
      .unwrap()
      .then(() => {
        toast.success("Successfully removed Google Calendar");
      })
      .catch(() => {
        toast.error("Error removing Google Calendar");
      });
  };

  const handleDeleteRecordingService = () => {
    if (!recordingService) return;
    setLoading(true);
    emitAnalyticsTrackingEvent(
      "recording_service_deleted",
      {
        serviceType: "Recording",
        studioRoomId: studioRoom?.id,
        studioId,
      },
      user?.id,
    );
    dispatch(
      deleteRecordingService({
        recording_service_id: recordingService?.id,
      }),
    )
      .unwrap()
      .then((deletedRecordingService: RecordingService) => {
        setLoading(false);
        onSuccessfulSubmit?.(deletedRecordingService);
      })
      .catch(() => {
        toast.error(
          "Error deleting service. Please reach out to customer support.",
        );
        setLoading(false);
      });
  };

  const handleCreateRecordingService = useCallback(() => {
    setLoading(true);
    const location = pendingRecordingService.recording_location as string;
    if (!recordingLocation && !studioRoom && !location) {
      toast.error("Please select a location");
      setLoading(false);
      return;
    }

    const pendingDiscountRates = discountRates.map(
      (discountRate) => discountRate.discount,
    );

    emitAnalyticsTrackingEvent(
      recordingService
        ? "recording_service_updated"
        : "recording_service_created",
      {
        serviceType: "Recording",
        studioRoomId: studioRoom?.id,
        studioId,
        recordingLocation: recordingLocation?.formatted_address,
        minimumSessionTime: minimumSessionTime.value,
        maximumSessionTime: maximumSessionTime.value,
        minimumGapBetweenSessions,
        numberHoursNotice,
        discountRates: pendingDiscountRates,
      },
      user?.id,
    );

    dispatch(
      createRecordingService({
        ...pendingRecordingService,
        minimum_session_time_minutes: convertMillisecondsToMinutesRoundedToInt(
          convertIndexFromHourOptionsToMilliseconds(minimumSessionTime.value),
        ),
        maximum_session_time_minutes: convertMillisecondsToMinutesRoundedToInt(
          convertIndexFromHourOptionsToMilliseconds(maximumSessionTime.value),
        ),
        recording_location: studioRoom ? undefined : recordingLocation,
        minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
        number_of_hours_notice: numberHoursNotice,
        studio_room_id: studioRoom?.id,
        discount_rates: pendingDiscountRates,
      }),
    )
      .unwrap()
      .then((service: RecordingService) => {
        setLoading(false);
        if (studioRoom) {
          onSuccessfulSubmit?.(service);
        }
        onServiceUpdated?.(service);
        setShowWorkingHoursSetup(true);
      })
      .catch(() => {
        toast.error(
          "Error creating service. Please reach out to customer support.",
        );
        setLoading(false);
      });
  }, [
    dispatch,
    maximumSessionTime.value,
    minimumGapBetweenSessions,
    discountRates,
    minimumSessionTime.value,
    numberHoursNotice,
    onServiceUpdated,
    pendingRecordingService,
    recordingLocation,
    studioRoom?.id,
  ]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.discount.price < pendingRecordingService.price,
      );
    });
  }, [pendingRecordingService.price]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.minTimeSelected.value > minimumSessionTime.value,
      );
    });
  }, [minimumSessionTime]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.minTimeSelected.value < maximumSessionTime.value,
      );
    });
  }, [maximumSessionTime]);

  const removeDiscountRate = (index: number) => {
    if (index < 0 || index >= discountRates.length) {
      return;
    }
    const newDiscountRate = [...discountRates];
    newDiscountRate.splice(index, 1);
    setDiscountRate(newDiscountRate);
  };

  const setPriceForDiscountRate = (
    minTimeToEnableRate: number,
    value: number,
    index: number,
  ) => {
    let hourlyRate: number = value / (minTimeToEnableRate / 60);
    if (index < 0 || index >= discountRates.length) {
      return;
    }
    if (hourlyRate < minServiceRate || hourlyRate > maxServiceRate) {
      return;
    }

    if (hourlyRate > pendingRecordingService.price) {
      hourlyRate = +pendingRecordingService.price;
    }
    const discountRate = discountRates[index];
    discountRate.discount.price = +hourlyRate.toFixed(2);
    const newDiscountRate = [...discountRates];
    newDiscountRate[index] = discountRate;
    setDiscountRate(newDiscountRate);
  };

  const setMinimumTimeForDiscountRate = (
    selected: OptionType,
    index: number,
  ) => {
    if (
      discountRates.some(
        (discountRate) => discountRate.minTimeSelected.label === selected.label,
      )
    ) {
      return;
    }
    if (index < 0 || index >= discountRates.length) {
      return;
    }
    const selectedSessionInMinutes = selected.value * 60;
    if (selectedSessionInMinutes < minimumSessionTime.value * 60) {
      return;
    }
    if (selectedSessionInMinutes > maximumSessionTime.value * 60) {
      return;
    }
    const discountRate = discountRates[index];
    discountRate.minTimeSelected = selected;
    discountRate.discount.minimum_time_to_enable_rate = selected.value * 60;
    const newDiscountRates = [...discountRates];
    newDiscountRates[index] = discountRate;
    setDiscountRate([...newDiscountRates]);
  };

  const changeEquipmentHighlights = useCallback((value: string) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      equipment_highlights: value,
    }));
  }, []);

  const changeUnitNumber = useCallback((value: string) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      unit_number: value,
    }));
  }, []);

  const changeServiceRatePrice = useCallback((updatedPrice: number) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      price: updatedPrice,
    }));
  }, []);

  const changeTravelToArtistRatePrice = useCallback((updatedPrice: number) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      travel_to_artist_price: updatedPrice,
    }));
  }, []);

  useEffect(() => {
    if (!studioRoom?.recording_service) {
      return;
    }
    setBooking(!studioRoom?.recording_service?.not_accepting_bookings);
  }, [studioRoom]);

  useEffect(() => {
    if (!allowPartialPayments) {
      handleSettingDepositPercentage(100);
    }
  }, [allowPartialPayments]);

  const handleSettingDepositPercentage = (wholeNumber: number) => {
    if (wholeNumber < 25 && allowPartialPayments) {
      return toast.error("Minimum deposit percentage must be at least 25%");
    }
    if (wholeNumber > 75 && allowPartialPayments) {
      return toast.error("Minimum deposit percentage cannot exceed 75%");
    }
    setPendingRecordingService((curr) => {
      return {
        ...curr,
        minimum_deposit_percentage: `${wholeNumber / 100}`,
      };
    });
  };

  const minimumDepositDescription = useMemo(() => {
    if (!pendingRecordingService.price) {
      return "Set service price";
    }
    if (!pendingRecordingService.minimum_deposit_percentage) {
      return "Set minimum deposit percentage";
    }
    return `${PennyDollarFormatter().format(+pendingRecordingService.minimum_deposit_percentage * pendingRecordingService.price)}`;
  }, [pendingRecordingService]);

  if (!isRecordingEnabled && !canManageStudio && !recordingService) {
    return (
      <div>
        <p>
          Recording is in beta. Please reach out to the EngineEars team if you
          would like to host Recording services.
        </p>
      </div>
    );
  }

  if (!calendarIntegrated) {
    return <CalendarSetUpViewV1 studioID={studioId} />;
  }
  if (showWorkingHoursSetup) {
    return (
      <SelectWorkingHoursComponentV1
        studioRoomId={studioRoom?.id}
        studioRoomName={studioRoom?.room_name}
        onSuccessfulSubmit={() => {
          onSuccessfulSubmit?.();
        }}
        setShowWorkingHoursSetup={setShowWorkingHoursSetup}
      />
    );
  }

  const onToggleBooking = async (checkState: boolean) => {
    if (!studioRoom?.recording_service) return;
    try {
      await dispatch(
        updateBookingStatus({
          studioRoomId: studioRoom.id,
          notAcceptingBookings: !checkState,
        }),
      ).unwrap();
    } finally {
      setBooking(checkState);
    }
  };

  return (
    <div className="add-engineer-service-container mb-5">
      {studioRoom?.recording_service && (
        <div className={"add-engineer-service-form-row"}>
          <div className="add-engineer-service-form-row-label">
            <p>Accepting Bookings?</p>
          </div>
          <div
            className="add-engineer-service-form-row-input alt-percentage-slider"
            style={{ width: "100%" }}
          >
            <ToggleSwitch
              id="accepting-bookings-toggle"
              checked={acceptingBookings}
              onChange={onToggleBooking}
              label={acceptingBookings ? "Currently Booking" : "Not Booking"}
              updateCheckedLocally={false}
            />
          </div>
        </div>
      )}
      <div className="add-engineer-service-form-row">
        <div className="add-engineer-service-form-row-label">
          <p>Hourly rate ($)</p>
        </div>
        <div
          className="add-engineer-service-form-row-input"
          style={{ width: "100%" }}
        >
          <FixedRangeNumberField
            min={minServiceRate}
            max={maxServiceRate}
            initialValue={pendingRecordingService.price}
            placeholder="Service Rate"
            onChange={changeServiceRatePrice}
          />
        </div>
      </div>
      <div className="add-engineer-service-form-row">
        <div className="add-engineer-service-form-row-label">
          <p>
            {studioRoom
              ? "How much lead time is required for bookings in this room?"
              : "How far in advance do you need to be booked?"}
            <strong>&nbsp;(hours)</strong>
          </p>
        </div>
        <div
          className="add-engineer-service-form-row-input"
          style={{ width: "100%" }}
        >
          <FixedRangeNumberField
            min={0}
            max={24}
            initialValue={numberHoursNotice}
            placeholder="Hours"
            onChange={(value) => {
              setNumberHoursNotice(value);
            }}
          />
        </div>
      </div>
      <div className="add-engineer-service-form-row">
        <div className="add-engineer-service-form-row-label">
          <p>
            How much time do you need between bookings?{" "}
            <strong>&nbsp;(minutes)</strong>
          </p>
        </div>
        <div
          className="add-engineer-service-form-row-input"
          style={{ width: "100%" }}
        >
          <FixedRangeNumberField
            min={0}
            max={120}
            initialValue={minimumGapBetweenSessions}
            placeholder="Minutes"
            onChange={(value) => {
              setMinimumGapBetweenSessions(value);
            }}
          />
        </div>
      </div>
      <div className="add-engineer-service-form-column">
        <div className="add-engineer-service-form-row-label b3-semi-bold">
          <p>Minimum Session Time:</p>
        </div>
        <div
          className="add-engineer-service-form-row-input alt-percentage-slider"
          style={{ width: "100%" }}
        >
          <DropdownSelector
            value={minimumSessionTime}
            options={hourOptions}
            placeholder={"Minimum Session Time"}
            onChange={(selected) => {
              if (selected.value > maximumSessionTime.value) {
                setMaximumSessionTime(selected);
                setMinimumSessionTime(selected);
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time: selected.value,
                  maximum_session_time: selected.value,
                }));
              } else {
                setMinimumSessionTime(selected);
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time: selected.value,
                }));
              }
            }}
          />
        </div>
        <div className="add-engineer-service-form-column mt-3">
          <div className="add-engineer-service-form-row-label b3-semi-bold">
            <p>Maximum Session Time:</p>
          </div>
          <div
            className="add-engineer-service-form-row-input alt-percentage-slider"
            style={{ width: "100%" }}
          >
            <DropdownSelector
              value={maximumSessionTime}
              options={maximumSessionTimeOptions}
              placeholder={"Minimum Session Time "}
              onChange={(selected) => {
                if (selected.value < minimumSessionTime.value) {
                  setMinimumSessionTime(selected);
                  setMaximumSessionTime(selected);
                  setPendingRecordingService((prevState) => ({
                    ...prevState,
                    minimum_session_time: selected.value,
                    maximum_session_time: selected.value,
                  }));
                } else {
                  setMaximumSessionTime(selected);
                  setPendingRecordingService((prevState) => ({
                    ...prevState,
                    maximum_session_time: selected.value,
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="add-discount-rates-for-recording-service-container">
          <div className="add-engineer-service-form-row-label b3-semi-bold row-header-options">
            <p>Discount Options</p>
            <p
              onClick={() => setShowDiscount(!showDiscount)}
              className="view-more-options"
            >
              {" "}
              {showDiscount ? "Hide" : "View"}
            </p>
            <FontAwesomeIcon
              style={{ color: ColorPalette.DarkGrey }}
              icon={showDiscount ? faChevronUp : faChevronDown}
            />
          </div>
          {discountRates.length > 0 && (
            <div
              className={"discount-options-container ".concat(
                showDiscount ? "discount-options-show " : "",
              )}
            >
              {showDiscount &&
                discountRates.map((discountRate, index) => {
                  return (
                    <div
                      className={
                        "discount-options-individual-discount-container"
                      }
                      key={index}
                    >
                      <div className="add-engineer-service-form-column">
                        <div className="discount-options-prices-container">
                          <p className="b3-semi-bold">When the client books</p>
                          <p
                            style={{
                              color: ColorPalette.DarkGrey,
                              whiteSpace: "pre",
                            }}
                            className="b3"
                          >
                            {`(${discountRate.minTimeSelected.label} at ${
                              discountRate.discount.price
                                ? PennyDollarFormatter().format(
                                    discountRate.discount.price,
                                  )
                                : 0
                            } per hour)`}
                          </p>
                        </div>
                        <DropdownSelector
                          value={discountRate.minTimeSelected}
                          options={getHourOptionsWithMinimumDuration(
                            minimumSessionTime ? minimumSessionTime.value : 3,
                            maximumSessionTime ? maximumSessionTime.value : 24,
                          )}
                          placeholder={"Minimum Session Time "}
                          onChange={(selected) =>
                            setMinimumTimeForDiscountRate(selected, index)
                          }
                        />
                      </div>
                      <div
                        className="add-engineer-service-form-column"
                        style={{ width: "100%" }}
                      >
                        <div className="discount-options-prices-container">
                          <p className="b3-semi-bold">
                            The total price changes to ($)
                          </p>
                          <p
                            onClick={() => removeDiscountRate(index)}
                            className="b3 discount-options-delete-button"
                          >
                            Delete
                          </p>
                        </div>
                        <FixedRangeNumberField
                          min={minServiceRate}
                          max={
                            pendingRecordingService.price *
                            discountRate.minTimeSelected.value
                          }
                          initialValue={
                            (discountRate.discount.price *
                              discountRate.discount
                                .minimum_time_to_enable_rate) /
                            60
                          }
                          placeholder="Service Rate"
                          className="discount-options-fixed-range-number-field"
                          onChange={(value) => {
                            setPriceForDiscountRate(
                              discountRate.discount.minimum_time_to_enable_rate,
                              value,
                              index,
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <AddBlockDiscountButton
            discountRates={discountRates}
            minimumSessionTimeValue={minimumSessionTime.value}
            maximumSessionTimeValue={maximumSessionTime.value}
            pendingServicePrice={pendingRecordingService.price}
            setDiscountRate={setDiscountRate}
            setShowDiscount={setShowDiscount}
          />
        </div>
        {!studioRoom && (
          <div className="add-engineer-service-form-column mt-3">
            <div className="add-engineer-service-form-row-label">
              <p>My Location</p>
            </div>
            <LocationInput
              defaultValue={
                pendingRecordingService.recording_location as string
              }
              onPlaceSelected={(place) => {
                setRecordingLocation(place);
              }}
              showBorder
            />
          </div>
        )}
        {studioId && !studioRoom && (
          <div className="add-engineer-service-form-column">
            <div className="add-engineer-service-form-row-label">
              <p>Unit number (if applicable):</p>
            </div>
            <div
              className="add-engineer-service-form-row-input alt-percentage-slider"
              style={{ width: "100%" }}
            >
              <EditableTextArea
                editMode={true}
                numberOfLines={1}
                width={"100%"}
                initialValue={pendingRecordingService.unit_number ?? ""}
                onChange={changeUnitNumber}
                placeholder={"Enter unit number (if applicable)"}
              />
            </div>
          </div>
        )}
        {!studioId && (
          <div>
            <Text style={{ marginBottom: "4px", marginTop: "24px" }}>
              For sessions outside your affiliated studios:
            </Text>
            {pendingRecordingService.will_come_to_you && (
              <>
                <div className="add-engineer-service-form-row">
                  <div className="add-engineer-service-form-row-label">
                    <p>Travel to artist service rate (per hour)</p>
                  </div>
                  <div
                    className="add-engineer-service-form-row-input"
                    style={{ width: "100%" }}
                  >
                    <FixedRangeNumberField
                      min={minServiceRate}
                      max={maxServiceRate}
                      initialValue={
                        Number(pendingRecordingService.travel_to_artist_price)
                          ? Number(
                              pendingRecordingService.travel_to_artist_price,
                            )
                          : minServiceRate
                      }
                      placeholder="Travel To Artist Rate"
                      onChange={changeTravelToArtistRatePrice}
                    />
                  </div>
                </div>
                <div className="add-engineer-service-form-row">
                  <div className="add-engineer-service-form-row-label">
                    <p>How far are you willing to travel? (minutes): </p>
                  </div>
                  <div
                    className="add-engineer-service-form-row-input"
                    style={{ width: "100%" }}
                  >
                    <FixedRangeNumberField
                      min={MIN_TRAVEL_DISTANCE}
                      max={MAX_TRAVEL_DISTANCE}
                      initialValue={
                        pendingRecordingService.max_travel_distance_minutes
                      }
                      onChange={(value: number) => {
                        setPendingRecordingService((prevState) => ({
                          ...prevState,
                          max_travel_distance_minutes: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {!studioRoom && pendingRecordingService.will_come_to_you && (
        <div className="add-engineer-service-form-column mt-3 mb-4">
          <div className="add-engineer-service-form-row-label">
            <p>Equipment:</p>
          </div>
          <div
            className="add-engineer-service-form-row-input alt-percentage-slider"
            style={{ width: "100%" }}
          >
            <EditableTextArea
              editMode={true}
              width={"100%"}
              initialValue={pendingRecordingService.equipment_highlights}
              onChange={changeEquipmentHighlights}
              placeholder={"Enter equipment highlights"}
            />
          </div>
        </div>
      )}
      {studioId && !studioRoom && (
        <div className="add-engineer-service-form-column mb-4">
          <div className="add-engineer-service-form-row-label">
            <p>Transit, parking, and access information:</p>
          </div>
          <div
            className="add-engineer-service-form-row-input alt-percentage-slider "
            style={{ width: "100%" }}
          >
            <EditableTextArea
              editMode={true}
              width={"100%"}
              initialValue={pendingRecordingService.arrival_information}
              onChange={(value) => {
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  arrival_information: value,
                }));
              }}
              placeholder={"Enter information for the client"}
            />
          </div>
        </div>
      )}
      <div className="add-engineer-service-form-row">
        <div className="add-engineer-service-form-row-label">
          <p>
            Allow partial payments&nbsp;
            <ToolTipTextArea
              text={
                "Allows your clients to put down an initial deposit when booking; this percentage determines the initial deposit amount."
              }
            />
          </p>
        </div>
        <div className="add-engineer-service-form-row-input">
          <ToggleSwitch
            checked={allowPartialPayments}
            onChange={(checked: boolean) => {
              setAllowPartialPayments(checked);
            }}
          />
        </div>
      </div>
      {allowPartialPayments && (
        <div className="add-engineer-service-form-row">
          <div className="add-engineer-service-form-row-label">
            <p>
              Minimum deposit required
              <span className="dollarsign">{minimumDepositDescription}</span>
            </p>
          </div>
          <div className="add-engineer-service-form-row-input alt-percentage-slider">
            <PercentageSlider
              minValue={MINIMUM_DEPOSIT}
              maxValue={MAXIMUM_DEPOSIT}
              roundingFactor={5}
              initialValue={initialDepositPercentage}
              serviceType={ProjectType.RECORDING}
              handleUpdate={handleSettingDepositPercentage}
            />
          </div>
        </div>
      )}
      <div className="engineering-service-create-service-button-container mt-4">
        {recordingService && (
          <Button
            className="engineering-service-button"
            onClick={handleDeleteRecordingService}
            disabled={loading}
            variant={ButtonVariant.OUTLINED}
          >
            Delete Service
          </Button>
        )}
        <Button
          className="engineering-service-button"
          onClick={handleCreateRecordingService}
          disabled={loading}
          loading={loading}
        >
          Next Step
        </Button>
      </div>
      {!hideGoogleCalendar && calendarIntegrated && (
        <div className="collapsable-remove-calender-integration-container">
          <CollapsableComponent hintText={"Google Calendar Integration"}>
            <p className="b1 mb-4 text-center">
              Revoke access to your{" "}
              <span className="b1-semi-bold google-product-span">
                Google Calendar
              </span>
              . This will remove your{` ${email ?? ""}`} account from the
              EngineEars platform and your calendar will no longer be synced
              with EngineEars and your recording services will be removed.
            </p>
            <button
              className="calendar-setup-button"
              onClick={handleRemovingGoogleCalendarIntegration}
            >
              {!loading && (
                <img
                  alt={"google calendar icon"}
                  src={GoogleCalendarIcon}
                  width={20}
                  height={20}
                />
              )}
              {loading ? (
                <SoundWaveLoader whiteLoader={false} width={120} height={20} />
              ) : (
                <p className="ml-2">
                  Unlink{" "}
                  <span className="b1-semi-bold google-product-span">
                    Google Calendar
                  </span>
                </p>
              )}
            </button>
          </CollapsableComponent>
        </div>
      )}
    </div>
  );
};
