import { ReactNode } from "react";
import { getImageUrl } from "../../../constants/googleStorage";
import { isValidRecordingServiceStudio } from "../../../hooks/useValidRecordingServices";
import { LoadEngineeringServicesResponse } from "../../../store/actions/services";
import EntityPhoto from "../../../store/models/entityPhoto";
import { RecordingService } from "../../../store/models/recording";
import Service, { transformRawData } from "../../../store/models/service";
import User from "../../../store/models/user";
import {
  BucketName,
  getImageProps,
  getOriginalImageProps,
} from "../../../store/utils";
import { ArtistReleaseTabView } from "../../components/ArtistReleaseTabView/ArtistReleaseTabView";
import { Overview } from "../../components/OverviewTab/Overview";
import { PortfolioTab } from "../../components/PortfolioTab/PortfolioTab";
import { ReviewsListV2Wrapper } from "../../components/ReviewsList/ReviewsListV2/ReviewsListWrapperV2";
import { ServicesTab } from "./components/ServicesTab";

/**
 * Query parameter keys for the Profile Screen
 * @example ?selectedTab=services
 */
export enum ProfileScreenParamKeys {
  SelectedTab = "selectedTab",
}

// These values must be kept in sync with the BE
// engineears/accounts/models.py
export enum ProfileScreenTabs {
  Releases,
  Services,
  Reviews,
  Portfolio,
  Overview,
}

export const profileTabsMap: Record<
  number,
  {
    value: ProfileScreenTabs;
    label: string;
    content: ReactNode | null;
    queryParamsValue: string;
  }
> = {
  [ProfileScreenTabs.Releases]: {
    value: ProfileScreenTabs.Releases,
    label: "Releases",
    content: <ArtistReleaseTabView />,
    queryParamsValue: "releases",
  },
  [ProfileScreenTabs.Services]: {
    value: ProfileScreenTabs.Services,
    label: "Services",
    content: <ServicesTab />,

    queryParamsValue: "services",
  },
  [ProfileScreenTabs.Reviews]: {
    value: ProfileScreenTabs.Reviews,
    label: "Reviews",
    content: <ReviewsListV2Wrapper />,

    queryParamsValue: "reviews",
  },
  [ProfileScreenTabs.Portfolio]: {
    value: ProfileScreenTabs.Portfolio,
    label: "Portfolio",
    content: <PortfolioTab />,

    queryParamsValue: "portfolio",
  },
  [ProfileScreenTabs.Overview]: {
    value: ProfileScreenTabs.Overview,
    label: "Overview",
    content: <Overview />,
    queryParamsValue: "overview",
  },
};

export const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

export const combineServicesFromResponse = (
  engineerId: number,
  services: LoadEngineeringServicesResponse,
  loggedInUser?: User,
) => {
  if (!services) {
    return [];
  }

  const result = [];
  const {
    recording_services: recordingServices,
    services: engineeringServices,
  } = services;

  if (recordingServices) {
    const recordingService = recordingServices.find(
      (service: RecordingService) => {
        return service?.engineer && service.engineer.id === engineerId;
      },
    );

    const studioRecordingServices = recordingServices.filter(
      (service: RecordingService) =>
        Boolean(service.studio_room) &&
        isValidRecordingServiceStudio(
          service.studio_room?.studio,
          loggedInUser,
          false,
        ),
    );

    if (
      recordingService &&
      (Boolean(
        recordingService.will_come_to_you || studioRecordingServices.length,
      ) ||
        engineerId === loggedInUser?.engineer?.id)
    ) {
      result.push(recordingService);
    }
  }

  if (engineeringServices) {
    result.push(
      ...engineeringServices
        .map(transformRawData)
        .filter((service: Service) => service.deleted === null),
    );
  }

  return result;
};

export const getProfileCoverPhotoImageProps = (
  isMobile: boolean,
  userData: User,
) => {
  return isMobile
    ? getImageProps(
        BucketName.COVER_PHOTOS_MOBILE,
        userData.cover_photo_mobile?.path || userData.cover_photo_desktop?.path,
        getImageUrl("profileCoverPhotos/mobile-boomy-orange-banner.svg"),
      )
    : getImageProps(
        BucketName.COVER_PHOTOS,
        userData.cover_photo_desktop?.path,
        getImageUrl("profileCoverPhotos/boomy-orange-banner.svg"),
      );
};

export const getEntityPhotoImageProps = (
  entityPhoto: EntityPhoto | null,
  original = false,
) => {
  if (original) {
    return getOriginalImageProps(BucketName.ENTITY_PHOTO, entityPhoto?.path);
  }
  return getImageProps(
    BucketName.ENTITY_PHOTO,
    entityPhoto?.path,
    getImageUrl("profileCoverPhotos/mobile-boomy-orange-banner.svg"),
  );
};

export const getUserProfileImageProps = (photoPath?: string) => {
  return getImageProps(
    BucketName.USER_PHOTOS,
    photoPath,
    getImageUrl("userProfile/defaultuser.jpg"),
    "",
  );
};
