import { atom } from "jotai";
import { SlimPrivateActiveUser } from "../../store/models/user";
import { activeUserStudiosAtom } from "./activeUserStudiosAtom";
import store from "../../store";
import { setUser } from "../../store/actions/accountInfo";

type ActiveUserState = SlimPrivateActiveUser | undefined;
// We use a private atom to prevent calls that change the data that don't have the desired side effects.
const _activeUserAtom = atom(
  // We cast the type here instead of using <> to let typescript's magic figure out the type naturally
  undefined as ActiveUserState,
);

// This buffer atom exists so we can create side effects
// We know these will run when a developer interacts with the active user
export const activeUserAtom = atom(
  (get) => {
    // We just do a simple return of the inner atom
    return get(_activeUserAtom);
  },
  (
    _,
    set,
    user: ActiveUserState | ((oldUser: ActiveUserState) => ActiveUserState),
  ) => {
    // Since the value may be a function, we need to capture the value
    let result: ActiveUserState = undefined;
    // This is a helper function to capture the result from the set state action
    set(_activeUserAtom, (oldState) => {
      // The following functions are clean up no matter how the user changes
      // Unset the list of studios the user has to be safe
      // It should get reset by the hook anyways
      set(activeUserStudiosAtom, []);
      if (typeof user === "function") {
        result = user(oldState);
      } else {
        result = user;
      }
      if (!result) {
        store.dispatch(setUser(undefined));
      }
      return result;
    });
  },
);
