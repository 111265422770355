import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { localServiceCoverPhotoAtom } from "../../../atoms/profileScreenEdit";
import {
  useGetServicePhotoSignedUrl,
  useUploadServicePhoto,
} from "../../../hooks/profileScreenHooks/useUploadServicePhoto";
import {
  MAXIMUM_DEPOSIT,
  MINIMUM_DEPOSIT,
} from "../../../hooks/useManageEngineeringService";
import { useMinAndMaxServiceRates } from "../../../hooks/useMinAndMaxServiceRates";
import { SCREENS } from "../../../routes";
import { removeCalendarIntegration } from "../../../store/actions/calendarService";
import {
  createRecordingService,
  createRecordingServiceParams,
  deleteRecordingService,
} from "../../../store/actions/recording";
import { updateBookingStatus } from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { Discounts, RecordingService } from "../../../store/models/recording";
import { StudioRoom } from "../../../store/models/studio";
import { selectIsUserRecordingEnabled } from "../../../store/selectors/userInfoSelectors";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  convertDiscountRatesToDisplayableRates,
  convertIndexFromHourOptionsToMilliseconds,
  convertMillisecondsToMinutesRoundedToInt,
  convertMinutesToHourOptionType,
  getHourOptionsWithMinimumDuration,
  hourOptions,
} from "../../../store/utils/serviceUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../core-ui/components/Button/FixedMinWidthButton";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import { PercentageTextfield } from "../../elements/PercentageTextField/PercentageTextfield";
import { PriceField } from "../../elements/PriceField/PriceField";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { ServicesFormModalFooter } from "../../screens/ProfileScreen/components/ServicesFormModalFooter";
import { CalendarManageView } from "../CalendarManageView/CalendarManageView";
import { CollapsableComponent } from "../CollapsableComponent/CollapsableComponent";
import { LocationInput } from "../LocationInput/LocationInput";
import "../ManageEngineeringService/ManageEngineeringService.css";
import {
  BlockDiscountContainer,
  ServiceFormColumn,
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
} from "../ManageEngineeringService/ManageEngineeringService.styles";
import { SelectWorkingHoursComponent } from "../SelectWorkingHoursComponent/SelectWorkingHoursComponent";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import { HighlightedTextContainer } from "./AddRecordingService.styles";
import { AddBlockDiscountButton } from "./components/AddBlockDiscountButton/AddBlockDiscountButton";

interface AddEngineeringServiceProps {
  onSuccessfulSubmit?: (service?: RecordingService) => void;
  onServiceUpdated?: (service: RecordingService) => void;
  studioRoom?: StudioRoom;
  studioId?: number;
  canManageStudio?: boolean;
  hideGoogleCalendar?: boolean;
  detailsUpdateOnly?: boolean;
  onUpdatePrice?: (newPrice: number) => void;
  onCancel?: () => void;
  isRevalidatingData?: boolean;
}

export interface DiscountRateService {
  minTimeSelected: OptionType;
  discount: Discounts;
}

const MIN_TRAVEL_DISTANCE = 0;
const MAX_TRAVEL_DISTANCE = 100;

export const AddRecordingService: React.FC<AddEngineeringServiceProps> = ({
  onSuccessfulSubmit,
  onServiceUpdated,
  studioRoom,
  studioId,
  canManageStudio,
  hideGoogleCalendar,
  onUpdatePrice,
  detailsUpdateOnly = false,
  onCancel,
  isRevalidatingData,
}) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const [recordingLocation, setRecordingLocation] = useState<
    undefined | google.maps.places.PlaceResult
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isRecordingEnabled: boolean = useAppSelector(
    selectIsUserRecordingEnabled,
  );
  const [acceptingBookings, setBooking] = useState<boolean>(true);
  const engineerRecordingService = useAppSelector(
    (state) => state.engineerServices.recordingService,
  );
  const recordingService = useMemo(() => {
    if (studioRoom) {
      return studioRoom.recording_service;
    }
    return engineerRecordingService;
  }, [engineerRecordingService, studioRoom]);

  const [allowPartialPayments, setAllowPartialPayments] = useState<boolean>(
    recordingService?.minimum_deposit_percentage
      ? recordingService.minimum_deposit_percentage < 1
      : false,
  );

  const [minServiceRate, maxServiceRate] = useMinAndMaxServiceRates(
    ProjectType.RECORDING,
  );
  const [minimumSessionTime, setMinimumSessionTime] = useState<OptionType>(
    recordingService
      ? convertMinutesToHourOptionType(
          recordingService.minimum_session_time_minutes,
        )
      : hourOptions[0],
  );

  const maximumSessionTimeOptions = useMemo(() => {
    return [...hourOptions].reverse();
  }, [hourOptions]);
  const [maximumSessionTime, setMaximumSessionTime] = useState<OptionType>(
    recordingService
      ? convertMinutesToHourOptionType(
          recordingService.maximum_session_time_minutes,
        )
      : maximumSessionTimeOptions[0],
  );
  const [minimumGapBetweenSessions, setMinimumGapBetweenSessions] =
    useState<number>(
      recordingService
        ? recordingService.minimum_gap_between_sessions_in_minutes
        : 0,
    );
  const [numberHoursNotice, setNumberHoursNotice] = useState<number>(
    recordingService ? recordingService.number_of_hours_notice : 0,
  );
  const [showWorkingHoursSetup, setShowWorkingHoursSetup] =
    useState<boolean>(false);
  const [discountRates, setDiscountRate] = useState<DiscountRateService[]>(
    recordingService
      ? convertDiscountRatesToDisplayableRates(recordingService)
      : [],
  );
  const [showDiscount, setShowDiscount] = useState<boolean>(
    discountRates.length > 0,
  );
  const [coverPhotoToBeCreated] = useAtom(localServiceCoverPhotoAtom);
  const { mutateAsync: getSignedUrl, isPending: isGetSignedUrlLoading } =
    useGetServicePhotoSignedUrl();
  const { mutateAsync: uploadPhoto, isPending: isUploadPhotoLoading } =
    useUploadServicePhoto();

  const isLoading =
    loading ||
    isGetSignedUrlLoading ||
    isUploadPhotoLoading ||
    isRevalidatingData;

  const discountHourOptions = getHourOptionsWithMinimumDuration(
    minimumSessionTime.value,
    maximumSessionTime.value,
  );

  const [pendingRecordingService, setPendingRecordingService] =
    useState<createRecordingServiceParams>(
      recordingService
        ? {
            price: recordingService.service_rate?.price || 0,
            label_price: recordingService?.service_rate?.label_price || 0,
            travel_to_artist_price:
              recordingService?.service_rate?.travel_to_artist_price || 0,
            minimum_session_time_minutes:
              recordingService.minimum_session_time_minutes || 0,
            maximum_session_time_minutes:
              recordingService.maximum_session_time_minutes || 0,
            will_come_to_you: Boolean(recordingService.will_come_to_you),
            max_travel_distance_minutes:
              recordingService.max_travel_distance_minutes || 0,
            equipment_highlights: recordingService.equipment_highlights || "",
            arrival_information: recordingService.arrival_information || "",
            recording_location:
              recordingService.recording_location?.city_location,
            unit_number: recordingService.unit_number || "",
            minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
            number_of_hours_notice: numberHoursNotice,
            minimum_deposit_percentage:
              recordingService.minimum_deposit_percentage.toString() ?? 1.0,
          }
        : {
            price: 0,
            label_price: 0,
            travel_to_artist_price: 0,
            minimum_session_time_minutes: 0,
            maximum_session_time_minutes: 0,
            will_come_to_you: true,
            max_travel_distance_minutes: 0,
            equipment_highlights: "",
            arrival_information: "",
            recording_location: undefined,
            unit_number: "",
            minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
            number_of_hours_notice: numberHoursNotice,
            minimum_deposit_percentage: "1.0",
          },
    );

  useEffect(() => {
    if (recordingService) {
      setPendingRecordingService({
        price: recordingService.service_rate?.price || 0,
        label_price: recordingService?.service_rate?.label_price || 0,
        travel_to_artist_price:
          recordingService?.service_rate?.travel_to_artist_price || 0,
        minimum_session_time_minutes:
          recordingService.minimum_session_time_minutes || 0,
        maximum_session_time_minutes:
          recordingService.maximum_session_time_minutes || 0,
        will_come_to_you: true,
        max_travel_distance_minutes:
          recordingService.max_travel_distance_minutes || 0,
        equipment_highlights: recordingService.equipment_highlights || "",
        arrival_information: recordingService.arrival_information || "",
        recording_location: recordingService.recording_location?.city_location,
        unit_number: recordingService.unit_number || "",
        minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
        number_of_hours_notice: numberHoursNotice,
        minimum_deposit_percentage:
          recordingService.minimum_deposit_percentage.toString() ?? "1.0",
      });
    }
  }, [minimumGapBetweenSessions, numberHoursNotice, recordingService]);

  useEffect(() => {
    onServiceUpdated?.({
      service_rate: {
        price: pendingRecordingService.price,
        label_price: pendingRecordingService.label_price,
        travel_to_artist_price: pendingRecordingService.travel_to_artist_price,
      },
      id: 0,
      minimum_session_time_minutes: 0,
      maximum_session_time_minutes: 0,
      will_come_to_you: pendingRecordingService.will_come_to_you
        ? "will come to you"
        : null,
      max_travel_distance_minutes:
        pendingRecordingService.max_travel_distance_minutes,
      equipment_highlights: pendingRecordingService.equipment_highlights,
      service_type: ProjectType.RECORDING,
      unit_number: pendingRecordingService.unit_number,
      arrival_information: pendingRecordingService.arrival_information,
      minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
      number_of_hours_notice: numberHoursNotice,
      deleted: null,
      studio_room: null,
      not_accepting_bookings: null,
      minimum_deposit_percentage:
        pendingRecordingService.minimum_deposit_percentage
          ? +pendingRecordingService.minimum_deposit_percentage
          : 1.0,
    });
  }, [
    pendingRecordingService,
    numberHoursNotice,
    minimumGapBetweenSessions,
    onServiceUpdated,
  ]);

  const { calendarIntegrated, email } = useAppSelector(
    (state) => state.calendarService,
  );

  const handleRemovingGoogleCalendarIntegration = () => {
    setLoading(true);
    dispatch(removeCalendarIntegration({ studio_id: studioId }))
      .unwrap()
      .then(() => {
        toast.success("Successfully removed Google Calendar");
      })
      .catch(() => {
        toast.error("Error removing Google Calendar");
      });
  };

  const handleDeleteRecordingService = () => {
    if (!recordingService) return;
    setLoading(true);
    emitAnalyticsTrackingEvent(
      "recording_service_deleted",
      {
        serviceType: "Recording",
        studioRoomId: studioRoom?.id,
        studioId,
      },
      user?.id,
    );
    dispatch(
      deleteRecordingService({
        recording_service_id: recordingService?.id,
      }),
    )
      .unwrap()
      .then((deletedRecordingService: RecordingService) => {
        setLoading(false);
        onSuccessfulSubmit?.(deletedRecordingService);
      })
      .catch(() => {
        toast.error(
          "Error deleting service. Please reach out to customer support.",
        );
        setLoading(false);
      });
  };

  const handleCreateRecordingService = useCallback(() => {
    setLoading(true);
    const location = pendingRecordingService.recording_location as string;
    if (!recordingLocation && !studioRoom && !location) {
      toast.error("Please select a location");
      setLoading(false);
      return;
    }

    const pendingDiscountRates = discountRates.map(
      (discountRate) => discountRate.discount,
    );

    emitAnalyticsTrackingEvent(
      recordingService
        ? "recording_service_updated"
        : "recording_service_created",
      {
        serviceType: "Recording",
        studioRoomId: studioRoom?.id,
        studioId,
        recordingLocation: recordingLocation?.formatted_address,
        minimumSessionTime: minimumSessionTime.value,
        maximumSessionTime: maximumSessionTime.value,
        minimumGapBetweenSessions,
        numberHoursNotice,
        discountRates: pendingDiscountRates,
      },
      user?.id,
    );

    dispatch(
      createRecordingService({
        ...pendingRecordingService,
        minimum_session_time_minutes: convertMillisecondsToMinutesRoundedToInt(
          convertIndexFromHourOptionsToMilliseconds(minimumSessionTime.value),
        ),
        maximum_session_time_minutes: convertMillisecondsToMinutesRoundedToInt(
          convertIndexFromHourOptionsToMilliseconds(maximumSessionTime.value),
        ),
        recording_location: studioRoom ? undefined : recordingLocation,
        minimum_gap_between_sessions_in_minutes: minimumGapBetweenSessions,
        number_of_hours_notice: numberHoursNotice,
        studio_room_id: studioRoom?.id,
        discount_rates: pendingDiscountRates,
      }),
    )
      .unwrap()
      .then(async (service: RecordingService) => {
        setLoading(false);

        if (coverPhotoToBeCreated) {
          const { signed_url } = await getSignedUrl({
            service_id: service.id,
            service_type: service.service_type,
            content_type: coverPhotoToBeCreated.type,
            file_size: coverPhotoToBeCreated.size,
          });

          await uploadPhoto({ url: signed_url, file: coverPhotoToBeCreated });
        }

        onSuccessfulSubmit?.(service);
        onServiceUpdated?.(service);
        if (!detailsUpdateOnly) {
          setShowWorkingHoursSetup(true);
        }
      })
      .catch(() => {
        toast.error(
          "Error creating service. Please reach out to customer support.",
        );
        setLoading(false);
      });
  }, [
    dispatch,
    maximumSessionTime.value,
    minimumGapBetweenSessions,
    discountRates,
    minimumSessionTime.value,
    numberHoursNotice,
    onServiceUpdated,
    pendingRecordingService,
    recordingLocation,
    studioRoom?.id,
    coverPhotoToBeCreated,
  ]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.discount.price < pendingRecordingService.price,
      );
    });
  }, [pendingRecordingService.price]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.minTimeSelected.value > minimumSessionTime.value,
      );
    });
  }, [minimumSessionTime]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.minTimeSelected.value < maximumSessionTime.value,
      );
    });
  }, [maximumSessionTime]);

  useEffect(() => {
    if (discountRates.length === 0) {
      setShowDiscount(false);
    }
  }, [discountRates]);

  const removeDiscountRate = (index: number) => {
    if (index < 0 || index >= discountRates.length) {
      return;
    }
    const newDiscountRate = [...discountRates];
    newDiscountRate.splice(index, 1);
    setDiscountRate(newDiscountRate);
  };

  const setPriceForDiscountRate = (
    minTimeToEnableRate: number,
    value: number,
    index: number,
  ) => {
    let hourlyRate: number = value / (minTimeToEnableRate / 60);
    if (index < 0 || index >= discountRates.length) {
      return;
    }

    if (hourlyRate > pendingRecordingService.price) {
      hourlyRate = +pendingRecordingService.price;
    }
    const discountRate = discountRates[index];
    discountRate.discount.price = +hourlyRate.toFixed(2);
    const newDiscountRate = [...discountRates];
    newDiscountRate[index] = discountRate;
    setDiscountRate(newDiscountRate);
  };

  const setMinimumTimeForDiscountRate = (
    selected: OptionType,
    index: number,
  ) => {
    if (
      discountRates.some(
        (discountRate) => discountRate.minTimeSelected.label === selected.label,
      )
    ) {
      return;
    }
    if (index < 0 || index >= discountRates.length) {
      return;
    }
    const selectedSessionInMinutes = selected.value * 60;
    if (selectedSessionInMinutes < minimumSessionTime.value * 60) {
      return;
    }
    if (selectedSessionInMinutes > maximumSessionTime.value * 60) {
      return;
    }
    const discountRate = discountRates[index];
    discountRate.minTimeSelected = selected;
    discountRate.discount.minimum_time_to_enable_rate = selected.value * 60;
    const newDiscountRates = [...discountRates];
    newDiscountRates[index] = discountRate;
    setDiscountRate([...newDiscountRates]);
  };

  const changeEquipmentHighlights = useCallback((value: string) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      equipment_highlights: value,
    }));
  }, []);

  const changeUnitNumber = useCallback((value: string) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      unit_number: value,
    }));
  }, []);

  const changeServiceRatePrice = useCallback((updatedPrice: number) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      price: updatedPrice,
    }));
    onUpdatePrice?.(updatedPrice);
  }, []);

  const changeTravelToArtistRatePrice = useCallback((updatedPrice: number) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      travel_to_artist_price: updatedPrice,
    }));
  }, []);

  useEffect(() => {
    if (!studioRoom?.recording_service) {
      return;
    }
    setBooking(!studioRoom?.recording_service?.not_accepting_bookings);
  }, [studioRoom]);

  useEffect(() => {
    if (!allowPartialPayments) {
      handleSettingDepositPercentage(1);
    }
  }, [allowPartialPayments]);

  const handleSettingDepositPercentage = (newValue: number) => {
    setPendingRecordingService((curr) => {
      return {
        ...curr,
        minimum_deposit_percentage: newValue.toString(),
      };
    });
  };

  const minimumDepositDescription = useMemo(() => {
    if (!pendingRecordingService.price) {
      return "Set service price";
    }
    if (!pendingRecordingService.minimum_deposit_percentage) {
      return "Set minimum deposit percentage";
    }
    return `${PennyDollarFormatter().format(+pendingRecordingService.minimum_deposit_percentage * pendingRecordingService.price)}`;
  }, [pendingRecordingService]);

  if (!isRecordingEnabled && !canManageStudio && !recordingService) {
    return (
      <div>
        <p>
          Recording is in beta. Please reach out to the EngineEars team if you
          would like to host Recording services.
        </p>
      </div>
    );
  }

  if (!calendarIntegrated) {
    return <CalendarManageView studioID={studioId} />;
  }
  if (showWorkingHoursSetup && !detailsUpdateOnly) {
    return (
      <SelectWorkingHoursComponent
        studioRoomId={studioRoom?.id}
        studioRoomName={studioRoom?.room_name}
        onSuccessfulSubmit={() => {
          onSuccessfulSubmit?.();
        }}
        setShowWorkingHoursSetup={setShowWorkingHoursSetup}
      />
    );
  }

  const onToggleBooking = async (checkState: boolean) => {
    if (!studioRoom?.recording_service) return;
    try {
      await dispatch(
        updateBookingStatus({
          studioRoomId: studioRoom.id,
          notAcceptingBookings: !checkState,
        }),
      ).unwrap();
    } finally {
      setBooking(checkState);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "32px",
        width: "100%",
      }}
    >
      {studioRoom?.recording_service && (
        <div className={"add-engineer-service-form-row"}>
          <div className="add-engineer-service-form-row-label">
            <p>Accepting Bookings?</p>
          </div>
          <div
            className="add-engineer-service-form-row-input alt-percentage-slider"
            style={{ width: "100%" }}
          >
            <ToggleSwitch
              id="accepting-bookings-toggle"
              checked={acceptingBookings}
              onChange={onToggleBooking}
              label={acceptingBookings ? "Currently Booking" : "Not Booking"}
              updateCheckedLocally={false}
            />
          </div>
        </div>
      )}
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>Hourly rate ($)</ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <PriceField
            min={minServiceRate}
            max={maxServiceRate}
            // The initial data received from the API is string, so we need to parse here
            value={parseInt(`${pendingRecordingService.price}`)}
            placeholder="Service Rate"
            onChange={changeServiceRatePrice}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            {studioRoom
              ? "How much lead time is required for bookings in this room?"
              : "How far in advance do you need to be booked?"}
            <strong>&nbsp;(hours)</strong>
          </ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <FixedRangeNumberField
            min={0}
            max={24}
            initialValue={numberHoursNotice}
            placeholder="Hours"
            onChange={(value) => {
              setNumberHoursNotice(value);
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            How much time do you need between bookings?{" "}
            <strong>&nbsp;(minutes)</strong>
          </ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <FixedRangeNumberField
            min={0}
            max={120}
            initialValue={minimumGapBetweenSessions}
            placeholder="Minutes"
            onChange={(value) => {
              setMinimumGapBetweenSessions(value);
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>Minimum Session Time</ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <DropdownSelector
            value={minimumSessionTime}
            options={hourOptions}
            placeholder={"Minimum Session Time"}
            onChange={(selected) => {
              if (selected.value > maximumSessionTime.value) {
                setMaximumSessionTime(selected);
                setMinimumSessionTime(selected);
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time: selected.value,
                  maximum_session_time: selected.value,
                }));
              } else {
                setMinimumSessionTime(selected);
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time: selected.value,
                }));
              }
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>Maximum Session Time</ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <DropdownSelector
            value={maximumSessionTime}
            options={maximumSessionTimeOptions}
            placeholder={"Minimum Session Time "}
            onChange={(selected) => {
              if (selected.value < minimumSessionTime.value) {
                setMinimumSessionTime(selected);
                setMaximumSessionTime(selected);
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time: selected.value,
                  maximum_session_time: selected.value,
                }));
              } else {
                setMaximumSessionTime(selected);
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  maximum_session_time: selected.value,
                }));
              }
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <BlockDiscountContainer>
        <ServiceFormRow>
          <ServiceFormLabelContainer>
            <Box>
              <ServiceFormLabel>Offer block discounts</ServiceFormLabel>
            </Box>
          </ServiceFormLabelContainer>
          <ServiceFormInputContainer>
            <ToggleSwitch
              checked={showDiscount}
              updateCheckedLocally={false}
              onChange={(checkedState) => {
                if (discountRates.length === 0 && discountHourOptions.length) {
                  const discountAddSessionTime =
                    discountHourOptions[0].value * 60;
                  const optionSelected = {
                    minTimeSelected: discountHourOptions[0],
                    discount: {
                      minimum_time_to_enable_rate: discountAddSessionTime,
                      price: pendingRecordingService.price,
                    },
                  };
                  setDiscountRate([optionSelected]);
                }
                setShowDiscount(checkedState);
                if (!checkedState) {
                  setDiscountRate([]);
                }
              }}
            />
          </ServiceFormInputContainer>
        </ServiceFormRow>
        {discountRates.length > 0 && (
          <div className="discount-options-container">
            {showDiscount &&
              discountRates.map((discountRate, index) => {
                let discountPercentage = 0;
                if (
                  pendingRecordingService.price &&
                  discountRate.discount.price
                ) {
                  discountPercentage =
                    ((pendingRecordingService.price -
                      discountRate.discount.price) /
                      pendingRecordingService.price) *
                    100;
                }

                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      columnGap: "12px",
                      rowGap: "12px",
                      flexWrap: "wrap",

                      ".drop-down-selector-container": {
                        width: "120px",
                      },
                    }}
                    key={index}
                  >
                    <Button
                      variant={ButtonVariant.ICON}
                      onClick={() => removeDiscountRate(index)}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </Button>
                    <DropdownSelector
                      value={discountRate.minTimeSelected}
                      options={getHourOptionsWithMinimumDuration(
                        minimumSessionTime ? minimumSessionTime.value : 3,
                        maximumSessionTime ? maximumSessionTime.value : 24,
                      )}
                      placeholder={"Minimum Session Time "}
                      onChange={(selected) =>
                        setMinimumTimeForDiscountRate(selected, index)
                      }
                    />
                    <Text>for</Text>
                    <PriceField
                      min={
                        minServiceRate *
                        (discountRate.discount.minimum_time_to_enable_rate / 60)
                      }
                      max={
                        pendingRecordingService.price *
                        discountRate.minTimeSelected.value
                      }
                      value={
                        (discountRate.discount.price *
                          discountRate.discount.minimum_time_to_enable_rate) /
                        60
                      }
                      placeholder="Service Rate"
                      className="discount-options-fixed-range-number-field"
                      onChange={(value) => {
                        setPriceForDiscountRate(
                          discountRate.discount.minimum_time_to_enable_rate,
                          value,
                          index,
                        );
                      }}
                      style={{ width: "95px" }}
                    />
                    <Text>total ({discountPercentage.toFixed(2)}% off).</Text>
                  </Box>
                );
              })}
          </div>
        )}
        {(showDiscount || discountRates.length > 0) && (
          <AddBlockDiscountButton
            discountRates={discountRates}
            minimumSessionTimeValue={minimumSessionTime.value}
            maximumSessionTimeValue={maximumSessionTime.value}
            pendingServicePrice={pendingRecordingService.price}
            setDiscountRate={setDiscountRate}
            setShowDiscount={setShowDiscount}
          />
        )}
      </BlockDiscountContainer>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <ServiceFormLabel>Allow partial payments&nbsp;</ServiceFormLabel>
            <ToolTipTextArea
              text={
                "Allows your clients to put down an initial deposit when booking; this percentage determines the initial deposit amount."
              }
            />
          </Box>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <ToggleSwitch
            checked={allowPartialPayments}
            onChange={(checked: boolean) => {
              setAllowPartialPayments(checked);
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      {allowPartialPayments && (
        <ServiceFormRow>
          <ServiceFormLabelContainer>
            <ServiceFormLabel>Minimum deposit required</ServiceFormLabel>
            <ServiceFormLabelSubtext>
              (Initial deposit amount {minimumDepositDescription} )
            </ServiceFormLabelSubtext>
          </ServiceFormLabelContainer>
          <ServiceFormInputContainer>
            <PercentageTextfield
              max={MAXIMUM_DEPOSIT}
              min={MINIMUM_DEPOSIT}
              value={
                pendingRecordingService.minimum_deposit_percentage
                  ? parseFloat(
                      pendingRecordingService.minimum_deposit_percentage,
                    )
                  : 0
              }
              onChangePercentage={handleSettingDepositPercentage}
              step={5}
            />
          </ServiceFormInputContainer>
        </ServiceFormRow>
      )}

      {!studioRoom && (
        <>
          <ServiceFormColumn>
            <div>
              <ServiceFormLabel>My Location:</ServiceFormLabel>
            </div>
            <LocationInput
              defaultValue={
                pendingRecordingService.recording_location as string
              }
              onPlaceSelected={(place) => {
                setRecordingLocation(place);
              }}
              showBorder
            />
          </ServiceFormColumn>
          <HighlightedTextContainer>
            <Text
              variant={TextStyleVariant.P2}
              bold
              color={TextColor.PRIMARY_NO_DARK_THEME}
            >
              You can update your location at any time in{" "}
              {recordingService ? (
                <Link
                  style={{
                    fontWeight: "bold",
                    display: "inline-block",
                    minHeight: "auto",
                  }}
                  to={SCREENS.SETTINGS_SCREEN}
                >
                  Account Settings.
                </Link>
              ) : (
                <span>Account Settings.</span>
              )}
            </Text>
          </HighlightedTextContainer>
        </>
      )}

      {!studioId && (
        <>
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <Box>
                <ServiceFormLabel>
                  Willing to travel to the artist for sessions
                </ServiceFormLabel>
              </Box>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <ToggleSwitch
                checked={pendingRecordingService.will_come_to_you}
                onChange={(value) => {
                  setPendingRecordingService((currentRecordingService) => ({
                    ...currentRecordingService,
                    will_come_to_you: value,
                    travel_to_artist_price: value ? minServiceRate : 0,
                    max_travel_distance_minutes: value
                      ? pendingRecordingService.max_travel_distance_minutes ?? 0
                      : 0,
                  }));
                }}
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
          {pendingRecordingService.will_come_to_you && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Text
                style={{ marginBottom: "16px" }}
                weight={TEXT_WEIGHT.SEMI_BOLD}
              >
                For sessions outside your affiliated studios:
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "32px",
                }}
              >
                <ServiceFormRow>
                  <ServiceFormLabelContainer>
                    <ServiceFormLabel>
                      How far are you willing to travel? (km):{" "}
                    </ServiceFormLabel>
                  </ServiceFormLabelContainer>
                  <ServiceFormInputContainer>
                    <FixedRangeNumberField
                      min={MIN_TRAVEL_DISTANCE}
                      max={MAX_TRAVEL_DISTANCE}
                      initialValue={
                        pendingRecordingService.max_travel_distance_minutes
                      }
                      onChange={(value: number) => {
                        setPendingRecordingService((prevState) => ({
                          ...prevState,
                          max_travel_distance_minutes: value,
                        }));
                      }}
                    />
                  </ServiceFormInputContainer>
                </ServiceFormRow>
                <ServiceFormRow>
                  <ServiceFormLabelContainer>
                    <ServiceFormLabel>
                      Travel to artist service rate (per hour)
                    </ServiceFormLabel>
                  </ServiceFormLabelContainer>
                  <ServiceFormInputContainer>
                    <PriceField
                      min={minServiceRate}
                      max={maxServiceRate}
                      value={
                        Number(pendingRecordingService.travel_to_artist_price)
                          ? Number(
                              pendingRecordingService.travel_to_artist_price,
                            )
                          : minServiceRate
                      }
                      placeholder="Travel To Artist Rate"
                      onChange={changeTravelToArtistRatePrice}
                    />
                  </ServiceFormInputContainer>
                </ServiceFormRow>
              </Box>
            </Box>
          )}
        </>
      )}

      {studioId && !studioRoom && (
        <ServiceFormColumn>
          <div>
            <ServiceFormLabel>Unit number (if applicable):</ServiceFormLabel>
          </div>
          <div>
            <EditableTextArea
              editMode={true}
              numberOfLines={1}
              width={"100%"}
              initialValue={pendingRecordingService.unit_number ?? ""}
              onChange={changeUnitNumber}
              placeholder={"Enter unit number (if applicable)"}
            />
          </div>
        </ServiceFormColumn>
      )}

      {!studioRoom && pendingRecordingService.will_come_to_you && (
        <ServiceFormColumn>
          <div>
            <ServiceFormLabel>Equipment:</ServiceFormLabel>
          </div>
          <div>
            <EditableTextArea
              editMode={true}
              width={"100%"}
              initialValue={pendingRecordingService.equipment_highlights}
              onChange={changeEquipmentHighlights}
              placeholder={"Enter equipment highlights"}
            />
          </div>
        </ServiceFormColumn>
      )}
      {studioId && !studioRoom && (
        <ServiceFormColumn>
          <div>
            <p>Transit, parking, and access information:</p>
          </div>
          <div>
            <EditableTextArea
              editMode={true}
              width={"100%"}
              initialValue={pendingRecordingService.arrival_information}
              onChange={(value) => {
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  arrival_information: value,
                }));
              }}
              placeholder={"Enter information for the client"}
            />
          </div>
        </ServiceFormColumn>
      )}

      {studioRoom ? (
        <div className="engineering-service-create-service-button-container mt-4">
          {recordingService && (
            <Button
              className="engineering-service-button"
              onClick={handleDeleteRecordingService}
              disabled={loading}
              variant={ButtonVariant.OUTLINED}
            >
              Delete Service
            </Button>
          )}
          <Button
            className="engineering-service-button"
            onClick={handleCreateRecordingService}
            disabled={loading}
            loading={loading}
          >
            Next Step
          </Button>
        </div>
      ) : (
        <ServicesFormModalFooter>
          <FixedMinWidthButton
            variant={ButtonVariant.OUTLINED}
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </FixedMinWidthButton>
          <FixedMinWidthButton
            onClick={handleCreateRecordingService}
            disabled={isLoading}
            loading={isLoading}
          >
            Save
          </FixedMinWidthButton>
        </ServicesFormModalFooter>
      )}
      {!hideGoogleCalendar && calendarIntegrated && (
        <CollapsableComponent hintText={"Google Calendar Integration"}>
          <ServiceFormColumn sx={{ marginTop: "8px", marginBottom: "16px" }}>
            <CalendarManageView showExplanation={true} />
          </ServiceFormColumn>
          <HighlightedTextContainer>
            <Text
              variant={TextStyleVariant.P2}
              bold
              color={TextColor.PRIMARY_NO_DARK_THEME}
            >
              You can also link your Google Calendar at any time in{" "}
              {recordingService ? (
                <Link
                  style={{
                    fontWeight: "bold",
                    display: "inline-block",
                    minHeight: "auto",
                  }}
                  to={SCREENS.SETTINGS_SCREEN}
                >
                  Account Settings.
                </Link>
              ) : (
                <span>Account Settings.</span>
              )}
            </Text>
          </HighlightedTextContainer>
        </CollapsableComponent>
      )}
    </Box>
  );
};
