import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, keyframes, styled } from "@mui/material";

export const LongBioContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  height: "100%",
});

const slideDownAnimation = keyframes({
  from: {
    maxHeight: "175px",
  },
  to: {
    maxHeight: "unset",
  },
});

export const BioInput = styled("textarea")<{
  isEditMode: boolean;
  expand?: boolean;
}>(({ theme, isEditMode, expand = false }) => ({
  color: theme.palette.text.primary,
  border: "unset",
  borderRadius: theme.shape.borderRadius,
  padding: "unset",
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  maxHeight: "175px",
  resize: "none",
  background: "inherit",
  ":focus": {
    outline: !isEditMode && "unset",
  },
  ...(expand && {
    animation: `${slideDownAnimation} 0.5s ease-in-out forwards`,
    maxHeight: "unset",
  }),
  ...(isEditMode && {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: "16px",
    overflow: "auto",
  }),
}));

export const LongBioEmptyContainer = styled(Box)(({ theme }) => ({
  height: "190px",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: theme.palette.customColor.midgroundColor,
  alignItems: "center",
  gap: theme.spacing(4),
  alignSelf: "stretch",
}));

export const StyledLongBioEmptyFaIcon = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.standardColor.Gray[300],
    width: "45px",
    height: "40px",
    flexShrink: 0,
  }),
);
