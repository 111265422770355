import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { receiveErrors } from "../../store/actions/errorStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Studio } from "../../store/models/studio";
import User from "../../store/models/user";
import { selectCurrentUser } from "../../store/selectors/userInfoSelectors";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_AFFILIATED_STUDIOS } from "../../store/utils/routes";
import { useIsStudioManager } from "../bookingHooks/useStudioEngineerUser";

export const useGetAffiliatedStudiosForUser = (user?: User) => {
  const userId = user?.id;
  const currentUser = useAppSelector(selectCurrentUser);
  const isUserEngineer = Boolean(user?.engineer && !user?.engineer.deleted);
  const isStudioManager = useIsStudioManager(user);
  const dispatch = useAppDispatch();

  return useQuery({
    staleTime: userId && userId === currentUser?.id ? Infinity : undefined,
    queryKey: [QUERY_KEYS.GET_AFFILIATED_STUDIOS, userId],
    enabled: Boolean(userId) && (isUserEngineer || isStudioManager),
    queryFn: async () => {
      const params = `?user_id=${userId}`;
      const result = await makeBackendGetCallWithJsonResponse<Studio[]>(
        GET_AFFILIATED_STUDIOS,
        params,
      );
      if (result.success) {
        return result.resultJson;
      }
      const errors = { errors: result.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(errors);
    },
  });
};
