import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import "./ToolTipTextArea.css";
import { TooltipHelperContainer, TooltipText } from "./ToolTipTextArea.styles";

const MINIMUM_TOOLTIP_WIDTH = 250;
export interface ToolTipTextAreaProps {
  text: string;
  tooltipTextStyle?: React.CSSProperties;
  children?: React.ReactNode;
  containerProps?: Partial<HTMLAttributes<HTMLDivElement>>;
}

export const ToolTipTextArea = ({
  text,
  tooltipTextStyle = {},
  children,
  containerProps,
}: ToolTipTextAreaProps) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isClicked, setIsClicked] = React.useState(false);

  useEffect(() => {
    if (isMobile) {
      setIsClicked(false);
    }
  }, [isMobile]);

  const tooltip = useMemo(() => {
    const rect = ref.current?.getBoundingClientRect();
    let left = (rect?.x || 0) + (rect?.width || 0);
    let maxWidth = window.innerWidth - left;
    if (maxWidth < MINIMUM_TOOLTIP_WIDTH) {
      left = left - (MINIMUM_TOOLTIP_WIDTH - maxWidth);
      maxWidth = MINIMUM_TOOLTIP_WIDTH;
    }
    return createPortal(
      <TooltipText
        className="tooltiptext"
        style={{
          top: (rect?.y || 0) + (rect?.height || 0),
          left: left,
          visibility: active ? "visible" : "hidden",
          maxWidth: `min(${MINIMUM_TOOLTIP_WIDTH}px, ${maxWidth}px)`,
          ...tooltipTextStyle,
        }}
      >
        {text}
      </TooltipText>,
      document.body,
    );
  }, [text, tooltipTextStyle, active, ref]);

  if (!text) {
    return <>{children}</>;
  }

  return (
    <TooltipHelperContainer
      className="tooltip-helper-container tooltip"
      onMouseEnter={() => {
        setActive(true);
      }}
      onMouseLeave={() => {
        setActive(false);
      }}
      ref={ref}
      {...containerProps}
    >
      {children ?? (
        <FontAwesomeIcon
          icon={faCircleQuestion}
          color={"var(--tooltip-background-color)"}
          onClick={(e) => {
            setIsClicked(!isClicked && isMobile);
            e.stopPropagation();
          }}
        />
      )}
      {/* Only render tooltip when active for a lighter dom */}
      {active && tooltip}
    </TooltipHelperContainer>
  );
};
