import EntityPhoto from "./entityPhoto";
import { ProjectType, ScheduledProjectPaywallTypes } from "./project";
import User from "./user";

export const EP_SONG_COUNT = 6;
export const EP_DISCOUNT_PERCENTAGE = 15;

export const ALBUM_SONG_COUNT = 12;
export const ALBUM_DISCOUNT_PERCENTAGE = 30;

// Type representing the service data we get back directly from the backend
export interface APIService {
  created: string;
  deleted?: null | string;
  engineer_id: number;
  extra_alt_price: string;
  extra_revision_price: string;
  free_revisions: number;
  id: number;
  incentive_for_service?: Incentive[];
  service_rate: APIServiceRate;
  service_type: ProjectType;
  add_master_discount_percentage: string;
  add_atmos_mix_discount_percentage: string;
  minimum_deposit_percentage: string;
  enable_artist_review_mp3_file_download: boolean;
  enable_artist_review_wav_file_download: boolean;
  enable_bulk_song_discounts: boolean;
  partial_payment_paywall_option?: ScheduledProjectPaywallTypes;
  description: string | null;
  cover_photo: EntityPhoto | null;
}

// Type representing the transformed service data we use in our frontend components
export default interface Service {
  created?: string;
  deleted?: null | string;
  engineer_id: number;
  extra_alt_price: number;
  extra_revision_price: number;
  free_revisions: number;
  id?: number;
  incentive_for_service?: Incentive[];
  service_rate: ServiceRate;
  service_type: ProjectType;
  add_master_discount_percentage?: number;
  add_atmos_mix_discount_percentage?: number;
  minimum_deposit_percentage: number;
  enable_artist_review_mp3_file_download: boolean;
  enable_artist_review_wav_file_download: boolean;
  enable_bulk_song_discounts: boolean;
  partial_payment_paywall_option?: ScheduledProjectPaywallTypes;
  description?: string | null;
  cover_photo?: EntityPhoto | null;
}

export const transformRawData = (data: APIService): Service => {
  return {
    created: new Date(data.created).toISOString(),
    deleted: data.deleted,
    engineer_id: data.engineer_id,
    extra_alt_price: parseFloat(data.extra_alt_price),
    extra_revision_price: parseFloat(data.extra_revision_price),
    free_revisions: data.free_revisions,
    id: data.id,
    service_rate: {
      id: data.service_rate.id,
      created: new Date(data.service_rate.created).toISOString(),
      price: parseFloat(data.service_rate.price),
      label_price: data.service_rate.label_price
        ? parseFloat(data.service_rate.label_price)
        : undefined,
    },
    service_type: data.service_type,
    add_master_discount_percentage: data.add_master_discount_percentage
      ? parseFloat(data.add_master_discount_percentage)
      : undefined,
    add_atmos_mix_discount_percentage: data.add_atmos_mix_discount_percentage
      ? parseFloat(data.add_atmos_mix_discount_percentage)
      : undefined,
    minimum_deposit_percentage: parseFloat(data.minimum_deposit_percentage),
    enable_artist_review_mp3_file_download:
      data.enable_artist_review_mp3_file_download,
    enable_artist_review_wav_file_download:
      data.enable_artist_review_wav_file_download,
    enable_bulk_song_discounts: data.enable_bulk_song_discounts,
    partial_payment_paywall_option: data.partial_payment_paywall_option,
    description: data.description ?? "",
    cover_photo: data.cover_photo ?? null,
  };
};

export interface EngineerSchedulingSurvey {
  service_type: ProjectType;
  songs_per_day?: number;
  workdays?: string;
  number_of_days_notice?: number;
  per_day_rush_fee_percentage?: number;
}

export const MockEngineerSchedulingSurvey: EngineerSchedulingSurvey = {
  service_type: ProjectType.TWO_TRACK_MIXING,
  songs_per_day: 2,
  workdays: "0246",
  number_of_days_notice: 7,
  per_day_rush_fee_percentage: 0.1,
};

export interface APIServiceRate {
  created: string;
  deleted?: string | null;
  id: number;
  price: string;
  label_price?: string | null;
  user_id?: number;
  user?: User | null;
  travel_to_artist_price?: string | null;
}

export interface ServiceRate {
  created?: string;
  deleted?: string | null;
  id?: number;
  price: number;
  label_price?: number | null;
  user_id?: number;
  user?: User | null;
  travel_to_artist_price?: number | null;
}

export const MockServiceRate: ServiceRate = {
  created: "2021-03-22T00:29:09.742414",
  deleted: null,
  id: 9,
  price: 1500,
  label_price: 3000,
  user_id: 4,
};

interface Incentive {
  engineer_id: number;
  id: number;
  deleted: null | string;
  engineering_service_id: number;
  incentive: number;
}

const MockIncentive: Incentive = {
  deleted: null,
  engineer_id: 3,
  engineering_service_id: 9,
  id: 191,
  incentive: 0,
};

export const MockService: Service = {
  created: "2021-03-22T00:29:09.769472",
  deleted: null,
  engineer_id: 3,
  extra_alt_price: 75,
  minimum_deposit_percentage: 0.5,
  extra_revision_price: 225,
  free_revisions: 2,
  id: 9,
  incentive_for_service: [MockIncentive],
  service_rate: MockServiceRate,
  service_type: ProjectType.TWO_TRACK_MIXING,
  enable_artist_review_mp3_file_download: false,
  enable_artist_review_wav_file_download: false,
  enable_bulk_song_discounts: true,
  description: "",
  cover_photo: null,
};

export const ServicesMock: Service[] = [
  {
    id: 9,
    engineer_id: 3,
    created: "2021-03-22T00:29:09.769472",
    deleted: null,
    service_type: 2,
    minimum_deposit_percentage: 0.5,
    enable_artist_review_mp3_file_download: false,
    enable_artist_review_wav_file_download: false,
    enable_bulk_song_discounts: true,
    service_rate: {
      id: 9,
      user_id: 4,
      created: "2021-03-22T00:29:09.742414",
      deleted: null,
      price: 1490,
      label_price: 3000,
    },
    free_revisions: 2,
    extra_revision_price: 223.5,
    extra_alt_price: 74.5,
    incentive_for_service: [
      {
        id: 191,
        incentive: 0,
        deleted: null,
        engineering_service_id: 9,
        engineer_id: 3,
      },
    ],
    partial_payment_paywall_option: 0,
    description: "",
    cover_photo: null,
  },
  {
    id: 14,
    engineer_id: 3,
    minimum_deposit_percentage: 0.5,
    enable_artist_review_mp3_file_download: false,
    enable_artist_review_wav_file_download: false,
    enable_bulk_song_discounts: true,
    created: "2021-06-05T20:14:21.633437",
    deleted: null,
    service_type: 3,
    service_rate: {
      id: 14,
      user_id: 4,
      created: "2021-06-05T20:14:21.595569",
      deleted: null,
      price: 600,
      label_price: 3100,
    },
    free_revisions: 2,
    extra_revision_price: 90,
    extra_alt_price: 90,
    incentive_for_service: [
      {
        id: 234,
        incentive: 0,
        deleted: null,
        engineering_service_id: 14,
        engineer_id: 3,
      },
    ],
    partial_payment_paywall_option: 0,
    description: "",
    cover_photo: null,
  },
  {
    id: 125,
    engineer_id: 3,
    created: "2021-08-25T19:16:54.603526",
    deleted: null,
    service_type: 4,
    minimum_deposit_percentage: 0.5,
    enable_artist_review_mp3_file_download: false,
    enable_artist_review_wav_file_download: false,
    enable_bulk_song_discounts: true,
    service_rate: {
      id: 125,
      user_id: 4,
      created: "2021-08-25T19:16:54.518721",
      deleted: null,
      price: 250,
      label_price: 3200,
    },
    free_revisions: 2,
    extra_revision_price: 12.5,
    extra_alt_price: 12.5,
    incentive_for_service: [
      {
        id: 192,
        incentive: 0,
        deleted: null,
        engineering_service_id: 125,
        engineer_id: 3,
      },
    ],
    partial_payment_paywall_option: 0,
    description: "",
    cover_photo: null,
  },
];
