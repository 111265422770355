import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { editMode } from "../../../../atoms/profileScreenEdit";
import { useGetUserProfile } from "../../../../hooks/profileScreenHooks/useGetUserProfile";
import { useGetEngineerServices } from "../../../../hooks/useGetEngineerServices";
import { useIsAandR } from "../../../../hooks/useIsAandR";
import { useQuery } from "../../../../hooks/useQuery";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import { fetchUniqueBookingLink } from "../../../../store/actions/booking";
import { applyPromoCode } from "../../../../store/actions/marketing";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ProjectType } from "../../../../store/models/project";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";
import { combineServicesFromResponse } from "../utils";
import { AboutMe } from "./AboutMe/AboutMe";
import { EngineerServiceCards } from "./EngineerServiceCards";
import { FrequentlyAskedSectionWrapper } from "./FrequentlyAskedSection/FrequentlyAskedSectionWrapper";

export const ServicesTab = () => {
  const { username } = useParams<{ username: string }>();
  const dispatch = useAppDispatch();
  const queryLocation = useQuery();
  const { user: userMe } = useAppSelector((state) => state.accountInfo);
  const isAandR = useIsAandR(userMe);
  const [isEditMode] = useAtom(editMode);

  const userOnOwnProfile = useMemo(() => {
    return userMe?.username === username;
  }, [userMe?.username, username]);
  const { data: userData } = useGetUserProfile(username);
  const engineerId = userData?.engineer?.id;

  const {
    data: engineerServicesData,
    isFetching: isEngineerServicesLoading,
    isSuccess: isEngineerServicesSuccess,
  } = useGetEngineerServices(engineerId);

  const [editServiceType, setEditServiceType] = useState<
    ProjectType | undefined
  >();

  const addNewServiceQueryParam = useQueryParam("add_new_service").get();
  const [addServiceType, setAddServiceType] = useState<ProjectType | undefined>(
    () => {
      const parsedService = parseInt(addNewServiceQueryParam || "-1");
      if (
        userOnOwnProfile &&
        parsedService > ProjectType.NO_TYPE &&
        parsedService <= ProjectType.ATMOS_MIXING
      ) {
        return parsedService;
      }
    },
  );

  // Apply the discount code or promo code specified in the URL query params.
  // Discount code takes priority over promo code.
  useEffect(() => {
    if (!userData?.id) return;

    const discountCode = queryLocation.get("code");
    const promoCode = queryLocation.get("promocode");
    if (!discountCode && !promoCode) return;
    if (discountCode) {
      void dispatch(fetchUniqueBookingLink({ code: discountCode }));
    } else if (promoCode && !isAandR) {
      void dispatch(
        applyPromoCode({
          promocode: promoCode,
          user_id: userData.id,
        }),
      ).unwrap();
    }
  }, [dispatch, queryLocation, isAandR, userData?.id]);

  const renderServicesContent = () => {
    if (isEngineerServicesLoading && !editServiceType && !addServiceType) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <SoundWaveLoader width={100} height={100} />
        </Box>
      );
    }

    if (isEngineerServicesSuccess && engineerServicesData && engineerId) {
      const combinedServices = combineServicesFromResponse(
        engineerId,
        engineerServicesData,
        userMe,
      );

      return (
        <EngineerServiceCards
          combinedServices={combinedServices}
          userData={userData}
          editMode={isEditMode}
          engineer={userData.engineer!}
          userOnOwnProfile={userOnOwnProfile}
          editServiceType={editServiceType}
          setEditServiceType={setEditServiceType}
          addServiceType={addServiceType}
          setAddServiceType={setAddServiceType}
        />
      );
    }

    return null;
  };

  if (!userData) {
    return null;
  }

  const renderAboutMe = () => {
    if (isEditMode || (!userData?.profile?.long_bio && !userOnOwnProfile)) {
      return null;
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
        <AboutMe />
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "54px" }}>
      {renderAboutMe()}

      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}>
        <Text variant={TextStyleVariant.S2}>Services</Text>
        {renderServicesContent()}
      </Box>
      <FrequentlyAskedSectionWrapper />
    </Box>
  );
};
