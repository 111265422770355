import { useAtom } from "jotai";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { editMode } from "../../../atoms/profileScreenEdit";
import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useIsVisitingOwnProfile } from "../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import { useUpdateFeaturedTrack } from "../../../hooks/profileScreenHooks/useUpdateFeaturedTrack";
import {
  setLocalPlayer,
  FooterFileTrackType,
} from "../../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PortfolioFeatureData } from "../../../store/models/portfolio";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { FeaturedTrack } from "./FeaturedTrack";
import { convertPortfolioFeatureDataToPlayListTrack } from "../../../store/models/playListTrack";

interface FeaturedTrackWrapperProps {
  portfolioFeatureData: PortfolioFeatureData;
}

export const FeaturedTrackWrapper = ({
  portfolioFeatureData,
}: FeaturedTrackWrapperProps) => {
  const [isEditMode] = useAtom(editMode);
  const dispatch = useAppDispatch();
  const { trackedPlayerId, isFooterPlaying } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const isPlayingOnFooter = useMemo(() => {
    return Boolean(trackedPlayerId === portfolioFeatureData.id);
  }, [portfolioFeatureData.id, trackedPlayerId]);
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const { mutateAsync: updateFeaturedTrack, isPending: isUpdatingTrack } =
    useUpdateFeaturedTrack({
      userId: userData!.id,
    });

  const footerPlayerRef = useGetFooterPlayerRef();

  const toggleAudio = () => {
    emitAnalyticsTrackingEvent("portfolio_featured_muso_track", {
      portfolioFeatureDataId: portfolioFeatureData.id,
    });

    if (!isPlayingOnFooter) {
      dispatch(
        setLocalPlayer({
          url: portfolioFeatureData.spotify_preview_url!,
          trackedPlayerId: portfolioFeatureData.id,
          footerFileTrackType: FooterFileTrackType.SPOTIFY_SNIPPET,
          loadedTrack:
            convertPortfolioFeatureDataToPlayListTrack(portfolioFeatureData),
          playOnLoad: true,
        }),
      );
      return;
    }

    void footerPlayerRef.current?.playPause();
  };

  const deleteFeatureData = async () => {
    await updateFeaturedTrack({
      featured_track_id: portfolioFeatureData.id,
      deleted: true,
    });
  };

  return (
    <FeaturedTrack
      portfolioFeatureData={portfolioFeatureData}
      isEditMode={isEditMode}
      isFooterPlaying={isFooterPlaying}
      isUpdatingTrack={isUpdatingTrack}
      isPlayingOnFooter={isPlayingOnFooter}
      toggleAudio={
        portfolioFeatureData.spotify_preview_url ? toggleAudio : () => {}
      }
      deleteFeatureData={deleteFeatureData}
    />
  );
};
