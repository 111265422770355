import { ReactNode } from "react";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { Text } from "../../core-ui/components/Text/Text";
import {
  AlbumIconContainer,
  AlbumIconImg,
  AlbumIconText,
  ColumnContainer,
  FeaturedTrackRoleContainer,
  MusoCreditInfoColumnContainer,
  MusoCreditInfoRoleContainer,
} from "./AlbumIcon.styles";
import { formatDateToShortStringFormat } from "../../../store/utils/dateTimeUtils";

interface FeaturedTrackInfoProps {
  trackTitle: string;
  albumTitle: string;
  readableProjectType: string;
}

export const FeaturedTrackInfo = ({
  trackTitle,
  albumTitle,
  readableProjectType,
}: FeaturedTrackInfoProps) => {
  return (
    <ColumnContainer>
      <AlbumIconText bold variant={TextStyleVariant.P2}>
        {trackTitle}
      </AlbumIconText>
      <AlbumIconText variant={TextStyleVariant.P3}>{albumTitle}</AlbumIconText>
      <FeaturedTrackRoleContainer>
        {readableProjectType && (
          <AlbumIconText bold variant={TextStyleVariant.P4}>
            {readableProjectType}
          </AlbumIconText>
        )}
      </FeaturedTrackRoleContainer>
    </ColumnContainer>
  );
};

interface MusoCreditInfoProps {
  trackTitle: string;
  artistName: string;
  releaseDate: string;
  role: string;
}

export const MusoCreditInfo = ({
  trackTitle,
  artistName,
  releaseDate,
  role,
}: MusoCreditInfoProps) => {
  return (
    <MusoCreditInfoColumnContainer>
      <AlbumIconText bold variant={TextStyleVariant.P1}>
        {trackTitle}
      </AlbumIconText>
      <ColumnContainer>
        <AlbumIconText variant={TextStyleVariant.P1}>
          {artistName}
        </AlbumIconText>
        <Text variant={TextStyleVariant.P2} color={TextColor.SECONDARY}>
          {formatDateToShortStringFormat(releaseDate)}
        </Text>
        <MusoCreditInfoRoleContainer>
          <Text bold variant={TextStyleVariant.P4}>
            {role}
          </Text>
        </MusoCreditInfoRoleContainer>
      </ColumnContainer>
    </MusoCreditInfoColumnContainer>
  );
};

interface AlbumIconProps {
  children: ReactNode;
  imageSource: string;
  albumName: string;
  isPortfolio?: boolean;
  onClick?: () => void;
}

export const AlbumIcon = ({
  children,
  imageSource,
  albumName,
  isPortfolio = false,
  onClick,
}: AlbumIconProps) => {
  return (
    <AlbumIconContainer onClick={onClick} $isPortfolio={isPortfolio}>
      <AlbumIconImg src={imageSource} alt={`Album cover for ${albumName}`} />
      {children}
    </AlbumIconContainer>
  );
};
