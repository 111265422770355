import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes";
import {
  SelectedProfileState,
  setSelectedProfile,
} from "../../../store/actions/selectedProfile";
import { getMyStudios } from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIsCurrentUserEngineerSelector,
  selectIsUserStudioManager,
} from "../../../store/selectors/userInfoSelectors";
import logo from "../../assets/header-logo.svg";
import whiteLogo from "../../assets/header-white.svg";
import Link from "../../elements/Link/Link";
import { LoggedInHeaderContent } from "../LoggedInHeaderContent/LoggedInHeaderContent";
import { AnnouncementHeader } from "../Navigation/AnnouncementHeader/AnnouncementHeader";
import { NavProfileSidePanel } from "../Navigation/NavProfileSidePanel/NavProfileSidePanel";
import "./AppBarHeader.css";
import { AppBarHeaderContainer } from "./AppBarHeaderContainer";
import { AppBarHeaderContentContainer } from "./AppBarHeaderContentContainer";
import { Box } from "@mui/material";
import { activeProfileAtom } from "../../../atoms/user/activeProfileAtom";

export interface AppBarHeaderProps {
  isEmptyHeader?: boolean;
}

export const AppBarHeader = ({ isEmptyHeader }: AppBarHeaderProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isMobile } = useMediaQueryBreakpoint();
  const { user } = useAppSelector((state) => state.accountInfo);
  const darkMode = useAtomValue(darkModeAtom);
  const setProfileId = useSetAtom(activeProfileAtom);

  const userStudios = useAppSelector((state) => state.accountInfo.userStudios);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const userIsEngineer = useAppSelector(selectIsCurrentUserEngineerSelector);

  const isStudioEnabled = useAppSelector(selectIsUserStudioManager);
  useEffect(() => {
    if (!isStudioEnabled || isEmptyHeader) {
      return;
    }
    dispatch(getMyStudios()).catch(() => toast.error("Error loading studios"));
  }, [dispatch, isStudioEnabled, isEmptyHeader]);

  // sets default profile when user logs in
  useEffect(() => {
    if (!user || isEmptyHeader) return;
    const serializedSelectedProfile = localStorage.getItem("selectedProfile");
    if (serializedSelectedProfile) {
      const parsedSelectedProfile = JSON.parse(
        serializedSelectedProfile,
      ) as SelectedProfileState;
      dispatch(setSelectedProfile(parsedSelectedProfile));
    } else {
      dispatch(setSelectedProfile({ user, studio: undefined }));
    }
  }, [dispatch, user, isEmptyHeader, setProfileId]);

  const isSettingsScreen = useMemo(() => {
    return location.pathname.includes(SCREENS.SETTINGS_SCREEN);
  }, [location.pathname]);

  // if a user is not an engineer, but is a studio manager we set their studio as their default
  // but if they have more than one studio, we set their user profile as default
  useEffect(() => {
    if (isEmptyHeader) {
      return;
    }

    if (
      !(selectedProfile.studio || selectedProfile.user) &&
      !isSettingsScreen &&
      !userIsEngineer &&
      userStudios.length != 0
    ) {
      if (userStudios.length > 1) {
        setProfileId(undefined);
        dispatch(setSelectedProfile({ studio: undefined, user }));
      } else {
        const studio = userStudios[0];
        setProfileId(studio.id.toString());
        // Set studio profile as default if user is not an engineer
        dispatch(setSelectedProfile({ studio, user: undefined }));
      }
    }
  }, [
    userStudios,
    userIsEngineer,
    dispatch,
    selectedProfile,
    user,
    isSettingsScreen,
    isEmptyHeader,
    setProfileId,
  ]);

  return (
    <AppBarHeaderContainer>
      <>
        <AnnouncementHeader userId={user?.id} />
        <AppBarHeaderContentContainer>
          <Box className="app-bar-main-content">
            {isMobile && (
              <NavProfileSidePanel darkMode={darkMode} user={user} />
            )}
            <Link
              className="engineears-logo"
              to={SCREENS.SIGNED_OUT_HOME_SCREEN}
            >
              <img alt="engineears" src={darkMode ? whiteLogo : logo} />
            </Link>
            {!isMobile && !isEmptyHeader && (
              <AppBarContent pathname={location.pathname} />
            )}
          </Box>
        </AppBarHeaderContentContainer>
      </>
    </AppBarHeaderContainer>
  );
};

interface AppBarContentProps {
  pathname: string;
}

const AppBarContent = ({ pathname }: AppBarContentProps) => {
  return useMemo(() => {
    switch (pathname) {
      default:
        return <LoggedInHeaderContent />;
    }
  }, [pathname]);
};
