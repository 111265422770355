import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { getMusoCredit } from "../../store/actions/muso";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/selectors/userInfoSelectors";

export const useGetVerifiedCredits = (userId = 0, refresh = false) => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  return useQuery({
    staleTime: !refresh && user && user.id === userId ? Infinity : undefined,
    enabled: Boolean(userId),
    queryKey: [QUERY_KEYS.GET_USER_VERIFIED_MUSO_CREDITS, userId],
    queryFn: async () => {
      const response = await dispatch(
        getMusoCredit({
          user_id: userId,
        }),
      ).unwrap();
      return response.data;
    },
  });
};
