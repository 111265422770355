import {
  FrequentlyAskedSectionBox,
  FrequentlyAskedSectionTitleContainer,
} from "./FrequentlyAskedSection.styles";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { useAtomValue } from "jotai";
import { editMode } from "../../../../../atoms/profileScreenEdit";
import { FrequentlyAskedItemsWrapper } from "./FrequentlyAskedItemsWrapper";
import { useGetUserProfile } from "../../../../../hooks/profileScreenHooks/useGetUserProfile";
import { useParams } from "react-router-dom";
import { useGetFrequentlyAskedQuestions } from "../../../../../hooks/profileScreenHooks/useGetFrequentlyAskedQuestions";
import { Skeleton, Box } from "@mui/material";

export const FrequentlyAskedSectionWrapper = () => {
  const isEditMode = useAtomValue(editMode);

  const { username } = useParams<{ username: string }>();

  const { data: userData, isPending: isLoadingUserData } =
    useGetUserProfile(username);

  const { data: faqData, isLoading: isInitialLoadingFaqs } =
    useGetFrequentlyAskedQuestions(userData?.id);

  if (isInitialLoadingFaqs) {
    return (
      <FrequentlyAskedSectionBox>
        <FrequentlyAskedSectionTitleContainer>
          <Skeleton width="100px" height="1.75rem" animation="wave" />
        </FrequentlyAskedSectionTitleContainer>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Skeleton width="100%" height="40px" animation="wave" />
          <Skeleton width="100%" height="40px" animation="wave" />
        </Box>
      </FrequentlyAskedSectionBox>
    );
  }

  if ((isLoadingUserData || faqData.length === 0) && !isEditMode) {
    return null;
  }

  return (
    <FrequentlyAskedSectionBox>
      <FrequentlyAskedSectionTitleContainer>
        <Text variant={TextStyleVariant.S2}>FAQ</Text>
        {isEditMode && (
          <Text>
            Adding Frequently Asked Questions will help your customers book you
            more often.
          </Text>
        )}
      </FrequentlyAskedSectionTitleContainer>
      <FrequentlyAskedItemsWrapper items={faqData} editMode={isEditMode} />
    </FrequentlyAskedSectionBox>
  );
};
