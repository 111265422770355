import { SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createWorkingHours,
  getWorkingHours,
  workingHoursParam,
} from "../../../store/actions/workingHours";
import { useAppDispatch } from "../../../store/hooks";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../../store/models/alts";
import {
  convertBinaryArrayToBinaryString,
  convertTimeToHour,
  FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE,
  fillInAvailability,
  fillInUnavailability,
  INVALID_AM_PM,
  NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  WEEKDAYS,
  WorkingHours,
} from "../../../store/models/workingHours";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../core-ui/components/Button/FixedMinWidthButton";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { Text } from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { ServicesFormModalFooter } from "../../screens/ProfileScreen/components/ServicesFormModalFooter";
import {
  ServiceFormColumn,
  ServiceFormContainer,
  ServiceFormLabel,
} from "../ManageEngineeringService/ManageEngineeringService.styles";
import { WeekdayPicker } from "../WeekdayPicker/WeekdayPicker";
import { WorkingHourContainer } from "./SelectWorkingHourComponent.style";
import "./SelectWorkingHoursComponent.css";

export interface SelectWorkingHoursComponentProps {
  onSuccessfulSubmit?: () => void;
  setShowWorkingHoursSetup?: (show: boolean) => void;
  studioRoomId?: number;
  studioRoomName?: string;
  onCancel?: () => void;
  isRevalidatingData?: boolean;
}

export const SelectWorkingHoursComponent = ({
  onSuccessfulSubmit,
  setShowWorkingHoursSetup,
  studioRoomId,
  studioRoomName,
  onCancel,
  isRevalidatingData,
}: SelectWorkingHoursComponentProps) => {
  const [updatingAvailability, setUpdatingAvailability] = useState(false);
  const [nextDay, setNextDay] = useState(false);
  const dispatch = useAppDispatch();
  const [weekdays, setWeekdays] = React.useState<WEEKDAYS[]>([]);
  const [startTime, setStartTime] = React.useState<OptionType>(
    SelectHourInDayOptions[0],
  );
  const [endTime, setEndTime] = React.useState<OptionType>(
    SelectHourInDayOptions[0],
  );
  const [startAMPM, setStartAMPM] = React.useState<OptionType>(
    SelectAMPMOptions[0],
  );
  const [endAMPM, setEndAMPM] = React.useState<OptionType>(
    SelectAMPMOptions[0],
  );
  const [isLoading, setIsLoading] = useState(false);

  const parseWeekdays = (workingHours: WorkingHours[]) => {
    return workingHours
      .filter((weekday) => weekday.availability.includes("1"))
      .map((weekday) => weekday.day_of_week);
  };

  useEffect(() => {
    // On the backend, if studioRoomId is None, we fall back to the user_id,
    // if that is not present, we fallback to the requesting user
    setIsLoading(true);
    void dispatch(getWorkingHours({ studio_room_id: studioRoomId }))
      .unwrap()
      .then((result) => {
        const workingDays = parseWeekdays(result);
        setWeekdays(workingDays);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, studioRoomId]);

  useEffect(() => {
    if (startAMPM.value === 0 && endAMPM.value === 0) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 1) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 0) {
      setNextDay(true);
    } else {
      setNextDay(false);
    }
  }, [startAMPM, endAMPM, startTime, endTime]);

  const saveAvailability = useCallback(() => {
    const workingHours: workingHoursParam = {
      [WEEKDAYS.MONDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.TUESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.WEDNESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.THURSDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.FRIDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SATURDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SUNDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
    };

    for (const weekday of weekdays) {
      const startHour = convertTimeToHour(startTime.value, startAMPM.value);
      const endHour = convertTimeToHour(endTime.value, endAMPM.value);
      if (startHour === INVALID_AM_PM || endHour === INVALID_AM_PM) {
        return toast.error('Please select a valid start and end time."');
      }
      if (startHour < endHour) {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInUnavailability(startHour, endHour),
        );
      } else {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInAvailability(endHour, startHour),
        );
      }
    }
    setUpdatingAvailability(true);
    dispatch(
      createWorkingHours({
        working_hours: workingHours,
        studio_room_id: studioRoomId,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success("Your availability preferences have been saved!");
        setUpdatingAvailability(false);
        if (onSuccessfulSubmit) onSuccessfulSubmit();
      })
      .catch(() => {
        toast.error(
          "There was an error updating your availability. Please try again later.",
        );
        setUpdatingAvailability(false);
      });
  }, [
    dispatch,
    studioRoomId,
    weekdays,
    startTime.label,
    startAMPM.value,
    endTime.label,
    endAMPM.value,
    onSuccessfulSubmit,
  ]);

  if (isLoading)
    return <SoundWaveLoader whiteLoader={false} width={120} height={20} />;

  return (
    <ServiceFormContainer>
      <ServiceFormColumn>
        <ServiceFormLabel>Select your days of operation</ServiceFormLabel>
        <WeekdayPicker weekdaysInput={weekdays} onChange={setWeekdays} />
      </ServiceFormColumn>
      <ServiceFormColumn>
        <ServiceFormLabel>
          {studioRoomId
            ? `Select ${studioRoomName} Hours`
            : "Generally Available Recording Hours"}
        </ServiceFormLabel>
        <WorkingHourContainer>
          <SelectTimeInDayDropdownComponent
            onHourChange={(option) => setStartTime(option)}
            hour={startTime}
            onTimeChange={setStartAMPM}
            time={startAMPM}
          />
          <Text style={{ textAlign: "center" }}>to</Text>

          <SelectTimeInDayDropdownComponent
            onHourChange={(option) => setEndTime(option)}
            hour={endTime}
            onTimeChange={setEndAMPM}
            time={endAMPM}
          />
        </WorkingHourContainer>
      </ServiceFormColumn>
      {studioRoomId ? (
        <>
          {setShowWorkingHoursSetup && (
            <Button
              loading={updatingAvailability}
              className="mt-4 submit-available-hours-button"
              onClick={() => setShowWorkingHoursSetup(false)}
              variant={ButtonVariant.OUTLINED}
              fullWidth
            >
              Go to Previous Step
            </Button>
          )}
          <Button
            disabled={weekdays.length === 0}
            loading={updatingAvailability}
            className="mt-4 submit-available-hours-button"
            onClick={saveAvailability}
            variant={ButtonVariant.PRIMARY}
            fullWidth
          >
            Finish Setup
          </Button>
        </>
      ) : (
        <ServicesFormModalFooter>
          <FixedMinWidthButton
            variant={ButtonVariant.OUTLINED}
            onClick={onCancel}
            disabled={updatingAvailability || isRevalidatingData}
          >
            Cancel
          </FixedMinWidthButton>

          <FixedMinWidthButton
            disabled={weekdays.length === 0}
            loading={updatingAvailability || isRevalidatingData}
            onClick={saveAvailability}
            variant={ButtonVariant.PRIMARY}
          >
            Save
          </FixedMinWidthButton>
        </ServicesFormModalFooter>
      )}
    </ServiceFormContainer>
  );
};

interface SelectHourInDayDropdownComponentProps {
  onHourChange?: (option: OptionType) => void;
  hour: OptionType;
  onTimeChange?: (option: OptionType) => void;
  time: OptionType;
}

const SelectTimeInDayDropdownComponent = ({
  onHourChange,
  onTimeChange,
  hour,
  time,
}: SelectHourInDayDropdownComponentProps) => {
  return (
    <div className="select-time-drop-down-container">
      <OptionsDropdown
        style={{ flex: 1 }}
        options={SelectHourInDayOptions}
        value={hour.value}
        onChange={(e: SelectChangeEvent<number>) => {
          const {
            target: { value },
          } = e;
          const foundOption = SelectHourInDayOptions.find(
            (option) => option.value === value,
          );
          if (foundOption) {
            onHourChange?.(foundOption);
          }
        }}
      />

      <OptionsDropdown
        style={{ flex: 1 }}
        options={SelectAMPMOptions}
        value={time.value}
        onChange={(e: SelectChangeEvent<number>) => {
          const {
            target: { value },
          } = e;
          const foundOption = SelectAMPMOptions.find(
            (option) => option.value === value,
          );
          if (foundOption) {
            onTimeChange?.(foundOption);
          }
        }}
      />
    </div>
  );
};
