import { AccountSettings } from "./tabs/AccountSettings";
import { AccountType } from "./tabs/AccountType";
import { PaymentAndBilling } from "./tabs/PaymentAndBilling";

export enum AccountSettingsModalTabs {
  AccountDetails,
  AccountType,
  PaymentAndBilling,
}

export const accountSettingsTabs = [
  {
    value: AccountSettingsModalTabs.AccountDetails,
    label: "Account Details",
    content: (onClose: () => void) => <AccountSettings onClose={onClose} />,
  },
  {
    value: AccountSettingsModalTabs.AccountType,
    label: "Account Type",
    content: (onClose: () => void) => <AccountType onClose={onClose} />,
  },
  // TODO: Add Team Members tab when it's ready
  // {
  //   value: AccountSettingsModalTabs.TeamMembers,
  //   label: "Team Members",
  //   content: (onClose: () => void) => <TeamMembers onClose={onClose} />,
  // },
  // TODO: Add Notifications tab when it's ready
  // {
  //   value: AccountSettingsModalTabs.Notifications,
  //   label: "Notifications",
  //   content: (onClose: () => void) => <Notifications onClose={onClose} />,
  // },
  {
    value: AccountSettingsModalTabs.PaymentAndBilling,
    label: "Payment & Billing",
    content: (onClose: () => void) => <PaymentAndBilling onClose={onClose} />,
  },
];

export const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};
