import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { FooterFileTrackType } from "../actions/abPlayerStore";
import { PlaylistTrack, PlaylistTrackType } from "../models/playListTrack";

const selectABPlayerStore = (state: RootState) => state.abPlayerStore;

export const determineIfPortfolioFeatureData = (
  track?: PlaylistTrack,
): boolean => {
  return Boolean(track?.type === PlaylistTrackType.PortfolioFeatureData);
};

export const getCurrentTrackFromPlaylist = () =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    if (abPlayerStore.playlist.length === 0) {
      return undefined;
    }
    return abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
  });

export const getTrackInfo = (index: number) =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (
      !abPlayerStore.playlist ||
      index > abPlayerStore.playlist.length - 1 ||
      index < 0
    ) {
      return {
        artist: "",
        title: "",
        engineer: "",
        artworkSource: undefined,
        serviceType: undefined,
      };
    }
    const track = abPlayerStore.playlist[index];
    return {
      artist: track.artistDisplayName,
      artistUsername: track.artistUsername,
      title: track.title,
      engineer: track.engineerDisplayName,
      engineerUsername: track.engineerUsername,
      artworkSource: track.artworkSource,
      serviceType: track.serviceType,
      fileVersionId: track.fileVersionId,
    };
  });

export const determineIfTrackIsDownloaded = (index: number) =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist || abPlayerStore.playlist.length === 0) {
      return false;
    }
    if (index > abPlayerStore.playlist.length - 1 || index < 0) {
      return false;
    }
    const track = abPlayerStore.playlist[index];
    if (determineIfPortfolioFeatureData(track)) {
      // TODO: Implement this for portfolio feature data
      return true;
    }
    if (track.type === PlaylistTrackType.ArtistRelease) {
      if (!track.fileVersionId) {
        // prevents download if no file version present.
        return true;
      }
      // ArtistRelease tracks use fileVersionId id as key
      return Boolean(
        abPlayerStore.downloadedPlaylistTrack[track?.fileVersionId]
          ?.trackGeneratedMP3BlobUrl,
      );
    }
    return Boolean(
      abPlayerStore.downloadedPlaylistTrack[track.id]
        ?.trackGeneratedMP3BlobUrl ||
        abPlayerStore.downloadedPlaylistTrack[track.id]?.trackBlobUrl,
    );
  });

export const getCurrentTrackDownloadedProjectBlobMp3s = () =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return "";
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return "";
    }
    if (currentTrack.type === PlaylistTrackType.ArtistRelease) {
      return abPlayerStore.downloadedPlaylistTrack[
        currentTrack?.fileVersionId ?? 0
      ]?.trackGeneratedMP3BlobUrl;
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]
      ?.trackGeneratedMP3BlobUrl;
  });

export const getCurrentTrackDownloadedProjectBlob = () =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return "";
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return "";
    }
    if (currentTrack.type === PlaylistTrackType.ArtistRelease) {
      return abPlayerStore.downloadedPlaylistTrack[
        currentTrack?.fileVersionId ?? 0
      ]?.trackBlobUrl;
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]?.trackBlobUrl;
  });

export const canTrackBeDownloaded = () => {
  return createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist.length) return;
    const { footerFileTrackType } = abPlayerStore;
    switch (footerFileTrackType) {
      case FooterFileTrackType.SCHEDULED_PROJECT:
      case FooterFileTrackType.RELEASE:
        return true;
      default:
        return false;
    }
  });
};

export const getCurrentTrackBlobUrlIsLoading = () => {
  return createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return false;
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return false;
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]
      ?.isTrackBlobUrlLoading;
  });
};

export const getCurrentTrackBlobMp3UrlIsLoading = () => {
  return createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return false;
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return false;
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]
      ?.isTrackGeneratedMP3BlobUrlLoading;
  });
};
