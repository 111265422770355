import { useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { localServiceCoverPhotoAtom } from "../atoms/profileScreenEdit";
import {
  engineerServiceCrud,
  ServiceOperations,
} from "../store/actions/services";
import { useAppDispatch } from "../store/hooks";
import { ProjectType } from "../store/models/project";
import Service from "../store/models/service";
import User from "../store/models/user";
import { OptionType } from "../stories/elements/DropDownSelector/DropdownSelector";
import { emitAnalyticsTrackingEvent } from "../utils/analyticsUtils";
import { useGetServiceOriginalRate } from "./profileScreenHooks/useGetServiceOriginalRate";
import {
  useGetServicePhotoSignedUrl,
  useUploadServicePhoto,
} from "./profileScreenHooks/useUploadServicePhoto";

export const MINIMUM_DEPOSIT = 25;
export const MAXIMUM_DEPOSIT = 75;
export const DEFAULT_DEPOSIT = 100;

const useManageEngineeringService = (
  initialService: Service,
  engineerUser: User,
  selectedServiceType: ProjectType,
  onSuccessfulSubmit: () => void,
  onUpdatePrice?: (newPrice: number) => void,
) => {
  const [allowPartialPayments, setAllowPartialPayments] = useState<boolean>(
    initialService.minimum_deposit_percentage < 1,
  );
  const [addBulkDiscount, setAddBulkDiscount] = useState<boolean>(
    Boolean(
      initialService.add_master_discount_percentage ||
        initialService.add_atmos_mix_discount_percentage,
    ),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingService, setPendingService] = useState(initialService);
  const [coverPhotoToBeCreated] = useAtom(localServiceCoverPhotoAtom);
  const { isLabelPriceApplied } = useGetServiceOriginalRate(pendingService);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!allowPartialPayments) {
      handleDepositPercentage(1);
    }
  }, [allowPartialPayments]);

  const { mutateAsync: getSignedUrl, isPending: isGetSignedUrlLoading } =
    useGetServicePhotoSignedUrl();
  const { mutateAsync: uploadPhoto, isPending: isUploadPhotoLoading } =
    useUploadServicePhoto();

  const updateMP3DownloadPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_artist_review_mp3_file_download: value,
    });
  };

  const updateWAVDownloadPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_artist_review_wav_file_download: value,
    });
  };

  const updateCostPerExtraAlt = useCallback(
    (value: number) => {
      setPendingService({
        ...pendingService,
        extra_alt_price: value,
      });
    },
    [pendingService],
  );

  const updateExtraRevisionPrice = useCallback((value: number) => {
    setPendingService((prev) => ({
      ...prev,
      extra_revision_price: value,
    }));
  }, []);

  const manageEngineeringService = async (operation: ServiceOperations) => {
    if (
      allowPartialPayments &&
      pendingService.minimum_deposit_percentage < 0.25
    ) {
      toast.error("Minimum deposit percentage must be at least 25%");
      return;
    }
    if (
      allowPartialPayments &&
      !pendingService.partial_payment_paywall_option
    ) {
      toast.error("Partial payment paywall option must be set");
      return;
    }

    emitAnalyticsTrackingEvent(
      operation === ServiceOperations.UPDATE
        ? "update_engineering_service"
        : operation === ServiceOperations.CREATE
          ? "add_engineering_service"
          : "delete_engineering_service",
      {
        service_type: `${selectedServiceType}`,
      },
      engineerUser?.id,
    );

    setLoading(true);

    try {
      const serviceResponse = await dispatch(
        engineerServiceCrud({
          ...pendingService,
          service_rate: {
            ...pendingService.service_rate,
            user_id: engineerUser.id,
          },
          operation: operation,
        }),
      ).unwrap();
      setLoading(false);
      if (coverPhotoToBeCreated) {
        const { signed_url } = await getSignedUrl({
          service_id: serviceResponse.id,
          service_type: serviceResponse.service_type,
          content_type: coverPhotoToBeCreated.type,
          file_size: coverPhotoToBeCreated.size,
        });

        await uploadPhoto({ url: signed_url, file: coverPhotoToBeCreated });
      }

      onSuccessfulSubmit?.();
    } catch (error) {
      setLoading(false);
      toast.error(
        operation === ServiceOperations.DELETE
          ? "Error deleting service, please reach out to customer support"
          : "Error creating service, please reach out to customer support",
      );
    }
  };

  const updateNumberOfFreeRevisions = useCallback(
    (updatedNumber: OptionType) => {
      setPendingService((prevState) => ({
        ...prevState,
        free_revisions: updatedNumber.value,
      }));
    },
    [],
  );

  const changeServiceRatePrice = useCallback((updatedPrice: number) => {
    setPendingService((prevState: Service) => {
      return {
        ...prevState,
        service_rate: {
          ...prevState.service_rate,
          price: updatedPrice,
        },
      } as Service;
    });
    if (!isLabelPriceApplied) {
      onUpdatePrice?.(updatedPrice);
    }
  }, []);

  const changeServiceRateForLabelPrice = useCallback((updatedPrice: number) => {
    setPendingService((prevState: Service) => {
      return {
        ...prevState,
        service_rate: {
          ...prevState.service_rate,
          label_price: updatedPrice,
        },
      } as Service;
    });
    if (isLabelPriceApplied) {
      onUpdatePrice?.(updatedPrice);
    }
  }, []);

  const handleDepositPercentage = useCallback((depositPercentage: number) => {
    setPendingService((prevState: Service) => ({
      ...prevState,
      minimum_deposit_percentage: depositPercentage,
    }));
  }, []);

  const handlePartialPaymentPaywallOption = useCallback(
    (option: OptionType) => {
      setPendingService((prevState: Service) => {
        return {
          ...prevState,
          partial_payment_paywall_option: option.value,
        } as Service;
      });
    },
    [],
  );

  return {
    loading: loading || isGetSignedUrlLoading || isUploadPhotoLoading,
    allowPartialPayments,
    setAllowPartialPayments,
    pendingService,
    setPendingService,
    manageEngineeringService,
    handleDepositPercentage,
    updateCostPerExtraAlt,
    updateExtraRevisionPrice,
    changeServiceRateForLabelPrice,
    updateNumberOfFreeRevisions,
    changeServiceRatePrice,
    updateWAVDownloadPreference,
    updateMP3DownloadPreference,
    handlePartialPaymentPaywallOption,
    addBulkDiscount,
    setAddBulkDiscount,
  };
};

export default useManageEngineeringService;
