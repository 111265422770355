import { Box, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  loadSchedulingSurveyAnswers,
  updateSchedulingSurveyAnswers,
} from "../../../store/actions/services";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { EngineerSchedulingSurvey } from "../../../store/models/service";
import { getNumberedOptions } from "../../../store/utils/serviceUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../core-ui/components/Button/FixedMinWidthButton";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { PercentageTextfield } from "../../elements/PercentageTextField/PercentageTextfield";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { ServicesFormModalFooter } from "../../screens/ProfileScreen/components/ServicesFormModalFooter";
import "../ManageEngineeringService/ManageEngineeringService.css";
import {
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
} from "../ManageEngineeringService/ManageEngineeringService.styles";
import { WeekdayPicker } from "../WeekdayPicker/WeekdayPicker";
import "./SurveyForm.css";
export interface SurveyFormProps {
  serviceType: ProjectType;
  onFinishUpdate?: () => void;
  onCancel?: () => void;
  isRevalidatingData?: boolean;
}

export const SurveyForm = (props: SurveyFormProps) => {
  const dispatch = useAppDispatch();
  const serviceType = props.serviceType;
  const [surveyAnswers, setSurveyAnswers] = useState<EngineerSchedulingSurvey>({
    service_type: props.serviceType,
  });
  const [postingData, setPostingData] = useState<boolean>();
  const { surveyData, loadingAnswers } = useAppSelector(
    (state) => state.engineerServices,
  );
  const [enableRushFees, setEnableRushFees] = useState(false);
  const user = useAppSelector((state) => state.accountInfo.user);

  useEffect(() => {
    void dispatch(
      loadSchedulingSurveyAnswers({
        service_type: props.serviceType,
      }),
    )
      .unwrap()
      .then((survey) => {
        if (
          survey.per_day_rush_fee_percentage &&
          parseFloat(`${survey.per_day_rush_fee_percentage}`)
        ) {
          setEnableRushFees(true);
        }
      });
  }, [props.serviceType, dispatch]);

  useMemo(() => {
    if (surveyData !== undefined) setSurveyAnswers(surveyData);
  }, [surveyData]);

  const handleSubmit = useCallback(async () => {
    setPostingData(true);
    surveyAnswers.service_type = serviceType;
    emitAnalyticsTrackingEvent(
      "update_survey_answers",
      {
        service_type: `${serviceType}`,
      },
      user?.id,
    );

    await dispatch(
      updateSchedulingSurveyAnswers({
        ...surveyAnswers,
        per_day_rush_fee_percentage: enableRushFees
          ? surveyAnswers.per_day_rush_fee_percentage
          : 0,
      }),
    );
    setPostingData(false);
    if (props.onFinishUpdate) props.onFinishUpdate();
  }, [surveyAnswers, serviceType, user?.id, dispatch, enableRushFees, props]);

  function getNumberArrayFromWeekdayString(weekdays: string) {
    if (!weekdays) return [];

    const weekdaysInput = [];
    for (let i = 0; i < weekdays.length; i++) {
      weekdaysInput.push(parseInt(weekdays.charAt(i)));
    }
    return weekdaysInput;
  }

  const handleWeekdayPickerOnChange = useCallback((values: number[]) => {
    setSurveyAnswers((currentSurveyAnswers) => {
      return { ...currentSurveyAnswers, workdays: values.join("") };
    });
  }, []);

  const handleNumberOfDayNoticeOnChange = useCallback(
    (e: SelectChangeEvent<number>) => {
      const {
        target: { value },
      } = e;
      // On autofill we get a stringified value.
      // That's actually not what we want
      if (typeof value !== "string") {
        setSurveyAnswers((currentSurveyAnswers) => {
          return {
            ...currentSurveyAnswers,
            number_of_days_notice: value,
          };
        });
      }
    },
    [],
  );

  const handleUpdateSongsPerDay = useCallback(
    (e: SelectChangeEvent<number>) => {
      const {
        target: { value },
      } = e;
      // On autofill we get a stringified value.
      // That's actually not what we want
      if (typeof value !== "string") {
        setSurveyAnswers((currentSurveyAnswers) => {
          return {
            ...currentSurveyAnswers,
            songs_per_day: value,
          };
        });
      }
    },
    [],
  );

  const handleSetPerDayRushFee = useCallback((value: number) => {
    setSurveyAnswers((currentSurveyAnswers) => {
      return {
        ...currentSurveyAnswers,
        per_day_rush_fee_percentage: value,
      };
    });
  }, []);

  const submitDisabled = useMemo(() => {
    if (!surveyAnswers.songs_per_day) return true;
    if (!surveyAnswers.workdays) return true;
    if (!surveyAnswers.number_of_days_notice) return true;
    if (!enableRushFees) return false;
    if (!surveyAnswers.per_day_rush_fee_percentage) return true;
    return false;
  }, [
    enableRushFees,
    surveyAnswers.number_of_days_notice,
    surveyAnswers.per_day_rush_fee_percentage,
    surveyAnswers.songs_per_day,
    surveyAnswers.workdays,
  ]);

  const mixOrMaster = serviceType === ProjectType.MASTERING ? "master" : "mix";

  if (loadingAnswers) return <SoundWaveLoader width={100} height={100} />;

  return (
    <div className="survey-form-container">
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        <ServiceFormLabel>
          Select the days you are available to take projects:
        </ServiceFormLabel>
        <WeekdayPicker
          weekdaysInput={getNumberArrayFromWeekdayString(
            surveyAnswers.workdays!,
          )}
          onChange={handleWeekdayPickerOnChange}
        />
      </Box>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            How many songs do you prefer to {mixOrMaster} on a single day?
          </ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <OptionsDropdown
            value={surveyAnswers.songs_per_day}
            options={getNumberedOptions(1, 11)}
            placeholder="Select one"
            onChange={handleUpdateSongsPerDay}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>

      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            What is your standard turnaround time for {mixOrMaster}? Number of
            days
          </ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <OptionsDropdown
            value={surveyAnswers.number_of_days_notice}
            options={getNumberedOptions(2, 15)}
            placeholder="Select one"
            onChange={handleNumberOfDayNoticeOnChange}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>

      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <Box>
            <ServiceFormLabel>Turn on automatic rush fees?</ServiceFormLabel>
          </Box>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <ToggleSwitch
            checked={enableRushFees}
            onChange={(checkedState) => {
              setEnableRushFees(checkedState);
              if (!checkedState) {
                handleSetPerDayRushFee(0);
              } else {
                handleSetPerDayRushFee(0.25);
              }
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>

      {enableRushFees && (
        <div className="survey-form-container" style={{ marginLeft: "32px" }}>
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <ServiceFormLabel>
                If a client is looking for a rushed turnaround time, how much
                more should it cost to do so, as a percentage of typical package
                price? ie what is the per-day rush fee?
              </ServiceFormLabel>
              <ServiceFormLabelSubtext>
                (50% means that the {mixOrMaster} will cost 1.5x the normal
                price for one day of rush)
              </ServiceFormLabelSubtext>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <PercentageTextfield
                max={200}
                value={surveyAnswers.per_day_rush_fee_percentage}
                onChangePercentage={handleSetPerDayRushFee}
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
        </div>
      )}

      <ServicesFormModalFooter>
        <FixedMinWidthButton
          variant={ButtonVariant.OUTLINED}
          onClick={props.onCancel}
          disabled={postingData || props.isRevalidatingData}
        >
          Cancel
        </FixedMinWidthButton>
        <FixedMinWidthButton
          onClick={handleSubmit}
          disabled={submitDisabled}
          loading={postingData || props.isRevalidatingData}
        >
          Save
        </FixedMinWidthButton>
      </ServicesFormModalFooter>
    </div>
  );
};
