import { PopoverTrigger } from "@radix-ui/react-popover";
import useModal from "../../../hooks/useModal";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";

import { ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  LABEL_TEAM_ROLES,
  STUDIO_TEAM_ROLES,
  Team,
  TeamRole,
} from "../../../store/models/admins";
import { emailRegex } from "../../../store/utils/signUpUtils";
import { Textfield } from "../../elements/Textfield1/Textfield1";
import { DropdownSelector } from "../../elements/DropDownSelector/DropdownSelector";
import useCreateInviteMutation from "../../../hooks/teamHooks/useCreateInviteMutation";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import {
  TeamInviteButton,
  TeamInviteFieldHeader,
  TeamPanelInviteBody,
  TeamPanelInviteItem,
} from "./InviteTeammate.styles";
import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";

const hasRequiredInviteFields = (
  name: string,
  email: string,
  role: TeamRole,
) => {
  if (!name.length || !emailRegex.test(email) || role === TeamRole.NO_ROLE) {
    return false;
  }
  return true;
};

interface InviteTeammateButtonProps {
  team?: Team;
  isLabel?: boolean;
  inPanel?: boolean;
}

const InviteTeammateButton = ({
  team,
  isLabel,
  inPanel = true,
}: InviteTeammateButtonProps) => {
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    toggleModal: togglePopover,
    closeModal: closePopover,
  } = useModal();
  const { mutateAsync, isPending } = useCreateInviteMutation();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState(TeamRole.NO_ROLE);
  const clearFormData = useCallback(() => {
    setName("");
    setEmail("");
    setRole(TeamRole.NO_ROLE);
    setPhone("");
  }, [setName, setRole, setPhone, setEmail]);

  const closeForm = useCallback(() => {
    clearFormData();
    closePopover();
  }, [clearFormData, closePopover]);
  // When component is unmounted clear the saved values for the form
  useEffect(() => {
    return () => {
      clearFormData();
    };
  }, [clearFormData]);
  return (
    <BasePopover
      title={`Invite a Teammate`}
      description={
        <TeamPanelInviteBody className="team-panel-invite-body">
          <TeamPanelInviteItem className="team-panel-invite-item">
            <TeamInviteFieldHeader className="b3-semi-bold invite-teammate-field-header">
              Name
            </TeamInviteFieldHeader>
            <Textfield
              value={name}
              onChange={(e) => setName(e.target.value)}
              label={"Enter full name"}
            />
          </TeamPanelInviteItem>
          <TeamPanelInviteItem className="team-panel-invite-item">
            <TeamInviteFieldHeader className="b3-semi-bold invite-teammate-field-header">
              Email
            </TeamInviteFieldHeader>
            <Textfield
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              label={"Enter email"}
            />
          </TeamPanelInviteItem>
          <TeamPanelInviteItem className="team-panel-invite-item">
            <TeamInviteFieldHeader className="b3-semi-bold invite-teammate-field-header">
              Role
            </TeamInviteFieldHeader>
            <DropdownSelector
              onChange={(option) => setRole(option.value as TeamRole)}
              placeholder={"Select Role"}
              options={isLabel ? LABEL_TEAM_ROLES : STUDIO_TEAM_ROLES}
            />
          </TeamPanelInviteItem>
          <TeamPanelInviteItem className="team-panel-invite-item">
            <TeamInviteFieldHeader className="b3-semi-bold invite-teammate-field-header">
              Phone number (optional)
            </TeamInviteFieldHeader>
            <PhoneInput
              style={{
                height: "45px",
                width: "100%",
              }}
              sx={{
                "& .MuiOutlinedInput-root": { height: "45px", width: "100%" },
              }}
              isValidPhoneNumber={isValidPhoneNumber}
              defaultCountry={"US"}
              value={phone}
              onChange={(value: string) => {
                setPhone(value);
                setIsValidPhoneNumber(matchIsValidTel(value));
              }}
            />
          </TeamPanelInviteItem>
        </TeamPanelInviteBody>
      }
      onConfirm={async () => {
        await mutateAsync({
          team_id: team!.id,
          role: role,
          emails: email,
          phone_number: phone,
          full_name: name,
        }).then(() => {
          toast.success(`${name} has been sent an invite to the team.`);
          closeForm();
        });
      }}
      disableAutoClose={true}
      okText={isPending ? `Sending` : "Send Invite"}
      onCancel={closeForm}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      setIsPopoverOpen={setIsPopoverOpen}
      cancelButtonProps={{ fullWidth: true, disabled: isPending }}
      okButtonProps={{
        fullWidth: true,
        disabled:
          isPending || !team || !hasRequiredInviteFields(name, email, role),
      }}
    >
      <PopoverTrigger asChild onClick={togglePopover}>
        <TeamInviteButton
          variant={ButtonVariant.GHOST}
          {...(!inPanel && {
            style: {
              marginBottom: 0,
              fontSize: "12px",
              lineHeight: "16px",
            },
          })}
        >
          Invite Teammate
        </TeamInviteButton>
      </PopoverTrigger>
    </BasePopover>
  );
};

export default InviteTeammateButton;
