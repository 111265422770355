import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { ErrorsAction } from "../../store/actions/errorStore";
import {
  engineerServiceCrud,
  ServiceOperations,
} from "../../store/actions/services";
import { useAppDispatch } from "../../store/hooks";
import Service, { APIService } from "../../store/models/service";
import { callAllHandlersWithData } from "../../utils/utils";

interface mutateFunctionProps {
  updatedService: Service;
  operation: ServiceOperations;
}

interface useEngineerServiceCrudProps
  extends Omit<
    UseMutationOptions<APIService, ErrorsAction, mutateFunctionProps>,
    "onSuccess" | "mutationFn"
  > {
  additionalOnSuccess?: (data: APIService) => void;
}

export const useEngineerServiceCrud = ({
  additionalOnSuccess,
  ...extraProps
}: useEngineerServiceCrudProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const defaultOnSuccess = (data: APIService) => {
    void queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, data.engineer_id],
    });
  };

  return useMutation({
    mutationFn: async ({ updatedService, operation }: mutateFunctionProps) => {
      return dispatch(
        engineerServiceCrud({
          ...updatedService,
          operation: operation,
        }),
      ).unwrap();
    },
    onSuccess: callAllHandlersWithData(defaultOnSuccess, additionalOnSuccess),
    ...extraProps,
  });
};
