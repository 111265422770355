import { styled, Box } from "@mui/material";

export const FrequentlyAskedSectionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const FrequentlyAskedSectionTitleContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
);
