import { atomWithStorage } from "jotai/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { atom } from "jotai";
import { activeUserAtom } from "./activeUserAtom";

enum PALETTE_MODE {
  LIGHT = "light",
  DARK = "dark",
}

type USER_PALETTES = Record<string, PALETTE_MODE>;

export const userPaletteModeAtom = atomWithStorage(
  LOCAL_STORAGE_KEYS.PALETTE_BY_USER,
  {} as USER_PALETTES,
);

export const darkModeAtom = atom(
  (get) => {
    // Blank string acts as default user dark mode
    const userId = get(activeUserAtom)?.id ?? "";
    return (
      (get(userPaletteModeAtom)[userId] || PALETTE_MODE.LIGHT) ===
      PALETTE_MODE.DARK
    );
  },
  (get, set, darkMode: boolean) => {
    const userId = get(activeUserAtom)?.id ?? "";
    set(userPaletteModeAtom, (oldState) => {
      const newState = { ...oldState };
      newState[userId] = darkMode ? PALETTE_MODE.DARK : PALETTE_MODE.LIGHT;
      return newState;
    });
  },
);
