import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { QUERY_KEYS } from "../../../../constants/queryKeys";
import { useGetServiceOriginalRate } from "../../../../hooks/profileScreenHooks/useGetServiceOriginalRate";
import { useEngineerCanHostServices } from "../../../../hooks/useEngineerCanHostServices";
import { SCREENS } from "../../../../routes";
import { useAppSelector } from "../../../../store/hooks";
import Engineer from "../../../../store/models/engineer";
import { ProjectType } from "../../../../store/models/project";
import { RecordingService } from "../../../../store/models/recording";
import Service from "../../../../store/models/service";
import User from "../../../../store/models/user";
import {
  emitAnalyticsTrackingEvent,
  getDebugEventUserIdPrefix,
} from "../../../../utils/analyticsUtils";
import { AddRecordingService } from "../../../components/AddRecordingService/AddRecordingService";
import { EditServiceCard } from "../../../components/EditServiceCard/EditServiceCard";
import { EditServiceSurvey } from "../../../components/EditServiceSurvey/EditServiceSurvey";
import { ManageEngineeringService } from "../../../components/ManageEngineeringService/ManageEngineeringService";
import { SelectWorkingHoursComponent } from "../../../components/SelectWorkingHoursComponent/SelectWorkingHoursComponent";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Text } from "../../../core-ui/components/Text/Text";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { EETab, EETabs, TabsBottomGreyBar } from "../ProfileScreen.styles";
import { a11yProps } from "../utils";
import { ServiceDescriptionTab } from "./ServiceDescriptionTab";
import { CenteredModalBody } from "./ServicesTab.styles";

export enum FormType {
  EDIT = "edit",
  CREATE = "create",
}

interface ServicesFormModalContentProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  engineer: Engineer;
  onChangeService: (selectedProjectType: OptionType) => void;
  selectedServiceType: ProjectType;
  existingServices: (Service | RecordingService)[];
  formType: FormType;
  userData: User;
  onCancel: () => void;
}

export const ServicesFormModalContent = ({
  setOpen,
  engineer,
  onChangeService,
  selectedServiceType,
  existingServices,
  formType,
  userData,
  onCancel,
}: ServicesFormModalContentProps) => {
  const [selectedTab, setSelectedTab] = useState(ModalTab.ServiceDetails);
  const queryClient = useQueryClient();
  const history = useHistory();

  const { meetsStripeRequirements } = useAppSelector(
    (state) => state.accountInfo,
  );
  const canHostServices = useEngineerCanHostServices(engineer);

  const handleSuccessfulDetailsUpdate = async () => {
    toast.success("The service details has been successfully updated!");
    try {
      if (formType === FormType.EDIT) {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineer.id],
        });
      }

      setSelectedTab(ModalTab.SchedulingPreferences);
    } catch (error) {
      // Error handler is already managed by Redux action
    }
  };

  const handleSuccessfulWorkingHourUpdate = async () => {
    try {
      if (formType === FormType.CREATE) {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineer.id],
        });
      }

      setSelectedTab(ModalTab.ServiceDescription);
    } catch (error) {
      // Error handler is already managed by Redux action
    }
  };

  const handleSuccessfulDescriptionUpdate = () => {
    toast.success("The service description has been successfully updated!");
    setOpen(false);
  };

  const getEngineerServicesState = queryClient.getQueryState([
    QUERY_KEYS.GET_ENGINEER_SERVICES,
    engineer.id,
  ]);
  const isValidatingServices =
    getEngineerServicesState?.fetchStatus === "fetching";

  const initialFormData = useMemo(() => {
    return existingServices.find(
      (service) => service.service_type === selectedServiceType,
    );
  }, [existingServices, selectedServiceType]);

  const { originalRate } = useGetServiceOriginalRate(initialFormData);

  const [previewPrice, setPreviewPrice] = useState(originalRate);

  const tabs = [
    {
      value: ModalTab.ServiceDetails,
      label: "Service details",
      content: (
        <EditServiceCard
          serviceType={selectedServiceType}
          service={initialFormData}
          engineerId={engineer.id}
          previewPrice={previewPrice}
          isUpdating={formType === FormType.EDIT}
          onChangeService={onChangeService}
        >
          {selectedServiceType === ProjectType.RECORDING ? (
            <AddRecordingService
              onSuccessfulSubmit={handleSuccessfulDetailsUpdate}
              detailsUpdateOnly={true}
              onUpdatePrice={setPreviewPrice}
              onCancel={onCancel}
              isRevalidatingData={isValidatingServices}
            />
          ) : (
            <ManageEngineeringService
              key={selectedServiceType}
              onSuccessfulSubmit={handleSuccessfulDetailsUpdate}
              engineerUser={userData}
              service={initialFormData as Service}
              engineer={engineer}
              selectedServiceType={selectedServiceType}
              isUpdating={formType === FormType.EDIT}
              onUpdatePrice={setPreviewPrice}
              onCancel={onCancel}
              isRevalidatingData={isValidatingServices}
            />
          )}
        </EditServiceCard>
      ),
      disabled:
        formType === FormType.CREATE && selectedTab !== ModalTab.ServiceDetails,
    },
    {
      value: ModalTab.SchedulingPreferences,
      label: "Scheduling preferences",
      content:
        selectedServiceType === ProjectType.RECORDING ? (
          <SelectWorkingHoursComponent
            onSuccessfulSubmit={handleSuccessfulWorkingHourUpdate}
            setShowWorkingHoursSetup={() => {
              setSelectedTab(ModalTab.ServiceDetails);
            }}
            onCancel={onCancel}
            isRevalidatingData={isValidatingServices}
          />
        ) : (
          <EditServiceSurvey
            serviceType={selectedServiceType}
            onFinishUpdate={handleSuccessfulWorkingHourUpdate}
            onCancel={onCancel}
            isRevalidatingData={isValidatingServices}
          />
        ),
      disabled:
        formType === FormType.CREATE &&
        selectedTab !== ModalTab.SchedulingPreferences,
    },
    {
      value: ModalTab.ServiceDescription,
      label: "Service description",
      content: (
        <ServiceDescriptionTab
          service={initialFormData}
          userData={userData}
          onSuccessfulServiceDescriptionUpdate={
            handleSuccessfulDescriptionUpdate
          }
          engineer={engineer}
          onCancel={onCancel}
        />
      ),
      disabled:
        formType === FormType.CREATE &&
        selectedTab !== ModalTab.ServiceDescription,
    },
  ];

  if (!canHostServices) {
    return (
      <CenteredModalBody>
        <Text>
          Offering services is currently not enabled for your profile. You are
          on our waitlist! Be on the lookout for an update soon.
        </Text>
      </CenteredModalBody>
    );
  }

  if (!meetsStripeRequirements && existingServices.length === 0) {
    return (
      <CenteredModalBody>
        <Text bold>
          EngineEars is partnered with Stripe to enable instant payouts when
          projects or sessions are completed
        </Text>
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => {
            emitAnalyticsTrackingEvent("click_to_setup_stripe_from_profile", {
              user_id: `${getDebugEventUserIdPrefix}${userData.id}`,
            });
            history.push(SCREENS.SETUP_STRIPE);
          }}
        >
          Set Up Stripe Connect
        </Button>
      </CenteredModalBody>
    );
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          position: "relative",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        })}
      >
        <EETabs
          value={selectedTab}
          onChange={(_, val: ModalTab) => {
            setSelectedTab(val);
          }}
          aria-label="Services Form Modal Tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs.map((tab, index) => (
            <EETab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              disabled={Boolean(tab.disabled)}
              {...a11yProps(index)}
            />
          ))}
        </EETabs>
        <TabsBottomGreyBar />
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          padding: "0 24px 24px 24px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {tabs.map((tab) => (
          <TabPanel value={tab.value} index={selectedTab} key={tab.value}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

enum ModalTab {
  ServiceDetails = "service-details",
  SchedulingPreferences = "scheduling-preferences",
  ServiceDescription = "service-description",
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: ModalTab;
  value: ModalTab;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ alignSelf: "stretch" }}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};
