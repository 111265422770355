import { Box } from "@mui/material";
import { useMemo } from "react";
import { useGetFileVersions } from "../../../hooks/audioPlayerHooks/useGetFileVersions";
import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import {
  FooterFileTrackType,
  setLocalPlayer,
} from "../../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Alt } from "../../../store/models/alts";
import {
  AUDIO_UPLOAD_TYPE,
  FILE_STATUS,
  LINK_UPLOAD_TYPE,
  MP4_UPLOAD_TYPE,
  ZIP_UPLOAD_TYPE,
} from "../../../store/models/fileVersion";
import { PortfolioFeatureData } from "../../../store/models/portfolio";
import {
  Project,
  projectTypeReadableFileName,
} from "../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  PlaybackControlButton,
  PlaybackControlButtonVariant,
} from "../../elements/PlaybackControlButton/PlaybackControlButton";
import { PlaybackControlButtonSize } from "../../elements/PlaybackControlButton/PlaybackControlButton.styles";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { FeaturedABTrack } from "./FeaturedABTrack";
import { FeaturedABTrackContainer } from "./FeaturedABTrack.styles";
import { convertPortfolioFeatureDataToPlayListTrack } from "../../../store/models/playListTrack";
import { useDownloadTrackFromFileVersion } from "../../../hooks/audioPlayerHooks/fetchTrack";

interface FeaturedABTrackProps {
  portfolioFeatureData: PortfolioFeatureData;
  project: Project;
}

export const FeaturedABTrackWrapper = ({
  portfolioFeatureData,
  project,
}: FeaturedABTrackProps) => {
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetFileVersions({
    projectId: project.id,
    status: FILE_STATUS.FILE_UPLOADED,
  });
  const {
    projectId: trackedProjectId,
    isFooterPlaying,
    trackedPlayerId,
  } = useAppSelector((state) => state.abPlayerStore);
  const readableProjectType = projectTypeReadableFileName.get(
    project.service_type,
  );

  const isSameProject = useMemo(() => {
    return Boolean(trackedProjectId === project.id);
  }, [project.id, trackedProjectId]);

  const latestReference = useMemo(() => {
    if (!data) return undefined;
    const references = data.filter((f) => f.reference);
    if (references.length === 1) {
      return references[0];
    }
    let latestVersion = 0;
    references.forEach((ref) => {
      if (ref.version > latestVersion) {
        latestVersion = ref.version;
      }
    });
    return references.find((ref) => ref.version === latestVersion);
  }, [data]);

  const latestMain = useMemo(() => {
    if (!data) return undefined;
    const mains = data.filter((f) => f.alt === Alt.MAIN);
    if (mains.length === 1) {
      return mains[0];
    }
    const mixOrMaster = mains
      .filter((f) => !f.reference)
      .filter(
        (f) =>
          f.upload_type !== ZIP_UPLOAD_TYPE &&
          f.upload_type !== LINK_UPLOAD_TYPE &&
          f.upload_type !== MP4_UPLOAD_TYPE &&
          f.upload_type !== AUDIO_UPLOAD_TYPE,
      );
    if (mixOrMaster.length === 1) {
      return mixOrMaster[0];
    }

    let largestVersion = 0;
    mixOrMaster.forEach((ref) => {
      if (ref.version > largestVersion) {
        largestVersion = ref.version;
      }
    });
    return mixOrMaster.find((file) => file.version === largestVersion);
  }, [data]);

  const footerPlayerRef = useGetFooterPlayerRef();

  const { url: refUrl } = useDownloadTrackFromFileVersion(latestReference);
  const { url: mainUrl } = useDownloadTrackFromFileVersion(latestMain);

  const onClick = (loadedUrl: string, playerId: number) => {
    emitAnalyticsTrackingEvent("portfolio_featured_ab_track", {
      fileVersionId: playerId,
      portfolioFeatureDataId: portfolioFeatureData.id,
    });

    if (trackedPlayerId !== playerId) {
      dispatch(
        setLocalPlayer({
          url: loadedUrl,
          trackedPlayerId: playerId,
          footerFileTrackType: FooterFileTrackType.AB_SNIPPET,
          loadedTrack:
            convertPortfolioFeatureDataToPlayListTrack(portfolioFeatureData),
          playOnLoad: true,
          keepPosition: isSameProject,
        }),
      );
    }
  };

  const playbackButtonOnClick = async () => {
    if (isSameProject) {
      void footerPlayerRef.current?.playPause();
    } else if (mainUrl) {
      onClick(mainUrl, latestMain?.id || -1);
    } else if (refUrl) {
      onClick(refUrl, latestReference?.id || -1);
    }
  };

  if (isLoading) {
    return <SoundWaveLoader width={100} height={100} />;
  }

  return (
    <FeaturedABTrackContainer>
      {latestReference ? (
        <FeaturedABTrack
          url={refUrl}
          readableProjectType={readableProjectType ?? ""}
          fileVersion={latestReference}
          isRef
          isSameProject={isSameProject}
          onClick={onClick}
        />
      ) : (
        <SoundWaveLoader width={100} height={100} />
      )}
      {latestMain ? (
        <FeaturedABTrack
          url={mainUrl}
          readableProjectType={readableProjectType ?? ""}
          isRef={false}
          fileVersion={latestMain}
          isSameProject={isSameProject}
          onClick={onClick}
        />
      ) : (
        <SoundWaveLoader width={100} height={100} />
      )}
      <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
        <PlaybackControlButton
          disabled={Boolean(!mainUrl && !refUrl)}
          isPrimary
          onClick={playbackButtonOnClick}
          size={PlaybackControlButtonSize.LARGE}
          variant={
            isFooterPlaying && isSameProject
              ? PlaybackControlButtonVariant.PAUSE
              : PlaybackControlButtonVariant.PLAY_CIRCLE
          }
        />
      </Box>
    </FeaturedABTrackContainer>
  );
};
