import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { ErrorsAction } from "../../store/actions/errorStore";
import {
  createRecordingService,
  createRecordingServiceParams,
} from "../../store/actions/recording";
import { useAppDispatch } from "../../store/hooks";
import { RecordingService } from "../../store/models/recording";
import { callAllHandlersWithData } from "../../utils/utils";

interface useEngineerServiceCrudProps
  extends Omit<
    UseMutationOptions<
      RecordingService,
      ErrorsAction,
      createRecordingServiceParams
    >,
    "onSuccess" | "mutationFn"
  > {
  engineerId: number;
  additionalOnSuccess?: (data: RecordingService) => void;
}

export const useUpsertRecordingService = ({
  engineerId,
  additionalOnSuccess,
  ...extraProps
}: useEngineerServiceCrudProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const defaultOnSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineerId],
    });
  };

  return useMutation({
    mutationFn: async (props: createRecordingServiceParams) => {
      return dispatch(createRecordingService(props)).unwrap();
    },
    onSuccess: callAllHandlersWithData(defaultOnSuccess, additionalOnSuccess),
    ...extraProps,
  });
};
