import { FormGroup } from "@mui/material";
import { useCallback, useState } from "react";
import useCreatePromoCodeMutation from "../../../hooks/marketingHooks/useCreatePromoCodeMutation";
import { useAppSelector } from "../../../store/hooks";
import question_icon from "../../assets/question-mark.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { PercentageTextfield } from "../../elements/PercentageTextField/PercentageTextfield";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import { PromoCodeFormActions } from "./CreatePromoCodeForm.styled";

const PROMO_CODE_MIN_LENGTH = 6;
const PROMO_CODE_MAX_LENGTH = 24;

export interface CreatePromoCodeFormProps {
  onPromoCodeAdded?: () => void;
  onCancel?: () => void;
}

export const CreatePromoCodeForm = ({
  onPromoCodeAdded = () => {},
  onCancel = () => {},
}: CreatePromoCodeFormProps) => {
  const selectedStudioProfile = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );
  const { isPending: loading, mutateAsync: createPromoCode } =
    useCreatePromoCodeMutation();
  const [discount, setDiscount] = useState(0.05);
  const [expiration, setDate] = useState<string | null>(null);
  const [singleUse, setSingleUse] = useState(false);
  const [code, setCode] = useState("");

  const filterCode = useCallback((val: string) => {
    return val.replaceAll(" ", "").toUpperCase();
  }, []);

  const handleSubmit = useCallback(async () => {
    void createPromoCode({
      code: code,
      discount_percentage: discount,
      single_use_per_user: singleUse,
      expires_at: expiration ? new Date(expiration).toISOString() : undefined,
      studio_id: selectedStudioProfile?.id,
    }).then(onPromoCodeAdded);
  }, [
    selectedStudioProfile,
    discount,
    singleUse,
    expiration,
    createPromoCode,
    onPromoCodeAdded,
    code,
  ]);

  const handleCancel = useCallback(() => {
    setDiscount(0.05);
    setDate(null);
    setSingleUse(false);
    setCode("");
    onCancel();
  }, [setDiscount, setDate, setSingleUse, setCode, onCancel]);

  return (
    <FormGroup sx={{ height: "100%" }} row>
      <div className="engineer-promo-list-item">
        <div className="engineer-promo-list-item-column">
          <label htmlFor="expires">
            expiration date{" "}
            <img
              className={"platform-opt-in-more-info"}
              src={question_icon}
              alt="information about platform wide promotions."
              height={10}
              width={10}
              title={
                "Voids your promo code after it expires. If you do not provide an expiration it will be active until you delete it"
              }
            />
          </label>
        </div>
        <div className="engineer-promo-list-item-column">
          <input
            type="date"
            id="expires"
            name="expires-at"
            onChange={(event) => {
              event.preventDefault();
              if (!event.target.value || event.target.value.length <= 0) {
                setDate(null);
              } else {
                setDate(event.target.value);
              }
            }}
            min={new Date().toISOString().slice(0, 10)}
          />
        </div>
      </div>
      <div className="engineer-promo-list-item">
        <div className="engineer-promo-list-item-column">
          <p className="b1">
            discount percentage*{" "}
            <img
              className={"platform-opt-in-more-info"}
              src={question_icon}
              alt="information about platform wide promotions."
              height={10}
              width={10}
              title={"Discounts this percentage off an artists total"}
            />
          </p>
        </div>
        <div className="engineer-promo-list-item-column">
          <PercentageTextfield
            min={1}
            max={60}
            className="promo-code-discount textfield"
            value={discount}
            onChangePercentage={(value: number) => {
              setDiscount(value);
            }}
          />
        </div>
      </div>
      <div className="engineer-promo-list-item">
        <div className="engineer-promo-list-item-column">
          <p className="b1">
            single use per user{" "}
            <img
              className={"platform-opt-in-more-info"}
              src={question_icon}
              alt="information about platform wide promotions."
              height={10}
              width={10}
              title={
                "Toggling will limit each artist with a one time use of this code"
              }
            />
          </p>
        </div>
        <div className="engineer-promo-list-item-column">
          <ToggleSwitch
            id="promo-code-single-use-toggle"
            checked={singleUse}
            onChange={() => setSingleUse((curr) => !curr)}
            updateCheckedLocally={false}
          />
        </div>
      </div>
      <div className="engineer-promo-list-item">
        <div className="engineer-promo-list-item-column">
          <p className="b1">code* </p>
          <ToolTipTextArea
            tooltipTextStyle={{ bottom: "20px", left: "24px" }}
            text={
              "Promotional code to apply discounts on your services. Must be at least 6 characters long."
            }
          />
        </div>
        <div className="engineer-promo-list-item-column">
          <EditableTextField
            className={"promo-code-text-field"}
            initialValue={""}
            label={"enter promo code"}
            onChange={(newVal) => {
              if (newVal.length > PROMO_CODE_MAX_LENGTH) {
                return;
              }
              setCode(newVal);
            }}
            filter={filterCode}
            editMode={true}
          />
        </div>
      </div>
      <PromoCodeFormActions>
        <Button
          fullWidth
          disabled={
            !code.length ||
            discount <= 0 ||
            loading ||
            code.length < PROMO_CODE_MIN_LENGTH ||
            code.length > PROMO_CODE_MAX_LENGTH
          }
          onClick={handleSubmit}
          loading={loading}
          variant={ButtonVariant.PRIMARY}
        >
          Submit
        </Button>
        <Button
          fullWidth
          variant={ButtonVariant.OUTLINED}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </PromoCodeFormActions>
    </FormGroup>
  );
};
