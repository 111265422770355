import { Box } from "@mui/material";
import { useCanAddEngineerService } from "../../../hooks/useCanAddEngineerService";
import { useHasMasteringService } from "../../../hooks/useHasMasteringService";
import useManageEngineeringService, {
  MAXIMUM_DEPOSIT,
  MINIMUM_DEPOSIT,
} from "../../../hooks/useManageEngineeringService";
import { useMinAndMaxServiceRates } from "../../../hooks/useMinAndMaxServiceRates";
import { useMinimumDepositDescription } from "../../../hooks/useMinimumDepositDescription";
import { ServiceOperations } from "../../../store/actions/services";
import { useAppSelector } from "../../../store/hooks";
import Engineer from "../../../store/models/engineer";
import {
  ProjectType,
  ScheduledProjectPaywallOptions,
  ScheduledProjectPaywallTypes,
} from "../../../store/models/project";
import Service from "../../../store/models/service";
import User from "../../../store/models/user";
import { selectEngineerHasDolbyServices } from "../../../store/selectors/servicesSelectors";
import {
  getNumberAsOptionType,
  getNumberedOptions,
} from "../../../store/utils/serviceUtils";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../core-ui/components/Button/FixedMinWidthButton";
import { DropdownSelector } from "../../elements/DropDownSelector/DropdownSelector";
import { PercentageTextfield } from "../../elements/PercentageTextField/PercentageTextfield";
import { PriceField } from "../../elements/PriceField/PriceField";
import { ToggleBundleDiscount } from "../../elements/ToggleBundledDiscount/ToggleBundledDiscount";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { ServicesFormModalFooter } from "../../screens/ProfileScreen/components/ServicesFormModalFooter";
import EngineeringServiceDownloadPreference from "../EngineeringServiceDownloadPreference/EngineeringServiceDownloadPreference";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import EngineeringServiceBulkSongDiscountPreference from "./EngineeringServiceBulkSongDiscountPreference";
import "./ManageEngineeringService.css";
import {
  ServiceFormContainer,
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
} from "./ManageEngineeringService.styles";

// eslint-disable-next-line import/no-unused-modules
export interface ManageEngineeringServiceProps {
  engineerUser: User;
  engineer: Engineer;
  onDeleteService?: () => void;
  onSuccessfulSubmit: () => void;
  service?: Service;
  selectedServiceType: ProjectType;
  isUpdating: boolean;
  onUpdatePrice: (newPrice: number) => void;
  onCancel: () => void;
  isRevalidatingData?: boolean;
}

export const ManageEngineeringService = ({
  engineerUser,
  engineer,
  onSuccessfulSubmit,
  service,
  selectedServiceType,
  isUpdating,
  onUpdatePrice,
  onCancel,
  isRevalidatingData,
}: ManageEngineeringServiceProps) => {
  const [minServiceRate, maxServiceRate] = useMinAndMaxServiceRates(
    service ? service.service_type : selectedServiceType,
  );

  const {
    loading,
    allowPartialPayments,
    setAllowPartialPayments,
    pendingService,
    setPendingService,
    manageEngineeringService,
    handleDepositPercentage,
    updateCostPerExtraAlt,
    updateExtraRevisionPrice,
    changeServiceRateForLabelPrice,
    updateNumberOfFreeRevisions,
    changeServiceRatePrice,
    updateWAVDownloadPreference,
    updateMP3DownloadPreference,
    handlePartialPaymentPaywallOption,
    addBulkDiscount,
    setAddBulkDiscount,
  } = useManageEngineeringService(
    service ??
      ({
        enable_artist_review_mp3_file_download: false,
        enable_artist_review_wav_file_download: false,
        engineer_id: engineer.id,
        service_type: selectedServiceType,
        extra_alt_price: 5,
        free_revisions: 1,
        extra_revision_price: 5,
        minimum_deposit_percentage: 1.0,
        service_rate: {
          price: minServiceRate,
          label_price: minServiceRate + 100,
          user_id: engineerUser.id,
          user: engineerUser,
        },
        partial_payment_paywall_option:
          ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED,
      } as Service),
    engineerUser,
    selectedServiceType,
    onSuccessfulSubmit,
    onUpdatePrice,
  );

  const minimumDepositDescription =
    useMinimumDepositDescription(pendingService);

  const services = useAppSelector((state) => state.engineerServices.services);
  const hasMaster = useHasMasteringService(services);
  const engineerHasDolbyServices = useAppSelector(
    selectEngineerHasDolbyServices,
  );
  const { canAddService, message } = useCanAddEngineerService(pendingService);

  const createOrUpdateEngineeringService = async () => {
    if (!canAddService && !isUpdating) return;
    if (!service) {
      await manageEngineeringService(ServiceOperations.UPSERT);
    } else {
      await manageEngineeringService(ServiceOperations.UPDATE);
    }
  };

  const updateBulkSongDiscountPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_bulk_song_discounts: value,
    });
  };

  if (!canAddService && message && !isUpdating) {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  }

  const canBeBundledWithMasterService =
    pendingService.service_type === ProjectType.MIXING ||
    pendingService.service_type === ProjectType.TWO_TRACK_MIXING;
  const canBeBundledWithDolbyService =
    pendingService.service_type === ProjectType.MIXING;

  return (
    <ServiceFormContainer>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel htmlFor="service-rate-input">
            Service Rate
          </ServiceFormLabel>
          <ServiceFormLabelSubtext>
            (For independent artists)
          </ServiceFormLabelSubtext>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <PriceField
            min={minServiceRate}
            max={maxServiceRate}
            value={pendingService.service_rate?.price}
            placeholder="Service Rate"
            onChange={changeServiceRatePrice}
            id="service-rate-input"
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel htmlFor="label-rate-input">
            Label Rate
          </ServiceFormLabel>
          <ServiceFormLabelSubtext>
            (Rates apply once verified and visible to labels)
          </ServiceFormLabelSubtext>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <PriceField
            min={minServiceRate}
            max={maxServiceRate}
            value={pendingService.service_rate?.label_price ?? minServiceRate}
            placeholder="Label Rate"
            onChange={changeServiceRateForLabelPrice}
            id="label-rate-input"
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>Number of free revisions</ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <DropdownSelector
            value={getNumberAsOptionType(pendingService.free_revisions ?? 1)}
            onChange={updateNumberOfFreeRevisions}
            options={getNumberedOptions(1, 6)}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <ServiceFormLabel htmlFor="extra-revision-cost-input">
              Cost per extra revision
            </ServiceFormLabel>
            <ToolTipTextArea
              text={"Revision price cannot exceed the service rate"}
            />
          </Box>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <PriceField
            min={
              pendingService.service_rate.price
                ? (5 * pendingService.service_rate.price) / 100
                : 5
            }
            max={
              pendingService.service_rate.price
                ? (50 * pendingService.service_rate.price) / 100
                : 50
            }
            value={pendingService.extra_revision_price || 5}
            placeholder="Revision price"
            onChange={function (value: number): void {
              updateExtraRevisionPrice(value);
            }}
            id="extra-revision-cost-input"
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel htmlFor="extra-alt-cost-input">
            Cost per extra alt
          </ServiceFormLabel>
          <ServiceFormLabelSubtext>
            *Note: main and clean alts are included.
          </ServiceFormLabelSubtext>
        </ServiceFormLabelContainer>

        <ServiceFormInputContainer>
          <PriceField
            min={
              pendingService.service_rate.price
                ? (5 * pendingService.service_rate.price) / 100
                : 5
            }
            max={
              pendingService.service_rate.price
                ? (50 * pendingService.service_rate.price) / 100
                : 50
            }
            value={pendingService.extra_alt_price || 5}
            placeholder="Cost per extra revision"
            onChange={function (value: number): void {
              updateCostPerExtraAlt(value);
            }}
            id="extra-alt-cost-input"
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>

      <EngineeringServiceBulkSongDiscountPreference
        pendingService={pendingService}
        onUpdate={updateBulkSongDiscountPreference}
      />

      <ToggleBundleDiscount
        addService={addBulkDiscount}
        setAddService={setAddBulkDiscount}
        canBeBundledWithDolbyService={canBeBundledWithDolbyService}
        canBeBundledWithMasterService={canBeBundledWithMasterService}
        engineerHasDolbyServices={engineerHasDolbyServices}
        engineerHasMasterServices={hasMaster}
        setPendingService={setPendingService}
        pendingService={pendingService}
      />

      <EngineeringServiceDownloadPreference
        show={false}
        pendingService={pendingService}
        onWAVUpdate={updateWAVDownloadPreference}
        onMP3Update={updateMP3DownloadPreference}
      />
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <ServiceFormLabel htmlFor="partial-payments-toggle">
              Allow partial payments&nbsp;
            </ServiceFormLabel>
            <ToolTipTextArea
              text={
                "Allows your clients to put down an initial deposit when booking; this percentage determines the initial deposit amount."
              }
            />
          </Box>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <ToggleSwitch
            checked={allowPartialPayments}
            onChange={(checked: boolean) => {
              setAllowPartialPayments(checked);
            }}
            id="partial-payments-toggle"
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      {allowPartialPayments && (
        <>
          <ServiceFormRow>
            <DropdownSelector
              value={ScheduledProjectPaywallOptions.find(
                (option) =>
                  option.value ===
                  pendingService.partial_payment_paywall_option,
              )}
              options={ScheduledProjectPaywallOptions.slice(1)}
              onChange={handlePartialPaymentPaywallOption}
              placeholder="Select paywall option"
            />
          </ServiceFormRow>
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <ServiceFormLabel htmlFor="minimum-deposit-required-input">
                Minimum deposit required
              </ServiceFormLabel>
              <ServiceFormLabelSubtext>
                (Initial deposit amount {minimumDepositDescription})
              </ServiceFormLabelSubtext>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <PercentageTextfield
                max={MAXIMUM_DEPOSIT}
                min={MINIMUM_DEPOSIT}
                value={pendingService.minimum_deposit_percentage}
                onChangePercentage={handleDepositPercentage}
                step={5}
                id="minimum-deposit-required-input"
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
        </>
      )}

      <ServicesFormModalFooter>
        <FixedMinWidthButton
          variant={ButtonVariant.OUTLINED}
          onClick={onCancel}
          disabled={loading || isRevalidatingData}
        >
          Cancel
        </FixedMinWidthButton>
        <FixedMinWidthButton
          className="engineering-service-button"
          onClick={createOrUpdateEngineeringService}
          disabled={loading || isRevalidatingData}
          loading={loading || isRevalidatingData}
        >
          Save
        </FixedMinWidthButton>
      </ServicesFormModalFooter>
    </ServiceFormContainer>
  );
};
