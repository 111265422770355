import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { default as WaveSurferRef } from "wavesurfer.js";
import { getFooterWaveformRef } from "./waveformHooks";
import {
  setPlaylist,
  SetPlaylistArgs,
} from "../../store/actions/abPlayerStore";

export const useGetFooterPlayerRef = () => {
  const { isFooterReady } = useAppSelector((store) => store.abPlayerStore);
  const footerPlayerRef = useRef<WaveSurferRef | null>(null);
  useEffect(() => {
    if (!isFooterReady) return;
    footerPlayerRef.current = getFooterWaveformRef();
  }, [isFooterReady]);

  return footerPlayerRef;
};

export const useCurrentProgressForPlaylistId = (currentPlaylistId: number) => {
  const { isFooterReady, currentPosition, playlistId } = useAppSelector(
    (store) => store.abPlayerStore,
  );
  const footerPlayerRef = useGetFooterPlayerRef();

  return useMemo(() => {
    if (!isFooterReady) {
      return 0;
    }
    if (!footerPlayerRef.current) {
      return 0;
    }
    if (currentPlaylistId !== playlistId) {
      return 0;
    }
    return (currentPosition / footerPlayerRef.current.getDuration()) * 100;
  }, [
    isFooterReady,
    currentPosition,
    footerPlayerRef.current,
    currentPlaylistId,
  ]);
};

export const usePlayPauseOnFooter = () => {
  const footerPlayerRef = useGetFooterPlayerRef();
  const { isFooterReady } = useAppSelector((store) => store.abPlayerStore);

  const handleClick = () => {
    if (!isFooterReady) return;
    if (footerPlayerRef.current) {
      if (footerPlayerRef.current.isPlaying()) {
        footerPlayerRef.current.pause();
      } else {
        footerPlayerRef.current.play();
      }
    }
  };
  return { handleClick };
};

export const useSetPlayList = () => {
  const dispatch = useAppDispatch();
  return useCallback((args: SetPlaylistArgs) => {
    dispatch(setPlaylist(args));
  }, []);
};
