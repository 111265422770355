import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { compile } from "path-to-regexp";
import queryString from "query-string";
import { Discounts, RecordingService } from "../models/recording";
import {
  RecordingSession,
  RecordingSessionBooking,
  SessionBookingDetails,
} from "../models/recordingSession";
import { TransactionStatus } from "../models/transaction";
import { getFormattedDateString } from "../utils/dateTimeUtils";
import {
  FETCH_STATES,
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { getOrderSummaryFromItemizedTransactionResponse } from "../utils/recordingUtils";
import {
  ACCEPT_BOOKING_RECORDING_SESSION,
  ACCEPT_SESSION_MODIFICATION,
  ARTIST_RECORDING_SESSION_TRANSITION,
  DELETE_RECORDING_SERVICE,
  ENGINEER_RECORDING_SESSION_TRANSITION,
  GET_ITEMIZED_TRANSACTION,
  GET_PENDING_RECORDING_SESSION_BOOKINGS,
  GET_RECORDING_SERVICE_AVAILABILITY,
  GET_RECORDING_SESSION,
  GET_RECORDING_SESSION_DETAILS_FROM_SHARE_LINK,
  GET_SESSION_BOOKING_DETAILS,
  RECORDING_SERVICE_API,
  REJECT_BOOKING_RECORDING_SESSION,
  REJECT_SESSION_MODIFICATION,
  REQUEST_SESSION_MODIFICATION,
  SESSION_CANCELLATION,
  SESSION_USER_ROLES,
} from "../utils/routes";
import { updateAvailabilityCache } from "./availability";
import { receiveErrors } from "./errorStore";
import { MultipartUploadParams } from "./fileVersions";
import { ReviewShareLinkParams } from "./projectsMap";
import { RecordingOrderSummary } from "./recordingCartsStore";
import {
  fetchTransactionStatus,
  GetItemizedTransactionResponse,
} from "./transactions";

export interface createRecordingServiceParams {
  price: number;
  label_price: number;
  minimum_session_time_minutes: number;
  maximum_session_time_minutes: number;
  will_come_to_you: boolean;
  max_travel_distance_minutes: number;
  equipment_highlights: string;
  arrival_information: string;
  unit_number: string | null;
  recording_location?: google.maps.places.PlaceResult | string;
  minimum_gap_between_sessions_in_minutes: number;
  number_of_hours_notice: number;
  studio_room_id?: number;
  travel_to_artist_price?: number;
  discount_rates?: Discounts[];
  minimum_deposit_percentage?: string;
  description?: string;
}

export const createRecordingService = createAsyncThunk(
  RECORDING_SERVICE_API,
  async (args: createRecordingServiceParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<RecordingService>(
      RECORDING_SERVICE_API,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const deleteRecordingService = createAsyncThunk(
  DELETE_RECORDING_SERVICE,
  async (args: { recording_service_id: number }, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<RecordingService>(
      DELETE_RECORDING_SERVICE,
      {
        recording_service_id: args.recording_service_id,
      },
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getRecordingServiceAvailabilityParams {
  startDate: Date;
  endDate?: Date;
  userId?: number;
  studioRoomId?: number;
  timezoneShiftMinutes: number;
  hideErrors?: boolean;
}

export interface GetRecordingServiceAvailabilityResponse {
  user_id: number;
  studio_room_id: number;
  utc_date: string;
  availability: string;
}

export const getRecordingServiceAvailability = createAsyncThunk(
  GET_RECORDING_SERVICE_AVAILABILITY,
  async (
    {
      startDate,
      endDate,
      userId,
      studioRoomId,
      timezoneShiftMinutes,
      hideErrors = false,
    }: getRecordingServiceAvailabilityParams,
    thunkAPI,
  ) => {
    let params = `?timezone_shift_minutes=${timezoneShiftMinutes}&start_date=${getFormattedDateString(
      startDate,
    )}`;
    if (endDate) {
      params += `&end_date=${getFormattedDateString(endDate)}`;
    }
    if (userId) {
      params += `&user_id=${userId}`;
    }
    if (studioRoomId) {
      params += `&studio_room_id=${studioRoomId}`;
    }
    if (hideErrors) {
      params += "&hide_errors=true";
    }
    // Default to refresh all recording service availability in db for now.
    // TODO: Revert this change once the webhooks are set up.
    params += "&refresh=true";
    const result = await makeBackendGetCallWithJsonResponse<
      GetRecordingServiceAvailabilityResponse[]
    >(GET_RECORDING_SERVICE_AVAILABILITY, params);
    if (result.success) {
      return result.resultJson;
    }
    try {
      const errors = { errors: result.resultJson };
      thunkAPI.dispatch(receiveErrors(errors));
      return thunkAPI.rejectWithValue(errors);
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: "Unknown error" });
    }
  },
);

export const getPendingRecordingSessionBookings = createAsyncThunk(
  GET_PENDING_RECORDING_SESSION_BOOKINGS,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<
      RecordingSessionBooking[]
    >(GET_PENDING_RECORDING_SESSION_BOOKINGS, "");
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface acceptBookingRecordingSessionParams {
  recording_session_booking_id: number;
}

export const acceptBookingRecordingSession = createAsyncThunk(
  ACCEPT_BOOKING_RECORDING_SESSION,
  async (args: acceptBookingRecordingSessionParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<RecordingSessionBooking>(
        ACCEPT_BOOKING_RECORDING_SESSION,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface rejectBookingRecordingSessionParams {
  recording_session_booking_id: number;
}

export const rejectBookingRecordingSession = createAsyncThunk(
  REJECT_BOOKING_RECORDING_SESSION,
  async (args: rejectBookingRecordingSessionParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<RecordingSessionBooking>(
        REJECT_BOOKING_RECORDING_SESSION,
        args,
      );
    if (result.success) {
      thunkAPI.dispatch(
        updateAvailabilityCache(
          result.resultJson.recording_sessions.map((recordingSession) => ({
            ...recordingSession,
            markAsAvailable: true,
          })),
        ),
      );

      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface getSessionBookingDetailsParams {
  bookingId: number;
}

export const getSessionBookingDetails = createAsyncThunk(
  GET_SESSION_BOOKING_DETAILS,
  async (args: getSessionBookingDetailsParams, thunkAPI) => {
    const getSessionBookingDetailsRequest = () =>
      makeBackendGetCallWithJsonResponse<SessionBookingDetails>(
        compile(GET_SESSION_BOOKING_DETAILS)({
          booking_id: args.bookingId,
        }),
        "",
      );

    const getItemizedTransactionRequest = () =>
      makeBackendGetCallWithJsonResponse<GetItemizedTransactionResponse>(
        GET_ITEMIZED_TRANSACTION,
        `?session_booking_id=${args.bookingId}`,
      );

    const [getSessionBookingDetailsResponse, getItemizedTransactionResponse] =
      await Promise.all([
        getSessionBookingDetailsRequest(),
        getItemizedTransactionRequest(),
      ]);

    if (
      getSessionBookingDetailsResponse.success &&
      getItemizedTransactionResponse.success
    ) {
      return {
        ...getSessionBookingDetailsResponse.resultJson,
        orderSummary: getOrderSummaryFromItemizedTransactionResponse(
          getItemizedTransactionResponse.resultJson,
        ),
      };
    }

    if (!getSessionBookingDetailsResponse.success) {
      const errors = { errors: getSessionBookingDetailsResponse.resultJson };
      thunkAPI.dispatch(receiveErrors(errors));
      return thunkAPI.rejectWithValue(errors);
    }

    if (!getItemizedTransactionResponse.success) {
      const errors = { errors: getItemizedTransactionResponse.resultJson };
      thunkAPI.dispatch(receiveErrors(errors));
      return thunkAPI.rejectWithValue(errors);
    }
  },
);

export const getRecordingSession = createAsyncThunk(
  GET_RECORDING_SESSION,
  async (projectId: string, thunkAPI) => {
    const param = `?project_id=${projectId}`;
    const result = await makeBackendGetCallWithJsonResponse<RecordingSession>(
      GET_RECORDING_SESSION,
      param,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getRecordingSessionDetailsFromShareLink = createAsyncThunk(
  GET_RECORDING_SESSION_DETAILS_FROM_SHARE_LINK,
  async ({ code, projectId, password }: ReviewShareLinkParams, thunkAPI) => {
    const params = `?${queryString.stringify({
      code,
      ...(projectId && { project_id: projectId }),
      ...(password && { password }),
    })}`;
    const response = await makeBackendGetCallWithJsonResponse<RecordingSession>(
      GET_RECORDING_SESSION_DETAILS_FROM_SHARE_LINK,
      params,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface recordingSessionTransitionParams {
  transition: string;
  project_id: number;
}

export interface engineerRecordingSessionTransitionParams
  extends recordingSessionTransitionParams {
  file_version_id?: number;
  multipartUploadParams?: MultipartUploadParams | null;
}

export const engineerRecordingSessionTransition = createAsyncThunk(
  ENGINEER_RECORDING_SESSION_TRANSITION,
  async (
    {
      file_version_id,
      transition,
      project_id,
      multipartUploadParams,
    }: engineerRecordingSessionTransitionParams,
    thunkAPI,
  ) => {
    const result = await makeBackendPostCallWithJsonResponse<RecordingSession>(
      ENGINEER_RECORDING_SESSION_TRANSITION,
      { file_version_id, transition, project_id, ...multipartUploadParams },
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const artistRecordingSessionTransition = createAsyncThunk(
  ARTIST_RECORDING_SESSION_TRANSITION,
  async (args: recordingSessionTransitionParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<RecordingSession>(
      ARTIST_RECORDING_SESSION_TRANSITION,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getCancellationRefundAmountParams {
  projectId: number;
}

interface SessionCancellationResponse {
  refund_percentage: number;
  refund_amount: number;
}

export const getCancellationRefundAmount = createAsyncThunk(
  SESSION_CANCELLATION + "/get",
  async (args: getCancellationRefundAmountParams, thunkAPI) => {
    const param = `?project_id=${args.projectId}`;
    const result =
      await makeBackendGetCallWithJsonResponse<SessionCancellationResponse>(
        SESSION_CANCELLATION,
        param,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface cancelSessionAndIssueAssociatedRefundParams {
  project_id: number;
}

export const cancelSessionAndIssueAssociatedRefund = createAsyncThunk(
  SESSION_CANCELLATION + "/post",
  async (args: cancelSessionAndIssueAssociatedRefundParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<RecordingSession>(
      SESSION_CANCELLATION,
      args,
    );
    if (result.success) {
      thunkAPI.dispatch(
        updateAvailabilityCache([
          {
            ...result.resultJson,
            markAsAvailable: true,
          },
        ]),
      );
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface fetchStudioGuideSessionParams {
  studio_id?: number;
}

export interface requestSessionModificationParams {
  project_id: number;
  requested_data: {
    new_datetime?: string;
    new_session_duration_minutes?: number;
  };
}

export const postSessionModification = createAsyncThunk(
  REQUEST_SESSION_MODIFICATION,
  async (args: requestSessionModificationParams, thunkAPI) => {
    const body = {
      project_id: args.project_id,
      requested_data: args.requested_data,
    };
    const result = await makeBackendPostCallWithJsonResponse<RecordingSession>(
      REQUEST_SESSION_MODIFICATION,
      body,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const acceptSessionModification = createAsyncThunk(
  ACCEPT_SESSION_MODIFICATION,
  async (args: acceptRejectSessionModificationParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<RecordingSession>(
      ACCEPT_SESSION_MODIFICATION,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const rejectSessionModification = createAsyncThunk(
  REJECT_SESSION_MODIFICATION,
  async (args: acceptRejectSessionModificationParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<RecordingSession>(
      REJECT_SESSION_MODIFICATION,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface getSessionUserRolesParams {
  sessionId: number;
}

export const getSessionUserRoles = createAsyncThunk(
  SESSION_USER_ROLES,
  async (args: getSessionUserRolesParams, thunkAPI) => {
    const param = `?session_id=${args.sessionId}`;
    const result = await makeBackendGetCallWithJsonResponse<{
      is_engineer: boolean;
      is_artist: boolean;
      is_manager: boolean;
    }>(SESSION_USER_ROLES, param);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface acceptRejectSessionModificationParams {
  project_id: number;
}

export type SessionBookingDetailsResultData = SessionBookingDetails & {
  orderSummary: RecordingOrderSummary | null;
};

export interface SessionBookingDetailsResult {
  status: FETCH_STATES;
  data: SessionBookingDetailsResultData | null;
}

export interface RecordingSessionState {
  recordingSession: RecordingSession | null;
  loading: boolean;
  updating: boolean;
  pendingRecordingSessionBookings: RecordingSessionBooking[];
  loadingPendingRecordingSessionBookings: boolean;
  sessionBookingDetailsMap: Record<string, SessionBookingDetailsResult>;
  // The `loading` state is used in multiple places not as intended,
  // so I created new property to keep track of the state inside the side panel
  acceptRejectSessionModificationLoading: boolean;
  recordingSessionLoading: boolean;
}

const initialState: RecordingSessionState = {
  recordingSession: null,
  loading: false,
  updating: false,
  pendingRecordingSessionBookings: [],
  loadingPendingRecordingSessionBookings: false,
  sessionBookingDetailsMap: {},
  acceptRejectSessionModificationLoading: false,
  recordingSessionLoading: false,
};

export const recordingSessionSlice = createSlice({
  name: "recordingSession",
  initialState: initialState,
  reducers: {
    setRecordingSession: (
      state,
      action: PayloadAction<RecordingSession | null>,
    ) => {
      state.recordingSession = action.payload;
    },
    clearRecordingSession: (state) => {
      state.recordingSession = null;
      state.loading = false;
    },
    clearSessionBookingRequest: (state, action: PayloadAction<string>) => {
      state.sessionBookingDetailsMap = {
        ...state.sessionBookingDetailsMap,
        [action.payload]: {
          status: FETCH_STATES.IDLE,
          data: null,
        },
      };
    },
    updateSessionBookingDetailsAfterModification: (
      state,
      action: PayloadAction<{
        recordingSession: RecordingSession;
        bookingId: number;
      }>,
    ) => {
      const { recordingSession, bookingId } = action.payload;

      const cachedRecordingSession = state.sessionBookingDetailsMap[
        bookingId
      ]?.data?.recording_sessions.find(
        (session) => session.id === recordingSession.id,
      );

      if (cachedRecordingSession) {
        cachedRecordingSession.first_choice_datetime =
          recordingSession.first_choice_datetime;
        cachedRecordingSession.studio_manager_payment_amount_split =
          recordingSession.studio_manager_payment_amount_split;
        cachedRecordingSession.engineer_payment_amount_split =
          recordingSession.engineer_payment_amount_split;
        cachedRecordingSession.session_duration_minutes =
          recordingSession.session_duration_minutes;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSessionBookingDetails.pending, (state, action) => {
      state.sessionBookingDetailsMap = {
        ...state.sessionBookingDetailsMap,
        [action.meta.arg.bookingId]: {
          status: FETCH_STATES.LOADING,
          data: null,
        },
      };
    });
    builder.addCase(getSessionBookingDetails.fulfilled, (state, action) => {
      state.sessionBookingDetailsMap = {
        ...state.sessionBookingDetailsMap,
        [action.meta.arg.bookingId]: {
          status: FETCH_STATES.LOADED,
          data: action.payload,
        },
      };
    });
    builder.addCase(getSessionBookingDetails.rejected, (state, action) => {
      state.sessionBookingDetailsMap = {
        ...state.sessionBookingDetailsMap,
        [action.meta.arg.bookingId]: {
          status: FETCH_STATES.FAILED,
          data: null,
        },
      };
    });
    builder.addCase(engineerRecordingSessionTransition.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(engineerRecordingSessionTransition.rejected, (state) => {
      state.updating = false;
    });
    builder.addCase(
      engineerRecordingSessionTransition.fulfilled,
      (state, action) => {
        state.recordingSession = action.payload;
        state.updating = false;
      },
    );
    builder.addCase(artistRecordingSessionTransition.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(artistRecordingSessionTransition.rejected, (state) => {
      state.updating = false;
    });
    builder.addCase(
      artistRecordingSessionTransition.fulfilled,
      (state, action) => {
        state.recordingSession = action.payload;
        state.updating = false;
      },
    );
    builder.addCase(getPendingRecordingSessionBookings.pending, (state) => {
      state.loadingPendingRecordingSessionBookings = true;
    });
    builder.addCase(getPendingRecordingSessionBookings.rejected, (state) => {
      state.pendingRecordingSessionBookings = [];
      state.loadingPendingRecordingSessionBookings = false;
    });
    builder.addCase(
      getPendingRecordingSessionBookings.fulfilled,
      (state, action) => {
        state.pendingRecordingSessionBookings = action.payload;
        state.loadingPendingRecordingSessionBookings = false;
      },
    );
    builder.addCase(postSessionModification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postSessionModification.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(postSessionModification.fulfilled, (state, action) => {
      state.recordingSession = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchTransactionStatus.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === TransactionStatus.PAID) {
        if (state.recordingSession) {
          state.recordingSession = {
            ...state.recordingSession,
            outstanding_balance: 0,
          };
        }
      }
    });
    builder.addMatcher(
      isAnyOf(
        acceptSessionModification.pending,
        rejectSessionModification.pending,
      ),
      (state) => {
        state.loading = true;
        state.acceptRejectSessionModificationLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        acceptSessionModification.rejected,
        rejectSessionModification.rejected,
      ),
      (state) => {
        state.loading = false;
        state.acceptRejectSessionModificationLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        acceptSessionModification.fulfilled,
        rejectSessionModification.fulfilled,
      ),
      (state, action) => {
        state.recordingSession = action.payload;
        state.loading = false;
        state.acceptRejectSessionModificationLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getRecordingSession.pending,
        getRecordingSessionDetailsFromShareLink.pending,
      ),
      (state) => {
        state.loading = true;
        state.recordingSessionLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getRecordingSession.rejected,
        getRecordingSessionDetailsFromShareLink.rejected,
      ),
      (state) => {
        state.loading = false;
        state.recordingSessionLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getRecordingSession.fulfilled,
        getRecordingSessionDetailsFromShareLink.fulfilled,
      ),
      (state, action) => {
        state.recordingSession = action.payload;
        state.loading = false;
        state.recordingSessionLoading = false;
      },
    );
  },
});

export const {
  setRecordingSession,
  clearRecordingSession,
  clearSessionBookingRequest,
  updateSessionBookingDetailsAfterModification,
} = recordingSessionSlice.actions;

export default recordingSessionSlice.reducer;
