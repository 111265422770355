import { Box, styled } from "@mui/material";

export const FeaturedTrackColumnContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
});

export const FeaturedTrackDeleteModalBody = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});
