import { convertReleaseTrackToPlaylistTrack } from "../../../store/models/playListTrack";
import {
  ReleaseTrack,
  ReleaseTypeOptions,
} from "../../../store/models/release";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { ReleaseListItemCoverPhoto } from "../ReleaseList/ReleaseListItemCoverPhoto";
import { ReleaseTrackCardContainer, ReleaseTrackListContainer } from "./styles";
import { format } from "date-fns";
import { Skeleton } from "@mui/material";

interface ReleaseTrackListProps {
  releaseTracks: ReleaseTrack[];
  onReleaseClick: (releaseId?: number) => void;
  isPending: boolean;
}

const cardTextStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block",
  width: "100%",
};

const renderReleaseTrackCard = (
  releaseTrack: ReleaseTrack,
  onReleaseClick: (releaseId?: number) => void,
) => {
  const playlistTrack = convertReleaseTrackToPlaylistTrack(releaseTrack);
  const releaseOption = ReleaseTypeOptions.find(
    (option) => option.value === releaseTrack.release.release_type,
  );
  const year = format(new Date(releaseTrack.release.created_at), "yyyy");
  const releaseText = releaseOption?.label || "";

  return (
    <ReleaseTrackCardContainer
      onClick={() => {
        onReleaseClick(releaseTrack.release.id);
      }}
      key={releaseTrack.id}
    >
      <ReleaseListItemCoverPhoto
        width={183}
        height={183}
        releaseCover={releaseTrack.release.release_cover}
      />
      <Text style={cardTextStyle} bold variant={TextStyleVariant.P2}>
        {playlistTrack.title}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Text variant={TextStyleVariant.P2} style={cardTextStyle}>
          {playlistTrack.artistDisplayName}
        </Text>
        <Text
          color={TextColor.SECONDARY}
          variant={TextStyleVariant.P3}
          style={{
            ...cardTextStyle,
          }}
        >{`${releaseText} · ${year}`}</Text>
      </div>
      <Text
        color={TextColor.SECONDARY}
        style={{ ...cardTextStyle, textAlign: "right" }}
        variant={TextStyleVariant.P4}
      >
        {`${releaseTrack.play_count} plays`}
      </Text>
    </ReleaseTrackCardContainer>
  );
};

const LoadingReleaseTrackCard = () => {
  return (
    <ReleaseTrackCardContainer>
      <Skeleton width={183} height={183} variant={"rounded"} />
      <Skeleton height={10} width={183} />
      <Skeleton height={5} width={183} />
      <Skeleton height={5} width={183} />
      <Skeleton
        style={{
          marginLeft: "auto",
        }}
        height={5}
        width={53}
      />
      <Skeleton
        style={{
          marginLeft: "auto",
        }}
        height={5}
        width={53}
      />
    </ReleaseTrackCardContainer>
  );
};

const LoadingReleaseTrackList = () => {
  return (
    <ReleaseTrackListContainer>
      <LoadingReleaseTrackCard />
      <LoadingReleaseTrackCard />
      <LoadingReleaseTrackCard />
      <LoadingReleaseTrackCard />
    </ReleaseTrackListContainer>
  );
};

export const TrendingReleaseTrackList = ({
  releaseTracks,
  isPending,
  onReleaseClick,
}: ReleaseTrackListProps) => {
  if (isPending) {
    return <LoadingReleaseTrackList />;
  }
  return (
    <ReleaseTrackListContainer>
      {releaseTracks.map((releaseTrack) => {
        return renderReleaseTrackCard(releaseTrack, onReleaseClick);
      })}
    </ReleaseTrackListContainer>
  );
};
